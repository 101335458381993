import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';

import AppleLogin from 'react-apple-login';

const theme = createTheme();

export default function SignInScreen() {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [incorrectLogin, setIncorrectLogin] = React.useState(true);

    const navigate = useNavigate();

    const handleRegister = () => {
        navigate('/register');
    };

    const handleSuccessfulLogin = () => {
        navigate('/?dashboard');
    };

    const headerOnClick = () => {
        navigate('/');
    };

    return (
        <div style={{ backgroundColor: 'black', minHeight: '100vh' }}>
            <ThemeProvider theme={theme}>
                <Header onClick={headerOnClick} />
                <Grid
                    container
                    component="main"
                    sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}
                >
                    <CssBaseline />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={Paper}
                        elevation={6}
                        square
                        sx={{
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: '10px',
                            padding: '20px',
                        }}
                    >
                        <Box sx={{ my: 8, mx: 4, width: '100%' }}>
                            <Avatar sx={{ m: 'auto', bgcolor: 'black', display: 'flex' }}>
                                <LockOutlinedIcon sx={{ color: 'white' }} />
                            </Avatar>

                            <Typography component="h1" variant="h5" sx={{ color: 'black', textAlign: 'center' }}>
                                Sign in
                            </Typography>

                            <Button
                                href="https://api.priorityze.app/authentication/google/get"
                                rel="noopener noreferrer"
                                variant="contained"
                                fullWidth
                                sx={{
                                    width: '100%',
                                    textDecoration: 'none',
                                    padding: '10px',
                                    marginTop: 2,
                                    marginBottom: 2,
                                    backgroundColor: 'black',
                                    color: 'white',
                                    fontWeight: 700,
                                    '&:hover': {
                                        backgroundColor: '#A6a6a6',
                                        color: 'black',
                                    },
                                }}
                            >
                                Sign In with Google
                            </Button>
                            <AppleLogin
                                clientId="app.priorityze.apidevelopment.sid"
                                redirectURI="https://api.priorityze.app/authentication/apple/redirect"
                                usePopup={false}
                                scope="name email"
                                responseMode="form_post"
                                responseType="id_token code"
                                callback={(response) => {
                                    if (response) {
                                    }
                                }}
                                render={(props) => (
                                    <Button
                                        onClick={props.onClick}
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            width: '100%',
                                            textDecoration: 'none',
                                            padding: '10px',
                                            marginTop: 2,
                                            marginBottom: 2,
                                            backgroundColor: 'black',
                                            color: 'white',
                                            fontWeight: 700,
                                            '&:hover': {
                                                backgroundColor: '#A6a6a6',
                                                color: 'black',
                                            },
                                        }}
                                    >
                                        Sign In with Apple
                                    </Button>
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </div>
    );
}

const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});

const Header = ({ onClick }) => {
    return (
        <Box sx={{ backgroundColor: 'black', padding: '1rem', marginBottom: '5vh' }}>
            <Typography
                component="h1"
                variant="h4"
                align="center"
                sx={{ color: 'white', cursor: 'pointer' }}
                onClick={onClick}
            >
                Priorityze
            </Typography>
        </Box>
    );
};

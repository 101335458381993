import logo from './logo.svg';
import './App.css';
import Header from './Components/Header Components/Header';
import * as React from 'react';
import { MainAddTaskModal } from './Components/AddTaskModals/MainAddTaskModal';
import { Button } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import SignInScreen from './pages/SignInScreen';

import { localReadRealList, localReadTasks, readTaskFromDB } from './Logic/MainTaskHandler';
import AccountScreen from './pages/AccountScreen';
import PurchaseSuccess from './pages/purchaseSuccess';
import PurchaseUnsuccessful from './pages/purchaseFailed';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CollabManagementScreen from './pages/CollabManagementScreen';
import CollabInviteScreen from './pages/CollabInviteScreen';

function App() {
    let Component;
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const toggleDrawer = () => {
        setOpenAddModal(!openAddModal);
    };

    readTaskFromDB();
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/signin" element={<SignInScreen />} />
                <Route path="/account" element={<AccountScreen />} />
                <Route path="/collab" element={<CollabManagementScreen />} />
                <Route path="/purchaseFailed" element={<PurchaseUnsuccessful />} />
                <Route path="collabInvite" element={<CollabInviteScreen />} />
                <Route path="/purchaseSuccess" element={<PurchaseSuccess />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
            </Routes>
        </Router>
    );
}

export default App;

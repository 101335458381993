import React from 'react';
import {
    cancelMembership,
    getCalendarEmails,
    getEmail,
    getMembership,
    goToCheckOut,
    signOut,
    storeCalendarEmails,
    storeEmail,
    storeMembership,
    unsyncCalendar,
} from '../Logic/AccountHandler';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { BrowserRouter as Routes, Navigate, Redirect, Route, Router, useNavigate } from 'react-router-dom';
import { LinkOff } from '@mui/icons-material';
export default function AccountScreen() {
    const navigate = useNavigate();
    const handleCancelMembership = () => {
        if (window.confirm('Are you sure you want to cancel your membership?')) {
            // Perform cancel membership logic
            // Update state or make api request accordingly
            cancelMembership();
        }
    };

    const handleSignOut = () => {
        // Perform sign out logic
        signOut();
    };
    const handleUpgradeMembership = () => {
        // Perform upgrade membership logic
        goToCheckOut();
    };

    const handleHeaderClick = () => {
        navigate('/');
    };

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [membership, setMembership] = React.useState('');
    const [calendarEmails, setCalendarEmails] = React.useState([]);

    React.useEffect(() => {
        fetch('https://api.priorityze.app/checkLogin', {
            method: 'GET',
            cors: 'no-cors',
            credentials: 'include',
        })
            .then((response) => {
                return response.json();
            })
            .then(async (body) => {
                if (body.success == true) {
                    await storeMembership().then(async () => {
                        setMembership(getMembership());
                        await storeEmail().then(() => {
                            setEmail(getEmail());
                        });
                        await storeCalendarEmails().then(() => {
                            setCalendarEmails(getCalendarEmails());
                        });
                    });
                }
                if (body.success == false) {
                    navigate('/');
                }
                setIsLoaded(true);
            })
            .catch((error) => {});
    }, []);

    return (
        <div>
            isLoaded && (
            <Header onClick={handleHeaderClick} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '5px',
                    backgroundColor: '#000000',
                }}
            >
                <Typography
                    variant="body1"
                    align="center"
                    sx={{
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        fontSize: '5vh',
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontDisplay: 'swap',
                        letterSpacing: '-0.015em',
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                >
                    Account Information
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}
                >
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 'bold',
                            fontSize: '3vh',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        Email
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{
                            color: '#86abd4',
                            fontWeight: 'bold',
                            fontSize: '2vh',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        {email}
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 'bold',
                            fontSize: '3vh',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        Membership
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{
                            color: '#86abd4',
                            fontWeight: 'bold',
                            fontSize: '2vh',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        {membership}
                    </Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '5px',
                    }}
                >
                    {membership === 'free' && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '5px',
                            }}
                        >
                            <Typography
                                variant="body1"
                                align="center"
                                sx={{
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                    fontSize: '2vh',
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    fontDisplay: 'swap',
                                    letterSpacing: '-0.015em',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            >
                                Upgrade to Premium to access more features!
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#FFFFFF',
                                    color: '#000000',
                                    fontWeight: 'bold',
                                    fontSize: '2vh',
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    fontDisplay: 'swap',
                                    letterSpacing: '-0.015em',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    marginTop: '5px',
                                }}
                                onClick={handleUpgradeMembership}
                            >
                                Upgrade
                            </Button>
                        </div>
                    )}

                    {membership === 'premium' && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '5px',
                            }}
                        >
                            <Typography
                                variant="body1"
                                align="center"
                                sx={{
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                    fontSize: '2vh',
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    fontDisplay: 'swap',
                                    letterSpacing: '-0.015em',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            >
                                You are a Premium member!
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={handleCancelMembership}
                                sx={{
                                    backgroundColor: '#FFFFFF',
                                    color: '#000000',
                                    fontWeight: 'bold',
                                    fontSize: '2vh',
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    fontDisplay: 'swap',
                                    letterSpacing: '-0.015em',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    marginTop: '20px',
                                }}
                            >
                                Cancel Membership
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20px',
                    backgroundColor: '#000000',
                }}
            >
                {/*

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#000000',
                            fontWeight: 'bold',
                            fontSize: '2vh',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                        href="https://api.priorityze.app/authentication/google/getCalendar"
                        rel="noopener noreferrer"
                    >
                        Sync Google Calendar
                    </Button>
                </div>
            */}
                {/*

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}
                >
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 'bold',
                            fontSize: '2vh',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        Synced Google Calendars
                    </Typography>
                    {calendarEmails.map((email) => {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    align="center"
                                    sx={{
                                        color: '#FFFFFF',
                                        fontWeight: 'bold',
                                        fontSize: '2vh',
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontDisplay: 'swap',
                                        letterSpacing: '-0.015em',

                                        paddingRight: 2,
                                    }}
                                >
                                    {email}
                                </Typography>
                                <LinkOff
                                    sx={{
                                        color: 'white',
                                        '&:hover': {
                                            color: 'blue',
                                            backgroundColor: 'black',
                                            borderRadius: 1,
                                        },
                                    }}
                                    onClick={() => unsyncCalendar(email)}
                                />
                            </div>
                        );
                    })}
                </div>
        */}

                <Button>
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#000000',
                            fontWeight: 'bold',
                            fontSize: '2vh',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                        onClick={handleSignOut}
                    >
                        Sign Out
                    </Typography>
                </Button>
            </div>
            )
        </div>
    );
}

const Header = ({ onClick }) => {
    return (
        <Box sx={{ backgroundColor: 'black', padding: '1rem', marginBottom: '5vh' }}>
            <Typography
                component="h1"
                variant="h4"
                align="center"
                sx={{ color: 'white', cursor: 'pointer' }}
                onClick={onClick}
            >
                Priorityze
            </Typography>
        </Box>
    );
};

import React from 'react';
import { cancelMembership, getEmail, getMembership, goToCheckOut, signOut } from '../Logic/AccountHandler';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { BrowserRouter as Routes, Navigate, Redirect, Route, Router, useNavigate } from 'react-router-dom';

export default function PurchaseUnsuccessful() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column',
            }}
        >
            <Typography variant="h4" style={{ color: 'white' }}>
                There was an error with your purchase!
            </Typography>
            <Typography variant="h6" style={{ color: 'white' }}>
                Please contact us at alloysolutionsus@gmail.com for assistance.
            </Typography>
            <Typography variant="h6" style={{ color: 'white' }}>
                Thank you for using Priorityze!
            </Typography>
        </div>
    );
}

export async function removeSelfFromList(listId) {
    if (window.confirm('Are you sure you want to remove yourself from this list?')) {
        const res = await fetch('https://api.priorityze.app/removeSelfFromList', {
            method: 'POST',
            body: JSON.stringify({
                listId: listId,
            }),
            credentials: 'include',
        });
        const body = await res.json();
        if (body.success == false) {
            alert(body.error);
        }
        if (window.refreshCollabManagement) {
            window.refreshCollabManagement();
        }
        return body;
    }
}

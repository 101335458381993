import { IconButton, TextField } from '@mui/material';
import * as React from 'react';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { createList } from '../../../Logic/ListHandler';
import { createCollabLists } from '../../../Logic/collabListLogic/collabListLogic';
import { Clear, Delete } from '@mui/icons-material';
export function CreateCollabListModal({ fetchLists }) {
    const [listName, setListName] = React.useState('');
    const [attendees, setAttendees] = React.useState([]);
    const [inputText, setInputText] = React.useState('');
    const [permission, setPermission] = React.useState('edit');

    const handleInputChange = (text) => {
        setInputText(text.target.value);
        if (
            text.target.value.includes(' ') ||
            text.target.value.includes(',') ||
            text.target.value.includes('\n') ||
            text.target.value.includes('\r') ||
            text.target.value.includes('\t')
        ) {
            handleAddEmail();
        }
    };

    const handleAddEmail = () => {
        if (inputText.trim() !== '') {
            if (attendees.length === 0) {
                setAttendees([inputText]);
            } else {
                setAttendees([...attendees, inputText]);
            }
            setInputText('');
        }
    };

    const handleRemoveEmail = (index) => {
        let temp = [...attendees];
        temp.splice(index, 1);

        setAttendees(temp);
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                }}
            >
                <IconButton
                    style={{ color: 'white', backgroundColor: 'black', borderRadius: 5, padding: 5, marginBottom: 2 }}
                    onClick={async () => {
                        await createCollabLists(listName, attendees, permission);
                        fetchLists();
                    }}
                >
                    <AddBoxIcon fontSize="large" />
                </IconButton>
                <StyledTextField
                    id="outlined-textarea"
                    label="List Name"
                    onChange={(e) => {
                        setListName(e.target.value);
                    }}
                    placeholder="Add a List"
                    fullWidth={true}
                    sx={{ marginBottom: 2, width: 200 }}
                />
            </div>
            <div
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
            >
                {attendees.map((item, index) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                marginRight: '2%',
                            }}
                        >
                            <Clear
                                sx={{ color: 'white' }}
                                onClick={() => {
                                    handleRemoveEmail(index);
                                }}
                            />
                            <h3 style={{ color: 'white' }}>{item}</h3>
                        </div>
                    );
                })}
            </div>
            <StyledTextField
                id="outlined-textarea"
                label="Email"
                onChange={(e) => {
                    setInputText(e.target.value);
                    if (
                        e.target.value.includes(' ') ||
                        e.target.value.includes(',') ||
                        e.target.value.includes('\n') ||
                        e.target.value.includes('\r') ||
                        e.target.value.includes('\t')
                    ) {
                        handleAddEmail();
                    }
                }}
                placeholder="Add an Email"
                fullWidth={true}
                value={inputText}
                sx={{ width: 200 }}
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                <Checkbox
                    sx={{ color: 'white' }}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setPermission('edit');
                        } else {
                            setPermission('read');
                        }
                    }}
                    defaultChecked={permission === 'edit' ? true : false}
                />
                <h3 style={{ color: 'white' }}>Can Edit</h3>
            </div>
        </div>
    );
}

const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});

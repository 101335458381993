import React from 'react';

export default function PrivacyPolicy() {
    return (
        <div>
            <h1 style={{ color: 'white' }}>Privacy Policy</h1>
            <p style={{ color: 'white' }}>
                Welcome to Priorityze! We are committed to protecting your personal information and your right to
                privacy. If you have any questions or concerns about our policy, or our practices with regards to your
                personal information, please contact us at alloysolutionsus@gmail.com.
            </p>
            <h2 style={{ color: 'white' }}>Information We Collect</h2>
            <p style={{ color: 'white' }}>We collect the following information when you use our app:</p>
            <ul style={{ color: 'white' }}>
                <l1>
                    <strong>User account Information</strong>
                    When you create an account with us or sync your google calendar, we collect your email address and
                    optionally calendar events
                </l1>
                <li>
                    <strong>Task and Subtask Data:</strong> We store tasks and subtasks you create when you have a
                    premium account with us so we can seamlessly synchronize all your tasks. The information we store
                    includes the title, description, dates, and any other information tied to the task
                </li>
            </ul>
            <h2 style={{ color: 'white' }}>How We Use Your Information</h2>
            <p style={{ color: 'white' }}>We use the information we collect or receive:</p>
            <ul style={{ color: 'white' }}>
                <li>
                    <strong>Syncing your tasks</strong> We use your email address to sync your tasks between devices and
                    to sync your google calendar events
                </li>
            </ul>
            <h2 style={{ color: 'white' }}>Will Your Information Be Shared With Anyone?</h2>
            <p style={{ color: 'white' }}>
                We will not share any information with anyone. We do not sell or rent your personal information to
                anyone. We do not share your personal information with any third parties for their marketing purposes.
            </p>
            <h2 style={{ color: 'white' }}>How Long Do We Keep Your Information?</h2>
            <p style={{ color: 'white' }}>
                We will only keep your personal information for as long as it is necessary for the purposes set out in
                this privacy policy, unless a longer retention period is required or permitted by law (such as tax,
                accounting or other legal requirements). No purpose in this policy will require us keeping your personal
                information for longer than 1 year.
            </p>
            <h2 style={{ color: 'white' }}>Contact Us</h2>
            <p style={{ color: 'white' }}>
                If you have any questions or concerns about our privacy policy, please contact us at
                alloysolutionsus@gmail.com
            </p>
            <h2 style={{ color: 'white' }}>Changes to Our Privacy Policy</h2>
            <p style={{ color: 'white' }}>
                {' '}
                We reserve the right to change this privacy policy at any time. If we make changes, we will notify you
                by revising the date at the top of the policy and, in some cases, we may provide you with additional
                notice (such as adding a statement to our homepage or sending you a notification). We encourage you to
                review this privacy policy frequently to stay informed of the latest modifications.{' '}
            </p>
        </div>
    );
}

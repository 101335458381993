export async function removeUserFromList(listId, email) {
    const res = await fetch('https://api.priorityze.app/removeAttendee', {
        method: 'POST',
        body: JSON.stringify({
            list_id: listId,
            attendee_email: email,
        }),
        credentials: 'include',
    });
    const body = await res.json();
    if (body.success == false) {
        alert(body.error);
    }
    if (window.refreshCollabManagement) {
        window.refreshCollabManagement();
    }
    return body;
}

import MenuItem from '@mui/material/MenuItem';
import { getMembership } from './AccountHandler';
export let listChoices = [{ option: 'All' }];

export async function localStoreList() {
    //store list in local storage
    localStorage.setItem('listChoices', JSON.stringify(listChoices));
    return;
}

export async function localReadList() {
    //read list from local storage\

    const raw = localStorage.getItem('listChoices');
    if (raw) listChoices = JSON.parse(raw);

    return listChoices;
}

export async function readList() {
    let membership = getMembership();

    if (membership == 'free') {
        await localReadList();
        return listChoices;
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/readList', {
            method: 'GET',
            credentials: 'include',
        });

        const body = await response.json();
        listChoices = body.list;

        return body.list;
    } else {
        return listChoices;
    }
    //refresh list options
}

export async function createList(name) {
    let membership = getMembership();

    //if free, store in local storage
    if (membership == 'free') {
        listChoices.push({
            option: name,
        });
        await localStoreList();
        return;
    }
    //if premium, store in database
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/addList', {
            method: 'POST',
            body: JSON.stringify({
                name: name,
            }),
            credentials: 'include',
        });
        const body = await response.json();
        if (body.success == true) {
            listChoices.push(name);
        }
        return;
    }
}

export async function deleteList(name) {
    let membership = getMembership();
    //if free, store in local storage
    if (membership == 'free') {
        const temp = [...listChoices];
        listChoices = temp.filter((item) => item.option !== name);
        await localStoreList();
        return;
    }
    //if premium, store in database
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/deleteList', {
            method: 'POST',
            body: JSON.stringify({
                name: name,
            }),
            credentials: 'include',
        });
        const body = await response.json();
        if (body.success == true) {
            //get array of lists from body.list_name
            const temp = [...listChoices];
            listChoices = temp.filter((item) => item !== name);
        }
        return;
    }
}

export function DisplayList() {
    return listChoices.map((item) => {
        return (
            <MenuItem key={item} value={item}>
                {item}
            </MenuItem>
        );
    });
}

import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { BrowserRouter as Routes, Navigate, Redirect, Route, Router, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import SignInScreen from '../../pages/SignInScreen';
import { getMembership, signOut } from '../../Logic/AccountHandler';
import { Menu } from '@mui/material';
import { MenuItem } from '@mui/material';
function Header(props) {
    const { toggleDrawer, title } = props;
    let redirectToAppStore = 'https://priorityze.app/';

    if (navigator.userAgent.match(/Android/i)) {
        redirectToAppStore = 'https://play.google.com/store/apps/details?id=com.vinayk7.FocusProject&hl=en_US&gl=US';
    }
    if (navigator.userAgent.match(/iphone/i)) {
        redirectToAppStore = 'https://apps.apple.com/us/app/priorityze/id1623896428';
    }
    const navigate = useNavigate();
    const handleSignIn = () => {
        navigate('/signin');
    };
    const handleAccount = () => {
        navigate('/account');
    };
    const handleSignOut = () => {
        navigate('/');
    };
    const handleCollabManage = () => {
        navigate('/collab');
    };
    const handleCollabInvite = () => {
        navigate('/collabInvite');
    };
    const url = window.location.href;
    let isSignInScreen = false;
    if (url.includes('?dashboard')) {
        isSignInScreen = true;
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = () => {
        setAnchorEl(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    let membership = getMembership();
    let premiumMember = membership == 'premium' ? true : false;

    return (
        <div>
            <React.Fragment>
                <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', backgroundColor: 'black' }}>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={toggleDrawer}
                        sx={{ display: isSignInScreen ? 'block' : 'none' }}
                    >
                        Add
                    </Button>

                    <Typography
                        component="h2"
                        variant="h5"
                        color="inherit"
                        align="center"
                        noWrap
                        sx={{
                            flex: 1,
                            color: 'white',
                            marginLeft: isSignInScreen ? (window.innerWidth > 600 ? '3%' : '13%') : '0',
                        }}
                    >
                        {title}
                    </Typography>
                    <nav></nav>
                    <div>
                        <Button
                            variant="outlined"
                            size="small"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            onClick={() => {
                                if (isSignInScreen) {
                                    handleClick();
                                } else {
                                    handleSignIn();
                                }
                            }}
                        >
                            {isSignInScreen ? 'Account' : 'Sign In'}
                        </Button>
                        {isSignInScreen && (
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    //move it down a bit

                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        handleAccount();
                                    }}
                                >
                                    My account
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        signOut();
                                        handleSignOut();
                                        handleClose();
                                    }}
                                >
                                    Logout
                                </MenuItem>
                                {premiumMember && (
                                    <MenuItem
                                        onClick={() => {
                                            handleCollabManage();
                                            handleClose();
                                        }}
                                    >
                                        Manage Collaborations
                                    </MenuItem>
                                )}
                                {premiumMember && (
                                    <MenuItem
                                        onClick={() => {
                                            handleCollabInvite();
                                            handleClose();
                                        }}
                                    >
                                        Manage Invites
                                    </MenuItem>
                                )}
                            </Menu>
                        )}
                    </div>
                </Toolbar>
                <Toolbar
                    component="nav"
                    variant="dense"
                    sx={{ justifyContent: 'center', overflowX: 'auto', backgroundColor: 'black' }}
                >
                    <Link style={{ marginRight: 20 }} target="_blank">
                        <IconButton>
                            <InstagramIcon
                                sx={{ color: 'white' }}
                                onClick={() => window.open('https://www.instagram.com/priorityzeapp/', '_blank')}
                            />
                        </IconButton>
                    </Link>
                    <Link to="" style={{ marginRight: 20 }}>
                        <IconButton>
                            <TwitterIcon
                                onClick={() => window.open('https://twitter.com/priorityze', '_blank')}
                                sx={{ color: 'white' }}
                            />
                        </IconButton>
                    </Link>
                    <Link to="" style={{ marginRight: 20 }}>
                        <IconButton>
                            <SmartphoneIcon
                                onClick={() => window.open(redirectToAppStore, '_blank')}
                                sx={{ color: 'white' }}
                            />
                        </IconButton>
                    </Link>
                </Toolbar>
            </React.Fragment>
        </div>
    );
}

export default Header;

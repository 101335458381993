import { getMembership } from '../AccountHandler';

export let collabLists = [];

export let collabInvites = [];

export async function readCollabLists() {
    if (getMembership() == 'free') {
        return;
    } else {
        const response = await fetch('https://api.priorityze.app/getCollabLists', {
            method: 'GET',
            credentials: 'include',
        });

        const body = await response.json();
        if (body.success == true) {
            collabLists = body.list;
        }

        return body;
    }
}

function generateString(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export async function createCollabLists(listName, attendees, permission) {
    let listId = generateString(10);
    if (getMembership() == 'premium') {
        const response = await fetch('https://api.priorityze.app/createCollabList', {
            method: 'POST',
            body: JSON.stringify({
                name: listName,
                attendees: attendees,
                permission: permission,
                list_id: listId,
            }),
            credentials: 'include',
        });
        const body = await response.json();
        if (body.success == true) {
            collabLists.push({ list_id: body.listId, option: listName });
        }
        return body;
    }
    if (window.refreshCollabManagement) {
        window.refreshCollabManagement();
    }
}

export async function readCollabInvites() {
    if (getMembership == 'premium') {
        const response = await fetch('https://api.priorityze.app/getCollabInvites', {
            method: 'GET',
            credentials: 'include',
        });

        const body = await response.json();
        if (body.success == true) {
            collabInvites = body.collabInvites;
        }

        return body;
    } else {
        return;
    }
}

export async function collabInviteResponse(listId, response, permission, listName) {
    const res = await fetch('https://api.priorityze.app/collabInviteResponse', {
        method: 'POST',
        body: JSON.stringify({
            listId: listId,
            response: response,
            permission: permission,
            listName: listName,
        }),
        credentials: 'include',
    });
    const body = await res.json();
    if (window.refreshCollabInvite) {
        window.refreshCollabInvite();
    }
    return body;
}

export async function sendCollabInvite(listId, email, permission) {
    const res = await fetch('https://api.priorityze.app/sendCollabInvite', {
        method: 'POST',
        body: JSON.stringify({
            list_id: listId,
            attendee_email: email,
            permission: permission,
        }),
        credentials: 'include',
    });
    const body = await res.json();
    if (body.success == false) {
        if (body.error == 'User is already a collaborator') {
            alert('User is already a collaborator');
        }
        if (body.error == 'User is not a premium member') {
            alert('User is not a premium member');
        }
        if (body.error == 'You are not the owner of this list') {
            alert('You are not the owner of this list');
        }
    } else {
        alert('Invite sent');
    }
    return body;
}

import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Button, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { alpha, styled } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';

export function TaskDeadlineModal({
    setDeadline,
    deadline,
    startDate,
    setAllDay,
    allDay,
    userSelectedDate,
    setRepeatedDays,
    repeatedDays,
    setDuration,
    setStartDate,
    userSelectedStartDate,
}) {
    const [checked, setChecked] = React.useState(allDay);
    //change all day to true
    const handleAllDay = (event) => {
        setChecked(event.target.checked);
        setAllDay(event.target.checked);
    };
    //RepeatedDays
    const days = ['Daily', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const handleRepeatedDays = (event) => {
        const {
            target: { value },
        } = event;
        setRepeatedDays(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };
    const [enableStartDate, setEnableStartDate] = React.useState(false);
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <div>
                <h2 style={{ textAlign: 'center', fontWeight: 500, marginBottom: 0, marginTop: 1, color: '#A6a6a6' }}>
                    {' '}
                    Deadline{' '}
                </h2>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',

                        padding: 0,
                    }}
                >
                    <Button
                        variant="outlined"
                        style={{
                            color: 'white',
                            borderColor: 'white',
                            justifyContent: 'center',

                            alignItems: 'center',
                            marginTop: 4,
                            marginBottom: 15,
                            marginRight: '2%',
                        }}
                        onClick={() => {
                            setEnableStartDate(!enableStartDate);
                            userSelectedStartDate(!enableStartDate);
                        }}
                    >
                        {enableStartDate == false ? 'Enable Start Date' : 'Disable Start Date'}
                    </Button>
                    <FormGroup sx={{ justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                        <FormControlLabel
                            sx={{ color: 'White' }}
                            control={<RedSwitch onChange={handleAllDay} />}
                            label="All Day"
                        />
                    </FormGroup>
                </div>
            </div>
            {allDay == false && (
                <div>
                    {enableStartDate == true && (
                        <div
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 4,
                                marginBottom: 15,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => {
                                        userSelectedDate(true);
                                        setStartDate(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'outlined',
                                            sx: {
                                                [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                                                    {
                                                        borderColor: '#262626',
                                                    },
                                                [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                                                    {
                                                        borderColor: '#262626',
                                                    },
                                                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                                                    {
                                                        borderColor: '#262626',
                                                    },
                                                [`& .${outlinedInputClasses.input}`]: {
                                                    color: '#F2F2F2',
                                                },
                                                [`&:hover .${outlinedInputClasses.input}`]: {
                                                    color: '#F2F2F2',
                                                },
                                                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
                                                    {
                                                        color: '#F2F2F2',
                                                    },
                                                [`& .${inputLabelClasses.outlined}`]: {
                                                    color: '#F2F2F2',
                                                },
                                                [`&:hover .${inputLabelClasses.outlined}`]: {
                                                    color: '#F2F2F2',
                                                },
                                                [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
                                                    color: '#F2F2F2',
                                                },
                                            },
                                        },
                                        openPickerButton: { style: { color: 'white' } },
                                        openPickerIcon: { style: { color: 'white' } },
                                    }}
                                    //Change text color of mui date picker

                                    //Change the border color of mui date picker

                                    //customize background in Mui DatePicker
                                />
                            </LocalizationProvider>
                        </div>
                    )}
                    <div
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 4,
                            marginBottom: '1%',
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Deadline"
                                minDate={startDate}
                                onChange={(newValue) => {
                                    userSelectedDate(true);
                                    setDeadline(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        sx: {
                                            [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                                                {
                                                    borderColor: '#262626',
                                                },
                                            [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                                                {
                                                    borderColor: '#262626',
                                                },
                                            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                                                {
                                                    borderColor: '#262626',
                                                },
                                            [`& .${outlinedInputClasses.input}`]: {
                                                color: '#F2F2F2',
                                            },
                                            [`&:hover .${outlinedInputClasses.input}`]: {
                                                color: '#F2F2F2',
                                            },
                                            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
                                                {
                                                    color: '#F2F2F2',
                                                },
                                            [`& .${inputLabelClasses.outlined}`]: {
                                                color: '#F2F2F2',
                                            },
                                            [`&:hover .${inputLabelClasses.outlined}`]: {
                                                color: '#F2F2F2',
                                            },
                                            [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
                                                color: '#F2F2F2',
                                            },
                                        },
                                    },
                                    openPickerButton: { style: { color: 'white' } },
                                    openPickerIcon: { style: { color: 'white' } },
                                }}
                                //Change text color of mui date picker

                                //Change the border color of mui date picker

                                //customize background in Mui DatePicker
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            )}
            {allDay == true && (
                <div>
                    {enableStartDate == true && (
                        <div
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 4,
                                marginBottom: 15,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => {
                                        userSelectedDate(true);
                                        setStartDate(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'outlined',
                                            sx: {
                                                [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                                                    {
                                                        borderColor: '#262626',
                                                    },
                                                [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                                                    {
                                                        borderColor: '#262626',
                                                    },
                                                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                                                    {
                                                        borderColor: '#262626',
                                                    },
                                                [`& .${outlinedInputClasses.input}`]: {
                                                    color: '#F2F2F2',
                                                },
                                                [`&:hover .${outlinedInputClasses.input}`]: {
                                                    color: '#F2F2F2',
                                                },
                                                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
                                                    {
                                                        color: '#F2F2F2',
                                                    },
                                                [`& .${inputLabelClasses.outlined}`]: {
                                                    color: '#F2F2F2',
                                                },
                                                [`&:hover .${inputLabelClasses.outlined}`]: {
                                                    color: '#F2F2F2',
                                                },
                                                [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
                                                    color: '#F2F2F2',
                                                },
                                            },
                                        },
                                        openPickerButton: { style: { color: 'white' } },
                                        openPickerIcon: { style: { color: 'white' } },
                                    }}
                                    //Change text color of mui date picker

                                    //Change the border color of mui date picker

                                    //customize background in Mui DatePicker
                                />
                            </LocalizationProvider>
                        </div>
                    )}
                    <div
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',

                            marginTop: 4,
                            marginBottom: '1%',
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Deadline"
                                minDate={startDate}
                                onChange={(newValue) => {
                                    userSelectedDate(true);
                                    setDeadline(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        sx: {
                                            [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                                                {
                                                    borderColor: '#262626',
                                                },
                                            [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                                                {
                                                    borderColor: '#262626',
                                                },
                                            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                                                {
                                                    borderColor: '#262626',
                                                },
                                            [`& .${outlinedInputClasses.input}`]: {
                                                color: '#F2F2F2',
                                            },
                                            [`&:hover .${outlinedInputClasses.input}`]: {
                                                color: '#F2F2F2',
                                            },
                                            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
                                                {
                                                    color: '#F2F2F2',
                                                },
                                            [`& .${inputLabelClasses.outlined}`]: {
                                                color: '#F2F2F2',
                                            },
                                            [`&:hover .${inputLabelClasses.outlined}`]: {
                                                color: '#F2F2F2',
                                            },
                                            [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
                                                color: '#F2F2F2',
                                            },
                                        },
                                    },
                                    openPickerButton: { style: { color: 'white' } },
                                    openPickerIcon: { style: { color: 'white' } },
                                }}
                                //Change text color of mui date picker

                                //Change the border color of mui date picker

                                //customize background in Mui DatePicker
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            )}
            <div>
                <FormControl
                    sx={{ m: 1, width: 300, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                >
                    <div style={{ marginRight: 4 }}>
                        <InputLabel
                            id="demo-multiple-checkbox-label"
                            sx={{
                                marginLeft: 8,
                                color: 'white',
                                '&.Mui-focused': {
                                    color: 'white',
                                },
                            }}
                        >
                            Repeats
                        </InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={repeatedDays}
                            onChange={handleRepeatedDays}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            sx={{
                                width: 150,
                                border: '1px solid #262626',
                                borderRadius: '5px',
                                color: 'white',
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                            }}
                        >
                            {days.map((dayItems) => (
                                <MenuItem key={dayItems} value={dayItems}>
                                    <Checkbox checked={repeatedDays.indexOf(dayItems) > -1} />
                                    <ListItemText primary={dayItems} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </FormControl>
            </div>
        </div>
    );
}

const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#262626',
        '&:hover': {
            backgroundColor: alpha('#262626', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#e32f45',
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#B3B3B3',
    },
}));
const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});

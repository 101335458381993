import * as React from 'react';
import { Button, Rating, SwipeableDrawer, TextField } from '@mui/material';
import { TaskCategoryModal } from './Category/TaskCategoryModal';
import { categoryChoices, readCategory } from '../../Logic/CategoryHandler';
import { listChoices, readList } from '../../Logic/ListHandler';
import { TaskListModal } from './List/TaskListModal';
import { TaskDeadlineModal } from './Deadline/TaskDeadlineModal';
import { createTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { TaskNotificationModal } from './Notification/TaskNotificationModal';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { StarBorder, StarBorderOutlined } from '@mui/icons-material';
import { addToList, testingFunction, updateTask } from '../../Logic/MainTaskHandler';
import { TaskEditCategoryModal } from './Category/TaskEditCategoryModal';
import { EditTaskListModal } from './List/EditTaskListModal';
import { EditTaskDeadlineModal } from './Deadline/EditTaskDeadlineModal';
import { EditTaskNotificationModal } from './Notification/EdiTaskNotificationModal';
import { TaskStatusModal } from './StatusModal';
import { TaskLocationModal } from './Location/LocationModal';

export function EditTaskModal({ openAddModal, toggleDrawer, item, setItem }) {
    let modalWidth = '35%';
    let screenWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    if (screenWidth <= 1200) {
        modalWidth = '100%';
    }
    //Title and Description
    const [title, setTitle] = React.useState(item.taskTitle);
    const [description, setDescription] = React.useState(item.taskDescription);

    //Date
    //create a new date object with 'MM/DD/YYYY' format
    let defaultDate;
    let defaultStartDate;
    let startDateSelected = false;
    let defaultAllDayEvent = false;

    if (item.taskDueDate.length > 0) {
        if (item.taskDeadlineTime.length > 0) {
            //create date with MM/DD/YYYY and hh:mm A format
            defaultDate = dayjs(item.taskDueDate + ' ' + item.taskDeadlineTime, 'MM/DD/YYYY hh:mm A');
            defaultAllDayEvent = false;
        } else {
            //create date with MM/DD/YYYY format
            defaultDate = dayjs(item.taskDueDate, 'MM/DD/YYYY');
            defaultAllDayEvent = true;
        }
    }
    let defaultRepeatedDate = [];
    //item.days_repeated is in format of a string with each day separated by a comma
    if (item.taskDaysRepeated.length > 0) {
        defaultRepeatedDate = item.taskDaysRepeated.split(',');
    }
    if (item.taskStartDate.length > 0) {
        startDateSelected = true;
        if (item.taskStartTime.length > 0) {
            defaultStartDate = dayjs(item.taskStartDate + ' ' + item.taskStartTime, 'MM/DD/YYYY hh:mm A');
        } else {
            defaultStartDate = dayjs(item.taskStartDate, 'MM/DD/YYYY');
        }
    }

    //

    //make that date the default date
    const [date, setDate] = React.useState(dayjs(defaultDate));
    const [startDate, setStartDate] = React.useState(dayjs(defaultStartDate));
    const [userSelectedStartDate, setUserSelectedStartDate] = React.useState(startDateSelected);
    const [userSelectedDate, setUserSelectedDate] = React.useState(false);
    const [allDayEvent, setAllDayEvent] = React.useState(defaultAllDayEvent);
    const [repeatedDate, setRepeatedDate] = React.useState(defaultRepeatedDate);
    const [duration, setDuration] = React.useState(item.taskDuration);

    const [dateChanged, setDateChanged] = React.useState(false);

    //Rating
    const [rating, setRating] = React.useState(item.taskRating);
    const [ratingChanged, setRatingChanged] = React.useState(false);

    //Category

    const [category, setCategory] = React.useState({
        option: item.taskCategory,
        color: item.taskColor,
    });
    const [categoryName, setCategoryName] = React.useState(item.taskCategory === 'None' ? '' : item.taskCategory);
    const [categoryChanged, setCategoryChanged] = React.useState(false);
    //List
    const [list, setList] = React.useState(item.taskList.length > 0 ? item.taskList : 'All');
    const [listId, setListId] = React.useState(item.listId ? item.listId : '');
    const [listChanged, setListChanged] = React.useState(false);

    //Notification
    const [notificationChosen, setNotificationChosen] = React.useState(0);
    const [timeInterval, setTimeInterval] = React.useState('');
    const [notificationTime, setNotificationTime] = React.useState(dayjs(new Date()));
    const [notificationDate, setNotificationDate] = React.useState(dayjs(new Date()));
    const [notificationRepeat, setNotificationRepeat] = React.useState(false);
    const [notificationDayChosen, setNotificationDayChosen] = React.useState('');
    const [notificationChanged, setNotificationChanged] = React.useState(false);

    //Location
    const [startLocation, setStartLocation] = React.useState(item.startLocation ? item.startLocation : '');
    const [endLocation, setEndLocation] = React.useState(item.endLocation ? item.endLocation : '');
    const [startPlaceId, setStartPlaceId] = React.useState(item.startPlaceId ? item.startPlaceId : '');
    const [endPlaceId, setEndPlaceId] = React.useState(item.endPlaceId ? item.endPlaceId : '');
    const [locationHours, setLocationHours] = React.useState(item.locationHours ? item.locationHours : '');
    const [locationMinutes, setLocationMinutes] = React.useState(item.locationMinutes ? item.locationMinutes : '');

    //status
    const [status, setStatus] = React.useState(item.status ? item.status : 'None');

    return (
        <SwipeableDrawer
            anchor={'left'}
            open={openAddModal}
            onClose={() => {
                toggleDrawer();
                //reset all values
                setTitle('');
                setDescription('');
                setList('All');
                setRating(2);
                setCategory(categoryChoices[0]);
                setDate(dayjs(new Date()));
                setStartDate(dayjs(new Date()));
                setUserSelectedDate(false);
                setUserSelectedStartDate(false);
                setAllDayEvent(false);
                setNotificationRepeat(false);
                setNotificationDayChosen('');
                setItem({});
            }}
            onOpen={toggleDrawer}
            sx={{
                color: 'white',
                width: 240,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: modalWidth,
                    boxSizing: 'border-box',
                    marginTop: 8,
                    overflow: 'auto',
                    height: '100%',
                    backgroundColor: 'black',
                },
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: 15,
                }}
            >
                <h1 style={{ textAlign: 'center', fontWeight: 700, color: '#A6a6a6' }}>The Task</h1>
                <StyledTextField
                    id="outlined-textarea"
                    label="Title"
                    placeholder="Add a Title"
                    defaultValue={title}
                    fullWidth={true}
                    sx={{ marginBottom: 2, width: '80%', borderColor: 'red' }}
                    variant="outlined"
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                    multiline
                />
                <StyledTextField
                    id="outlined-textarea"
                    label="Description"
                    placeholder="Add a Description"
                    fullWidth={true}
                    defaultValue={item.taskDescription}
                    sx={{ marginBottom: 2, width: '80%' }}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                    multiline
                />
                <EditTaskDeadlineModal
                    setDeadline={setDate}
                    deadline={date}
                    setAllDay={setAllDayEvent}
                    allDay={allDayEvent}
                    userSelectedDate={setUserSelectedDate}
                    repeatedDays={repeatedDate}
                    setRepeatedDays={setRepeatedDate}
                    setDuration={setDuration}
                    setDateChanged={setDateChanged}
                    duration={duration}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    defaultUserSelectedStartDate={userSelectedStartDate}
                    userSelectedStartDate={setUserSelectedStartDate}
                />
                <h2 style={{ textAlign: 'center', fontWeight: 500, marginBottom: 0, marginTop: 1, color: '#A6a6a6' }}>
                    {' '}
                    Rating
                </h2>
                <Rating
                    defaultValue={item.taskRating}
                    value={rating}
                    sx={{ color: 'white' }}
                    max={4}
                    size="large"
                    onChange={(_event, newValue) => {
                        setRating(newValue);
                        setRatingChanged(true);
                    }}
                    emptyIcon={<StarBorderOutlined fontSize="inherit" style={{ color: 'white' }} />}
                />
                <TaskEditCategoryModal
                    setCategory={setCategory}
                    categoryName={categoryName}
                    setCategoryChanged={setCategoryChanged}
                />

                <EditTaskListModal
                    setList={setList}
                    list={list}
                    setListChanged={setListChanged}
                    setListId={setListId}
                />

                <EditTaskNotificationModal
                    notificationChosen={notificationChosen}
                    setNotificationChosen={setNotificationChosen}
                    setTimeInterval={setTimeInterval}
                    setNotificationTime={setNotificationTime}
                    setNotificationDate={setNotificationDate}
                    setNotificationRepeat={setNotificationRepeat}
                    setNotificationDayChosen={setNotificationDayChosen}
                    notificationDate={notificationDate}
                    notificationDayChosen={notificationDayChosen}
                    notificationTime={notificationTime}
                    setReminderChanged={setNotificationChanged}
                />
                <TaskStatusModal setStatus={setStatus} originalStatus={status} />

                <TaskLocationModal
                    setStartLocation={setStartLocation}
                    setEndLocation={setEndLocation}
                    setStartPlaceId={setStartPlaceId}
                    setEndPlaceId={setEndPlaceId}
                    setLocationHours={setLocationHours}
                    setLocationMinutes={setLocationMinutes}
                    originalStartLocation={startLocation}
                    originalEndLocation={endLocation}
                    originalStartPlaceId={startPlaceId}
                    originalEndPlaceId={endPlaceId}
                    originalLocationHours={locationHours}
                    originalLocationMinutes={locationMinutes}
                    userSelectedStartDate={userSelectedStartDate}
                    userSelectedTime={userSelectedDate}
                    startDate={startDate}
                    dueDate={date}
                    allday={allDayEvent}
                />
                <Button
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        width: '80%',
                        backgroundColor: '#A6a6a6',
                        color: 'black',
                        fontWeight: 700,
                        '&:hover': {
                            backgroundColor: '#A6a6a6',
                            color: 'black',
                        },
                    }}
                    onClick={() => {
                        updateTask(
                            item.taskID,
                            title,
                            description,
                            list,
                            rating,
                            category,
                            item.taskDueDate,
                            item.taskDeadlineTime,
                            item.taskStartDate,
                            item.taskStartTime,
                            date,
                            startDate,
                            userSelectedDate,
                            userSelectedStartDate,
                            allDayEvent,
                            repeatedDate,
                            duration,
                            notificationChosen,
                            timeInterval,
                            notificationRepeat,
                            notificationDate,
                            notificationDayChosen,
                            notificationTime,
                            dateChanged,
                            listChanged,
                            categoryChanged,
                            item.taskChecked,
                            item.reminder_type,
                            notificationChanged,
                            status,
                            startLocation,
                            endLocation,
                            startPlaceId,
                            endPlaceId,
                            locationHours,
                            locationMinutes,
                            listId.length > 0 ? listId : null
                        );
                        //reset all values
                        setTitle('');
                        setDescription('');
                        setList('');
                        setRating(2);
                        setCategory(categoryChoices[0]);
                        setDate(dayjs(new Date()));
                        setStartDate(dayjs(new Date()));
                        setUserSelectedDate(false);
                        setUserSelectedStartDate(false);
                        setAllDayEvent(false);
                        setNotificationRepeat(false);
                        setNotificationDayChosen('');
                        setNotificationChanged(false);
                        setCategoryChanged(false);
                        setListChanged(false);
                        setDateChanged(false);
                        setRatingChanged(false);
                        setStatus('None');
                        setStartLocation('');
                        setEndLocation('');
                        setStartPlaceId('');
                        setEndPlaceId('');
                        setLocationHours('');
                        setLocationMinutes('');
                        setListId('');
                        setItem({});

                        toggleDrawer();
                    }}
                >
                    Confirm
                </Button>
            </div>

            <div style={{ minHeight: '15%' }}> </div>
        </SwipeableDrawer>
    );
}
const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});

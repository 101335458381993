import * as React from 'react';
import { IconButton, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { deleteList, listChoices } from '../../../Logic/ListHandler';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { createList } from '../../../Logic/ListHandler';

export function DeleteListModal({ fetchLists }) {
    const [listName, setListName] = React.useState('');
    let listOptions = [];
    const [selectedList, setSelectedList] = React.useState('None');
    for (let i = 1; i < listChoices.length; i++) {
        listOptions.push(listChoices[i]);
    }
    const handleList = (listItems) => {
        setListName(listItems.target.value);
        setSelectedList(listItems.target.value);
    };
    return (
        <div>
            <Box sx={{ minWidth: 120, marginTop: 2, flexDirection: 'row' }}>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            marginLeft: 4,
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }}
                    >
                        List
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedList}
                        label="Age"
                        onChange={handleList}
                        sx={{
                            marginBottom: 2,
                            width: 200,
                            marginLeft: 3,
                            border: '1px solid #262626',
                            borderRadius: '5px',
                            color: 'white',
                            //turn off focus border
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: ' #F2F2F2',
                            },
                        }}
                    >
                        {listOptions.map((item) => {
                            return (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
            <IconButton
                style={{
                    color: 'white',
                    backgroundColor: 'black',
                    borderRadius: 5,
                    padding: 5,
                    marginBottom: 2,
                    width: 200,
                    marginLeft: 25,
                    textAlign: 'center',
                    border: '1px solid #262626',
                }}
                onClick={async () => {
                    await deleteList(listName);
                    fetchLists();
                }}
            >
                <DeleteForeverIcon fontSize="large" />
            </IconButton>
        </div>
    );
}

import { getMembership } from './AccountHandler';

export let categoryChoices = [
    { option: 'Work', color: '#FF0000' },
    { option: 'School', color: '#00FF00' },
    { option: 'Personal', color: '#0000FF' },
    { option: 'None', color: '#FFFFFF' },
];

export async function localStoreCategory() {
    localStorage.setItem('categoryChoices', JSON.stringify(categoryChoices));
}
export async function localReadCategory() {
    const raw = localStorage.getItem('categoryChoices');
    if (raw) categoryChoices = JSON.parse(raw);
    return categoryChoices;
}
export async function readCategory() {
    let membership = getMembership();
    if (membership == 'free') {
        await localReadCategory();
        return categoryChoices;
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/readCategory', {
            method: 'GET',
            credentials: 'include',
        });
        const body = await response.json();
        categoryChoices = body.list;
        return body.list;
    } else {
        return categoryChoices;
    }
}

export async function createCategory(name, color) {
    let membership = getMembership();

    if (membership == 'free') {
        categoryChoices.push({ name: name, color: color });
        await localStoreCategory();
        return;
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/addCategory', {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                color: color,
            }),
            credentials: 'include',
        });
        const body = await response.json();
        if (body.success == true) {
            //get array of lists from body.list_name
            categoryChoices.push({ name: name, color: color });
        }
        return;
    }
}

export async function deleteCategory(name) {
    let membership = getMembership();
    if (membership == 'free') {
        const temp = [...categoryChoices];
        categoryChoices = temp.filter((category) => category.name != name);

        await localStoreCategory();
        return;
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/deleteCategory', {
            method: 'POST',
            body: JSON.stringify({
                name: name,
            }),
            credentials: 'include',
        });
        const body = await response.json();
        if (body.success == true) {
            //get array of lists from body.list_name
            const temp = [...categoryChoices];
            categoryChoices = temp.filter((category) => category.name != name);
        }
        return;
    }
}

import SubtaskComponent from '../Components/SubtaskComponent';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { getMembership } from './AccountHandler';
import { addHistorySubtask } from './HistoryTaskHandler';
import { ButtonBase } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { todoList } from './MainTaskHandler';
import { collabLists } from './collabListLogic/collabListLogic';
export let subtaskList = [];

export async function localReadSubtasks() {
    const raw = localStorage.getItem('subtaskList');
    if (raw) subtaskList = JSON.parse(raw);
    //if status is null or undefined, set to none
    for (let i = 0; i < subtaskList.length; i++) {
        subtaskList[i].status = subtaskList[i].status || 'None';
    }

    return subtaskList;
}
export async function localStoreSubtasks() {
    localStorage.setItem('subtaskList', JSON.stringify(subtaskList));
}

export async function readSubtaskFromDB() {
    let membership = getMembership();
    if (membership == 'free') {
        await localReadSubtasks();
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/readSubtask', {
            method: 'GET',
            cors: 'no-cors',
            credentials: 'include',
        });
        const body = await response.json();
        if (body.success == true) {
            subtaskList = body.subtask;
            return subtaskList;
        }
    } else {
        return subtaskList;
    }
}

function generateString(length) {
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export async function addToSubtask(
    taskID,
    title,
    description,
    rating,
    category,
    date,
    startDate,
    userSelectedDate,
    userSelectedStartDate,
    allDay,
    deadlineDaysRepeating,
    duration,
    getReminderOptions,
    reminderTime,
    repetition,
    reminderDate,
    dayforReminder,
    timeForReminder,
    status,
    startLocation,
    endLocation,
    startPlaceId,
    endPlaceId,
    locationHours,
    locationMinutes
) {
    if (title) {
        let subtaskID = generateString(10);
        let formattedString = '';
        let timeFormatted = '';
        let deadlineDaysRepeatingFormatted = '';
        if (userSelectedDate) {
            if (allDay) {
                formattedString = dayjs(date).format('MM/DD/YYYY');
            } else {
                formattedString = dayjs(date).format('MM/DD/YYYY');
                timeFormatted = dayjs(date).format('hh:mm A');
                timeFormatted = timeFormatted.replace('AM', 'am');
            }
            if (deadlineDaysRepeatingFormatted.includes('Daily')) {
                deadlineDaysRepeatingFormatted = 'Daily';
            }
            for (let i = 0; i < deadlineDaysRepeating.length; i++) {
                deadlineDaysRepeatingFormatted += deadlineDaysRepeating[i];
                if (i < deadlineDaysRepeating.length - 1) {
                    deadlineDaysRepeatingFormatted += ', ';
                }
            }
        }
        let startDateFormattedString = '';
        let startDateFormattedTime = '';
        if (userSelectedStartDate) {
            if (allDay) {
                startDateFormattedString = dayjs(startDate).format('MM/DD/YYYY');
            } else {
                startDateFormattedString = dayjs(startDate).format('MM/DD/YYYY');
                startDateFormattedTime = dayjs(startDate).format('hh:mm A');
                startDateFormattedTime = startDateFormattedTime.replace('AM', 'am');
            }
        }
        let membership = getMembership();
        if (membership == 'free') {
            subtaskList.push({
                id: taskID,
                subtaskID: subtaskID,
                title: title,
                description: description,
                category: category.option,
                color: category.color,
                reminder_time: null,
                rating: rating,
                duration: duration,
                duedate: formattedString,
                days_repeated: deadlineDaysRepeatingFormatted,
                deadline_time: timeFormatted,
                checked: false,
                startDate: startDateFormattedString,
                startTime: startDateFormattedTime,
                notificationIdentifier: '',
                calendarIdentifier: '',
                hidden: false,
                reminder_type: 'None',
                status: status,
                startLocation: startLocation,
                endLocation: endLocation,
                startPlaceId: startPlaceId,
                endPlaceId: endPlaceId,
                locationHours: locationHours,
                locationMinutes: locationMinutes,
            });
            await localStoreSubtasks();
        }
        if (membership == 'premium') {
            let itemIndex = todoList.findIndex((item) => item.taskID == taskID);
            if (todoList[itemIndex].listId) {
                let collabList = collabLists.find((item) => item.list_id == todoList[itemIndex].listId);

                let collabListPermission = collabList.permission;
                if (collabListPermission != 'edit') {
                    alert('You do not have permission to edit this list');
                    return;
                }
            }

            //setup reminderDate to be in new Date format
            let reminderDateFormatted = new Date(reminderDate);
            let reminderTimeFormatted = new Date(timeForReminder);
            const response = await fetch('https://api.priorityze.app/addSubtask', {
                method: 'POST',
                body: JSON.stringify({
                    title: title,
                    mainTaskId: taskID,
                    id: subtaskID,
                    description: description,
                    rating: rating,
                    category: category.option,
                    color: category.color,
                    reminder_time: null,
                    duration: duration,
                    duedate: formattedString,
                    days_repeated: deadlineDaysRepeatingFormatted,
                    deadline_time: timeFormatted.toLowerCase(),
                    startDate: startDateFormattedString,
                    startTime: startDateFormattedTime.toLowerCase(),
                    notificationIdentifier: '',
                    calendarIdentifier: '',
                    reminderOptions: getReminderOptions,
                    reminderTime: reminderTime,
                    reminderRepeat: repetition,
                    reminderDate: reminderDateFormatted,
                    reminderDay: dayforReminder,
                    reminderTimeForDate: reminderTimeFormatted,
                    repetitionForDayReminder: true,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    status: status,
                    startLocation: startLocation,
                    endLocation: endLocation,
                    startPlaceId: startPlaceId,
                    endPlaceId: endPlaceId,
                    locationHours: locationHours,
                    locationMinutes: locationMinutes,
                }),
                credentials: 'include',
            });
            const data = await response.json();
            if (data.success == true) {
                subtaskList.push({
                    id: taskID,
                    subtaskID: subtaskID,
                    title: title,
                    description: description,
                    category: category.option,
                    color: category.color,
                    reminder_time: null,
                    rating: rating,
                    duration: duration,
                    duedate: formattedString,
                    days_repeated: deadlineDaysRepeatingFormatted,
                    deadline_time: timeFormatted.toLowerCase(),
                    checked: 0,
                    startDate: startDateFormattedString,
                    startTime: startDateFormattedTime.toLowerCase(),
                    notificationIdentifier: '',
                    calendarIdentifier: '',
                    hidden: false,
                    reminder_type: data.reminder_type,
                    status: status,
                    startLocation: startLocation,
                    endLocation: endLocation,
                    startPlaceId: startPlaceId,
                    endPlaceId: endPlaceId,
                    locationHours: locationHours,
                    locationMinutes: locationMinutes,
                });
            } else {
            }
        }
    }
    window.refreshListScreen();
}

export async function updateSubtask(
    id,
    taskID,
    title,
    description,
    rating,
    category,
    originalDate,
    originalTime,
    originalStartDate,
    originalStartTime,
    date,
    startDate,
    userSelectedDate,
    userSelectedStartDate,
    allDay,
    deadlineDaysRepeating,
    duration,
    getReminderOptions,
    reminderTime,
    repetition,
    reminderDate,
    dayforReminder,
    timeForReminder,
    dateChanged,
    categoryChanged,
    checked,
    reminder_type,
    reminderChanged,
    status,
    startLocation,
    endLocation,
    startPlaceId,
    endPlaceId,
    locationHours,
    locationMinutes
) {
    if (title) {
        let dateToUse = originalDate;
        let formattedString = '';
        let timeFormatted = '';
        let startDateFormattedString = '';
        let startDateFormattedTime = '';
        let deadlineDaysRepeatingFormatted = '';
        if (userSelectedDate) {
            if (allDay) {
                formattedString = dayjs(date).format('MM/DD/YYYY');
            } else {
                formattedString = dayjs(date).format('MM/DD/YYYY');
                timeFormatted = dayjs(date).format('hh:mm A');
                timeFormatted = timeFormatted.replace('AM', 'am');
            }
            if (deadlineDaysRepeatingFormatted.includes('Daily')) {
                deadlineDaysRepeatingFormatted = 'Daily';
            }
            for (let i = 0; i < deadlineDaysRepeating.length; i++) {
                deadlineDaysRepeatingFormatted += deadlineDaysRepeating[i];
                if (i < deadlineDaysRepeating.length - 1) {
                    deadlineDaysRepeatingFormatted += ', ';
                }
            }
        } else {
            formattedString = originalDate;
            timeFormatted = originalTime;
        }
        if (userSelectedStartDate) {
            if (allDay) {
                startDateFormattedString = dayjs(startDate).format('MM/DD/YYYY');
            } else {
                startDateFormattedString = dayjs(startDate).format('MM/DD/YYYY');
                startDateFormattedTime = dayjs(startDate).format('hh:mm A');
                //to lowercase
                startDateFormattedTime = startDateFormattedTime.replace('AM', 'am');
            }
        } else {
            startDateFormattedString = originalStartDate;
            startDateFormattedTime = originalStartTime;
        }

        let membership = getMembership();
        if (membership == 'free') {
            for (let i = 0; i < subtaskList.length; i++) {
                if (subtaskList[i].id == taskID && subtaskList[i].subtaskID == id) {
                    subtaskList[i].title = title;
                    subtaskList[i].description = description;
                    subtaskList[i].category = category.option;
                    subtaskList[i].color = category.color;
                    subtaskList[i].rating = rating;
                    subtaskList[i].duration = duration;
                    subtaskList[i].duedate = formattedString;
                    subtaskList[i].days_repeated = deadlineDaysRepeatingFormatted;
                    subtaskList[i].deadline_time = timeFormatted;
                    subtaskList[i].checked = checked;
                    subtaskList[i].startDate = startDateFormattedString;
                    subtaskList[i].startTime = startDateFormattedTime;
                    subtaskList[i].notificationIdentifier = '';
                    subtaskList[i].calendarIdentifier = '';
                    subtaskList[i].status = status;
                    subtaskList[i].startLocation = startLocation;
                    subtaskList[i].endLocation = endLocation;
                    subtaskList[i].startPlaceId = startPlaceId;
                    subtaskList[i].endPlaceId = endPlaceId;
                    subtaskList[i].locationHours = locationHours;
                    subtaskList[i].locationMinutes = locationMinutes;
                }
            }
            await localStoreSubtasks();
        }
        if (membership == 'premium') {
            let itemIndex = todoList.findIndex((item) => item.taskID == taskID);
            if (todoList[itemIndex].listId) {
                let collabList = collabLists.find((item) => item.list_id == todoList[itemIndex].listId);
                let collabListPermission = collabList.permission;
                if (collabListPermission != 'edit') {
                    alert('You do not have permission to edit this list');
                    return;
                }
            }
            const response = await fetch('https://api.priorityze.app/editSubtask', {
                method: 'POST',
                body: JSON.stringify({
                    id: id,
                    mainTaskId: taskID,
                    title: title,
                    description: description,
                    category: category.option,
                    color: category.color,
                    reminder_time: null,
                    rating: rating,
                    duration: duration,
                    duedate: formattedString,
                    days_repeated: deadlineDaysRepeatingFormatted,
                    deadline_time: timeFormatted.toLowerCase(),
                    checked: checked,
                    startDate: startDateFormattedString,
                    startTime: startDateFormattedTime.toLowerCase(),
                    notificationIdentifier: '',
                    calendarIdentifier: '',
                    reminderOptions: getReminderOptions,
                    reminderTime: reminderTime,
                    reminderRepeat: repetition,
                    reminderDate: reminderDate,
                    reminderDay: dayforReminder,
                    reminderTimeForDate: timeForReminder,
                    repetitionForDayReminder: true,
                    reminder_type: reminder_type,
                    reminderChanged: reminderChanged,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    status: status,
                    startLocation: startLocation,
                    endLocation: endLocation,
                    startPlaceId: startPlaceId,
                    endPlaceId: endPlaceId,
                    locationHours: locationHours,
                    locationMinutes: locationMinutes,
                }),
                credentials: 'include',
            });
            const data = await response.json();
            if (data.success == true) {
                let index = subtaskList.findIndex((x) => x.id == taskID && x.subtaskID == id);
                subtaskList[index].title = title;
                subtaskList[index].description = description;
                subtaskList[index].category = category.option;
                subtaskList[index].color = category.color;
                subtaskList[index].rating = rating;
                subtaskList[index].duration = duration;
                subtaskList[index].duedate = formattedString;
                subtaskList[index].days_repeated = deadlineDaysRepeatingFormatted;
                subtaskList[index].deadline_time = timeFormatted.toLowerCase();
                subtaskList[index].checked = checked;
                subtaskList[index].startDate = startDateFormattedString;
                subtaskList[index].startTime = startDateFormattedTime.toLowerCase();
                subtaskList[index].notificationIdentifier = '';
                subtaskList[index].calendarIdentifier = '';
                subtaskList[index].reminder_type = data.reminder_type;
                subtaskList[index].status = status;
                subtaskList[index].startLocation = startLocation;
                subtaskList[index].endLocation = endLocation;
                subtaskList[index].startPlaceId = startPlaceId;
                subtaskList[index].endPlaceId = endPlaceId;
                subtaskList[index].locationHours = locationHours;
                subtaskList[index].locationMinutes = locationMinutes;
            } else {
            }
        }
    }
    window.refreshListScreen();
}

export async function addSubtaskFromHistorySubtask(
    id,
    taskID,
    title,
    category,
    color,
    description,
    reminder_time,
    rating,
    duration,
    duedate,
    days_repeated,
    deadline_time,
    startDate,
    startTime,
    notificationIdentifier,
    calendarIdentifier,
    reminder_type
) {
    let membership = getMembership();
    subtaskList.push({
        id: taskID,
        subtaskID: id,
        title: title,
        description: description,
        category: category,
        color: color,
        reminder_time: reminder_time,
        rating: rating,
        duration: duration,
        duedate: duedate,
        days_repeated: days_repeated,
        deadline_time: deadline_time,
        checked: false,
        startDate: startDate,
        startTime: startTime,
        notificationIdentifier: notificationIdentifier,
        calendarIdentifier: calendarIdentifier,
        hidden: false,
        reminder_type: reminder_type,
        status: 'None',
    });
    if (membership == 'free') {
        await localStoreSubtasks();
    }
    window.refreshListScreen();
}

export async function deleteSubtask(mainTaskId, id) {
    let membership = getMembership();
    if (membership == 'free') {
        let temp = [...subtaskList];
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].id == mainTaskId && temp[i].subtaskID == id) {
                await addHistorySubtask(
                    temp[i].id,
                    temp[i].subtaskID,
                    temp[i].title,
                    temp[i].category,
                    temp[i].color,
                    temp[i].description,
                    temp[i].reminder_time,
                    temp[i].rating,
                    temp[i].duration,
                    temp[i].duedate,
                    temp[i].days_repeated,
                    temp[i].deadline_time,
                    temp[i].startDate,
                    temp[i].startTime,
                    temp[i].notificationIdentifier,
                    temp[i].calendarIdentifier,
                    temp[i].reminder_type,
                    temp[i].startLocation,
                    temp[i].endLocation,
                    temp[i].startPlaceId,
                    temp[i].endPlaceId,
                    temp[i].locationHours,
                    temp[i].locationMinutes
                );
                temp.splice(i, 1);
            }
        }
        subtaskList = temp;
        await localStoreSubtasks();
    }
    if (membership == 'premium') {
        let itemIndex = todoList.findIndex((item) => item.taskID == mainTaskId);
        if (todoList[itemIndex].listId) {
            let collabList = collabLists.find((item) => item.list_id == todoList[itemIndex].listId);
            let collabListPermission = collabList.permission;
            if (collabListPermission != 'edit') {
                alert('You do not have permission to edit this list');
                return;
            }
        }

        const response = await fetch('https://api.priorityze.app/deleteSubtask', {
            method: 'POST',
            body: JSON.stringify({
                mainTaskId: mainTaskId,
                id: id,
            }),
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success == true) {
            let temp = [...subtaskList];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].id == mainTaskId && temp[i].subtaskID == id) {
                    await addHistorySubtask(
                        temp[i].id,
                        temp[i].subtaskID,
                        temp[i].title,
                        temp[i].category,
                        temp[i].color,
                        temp[i].description,
                        temp[i].reminder_time,
                        temp[i].rating,
                        temp[i].duration,
                        temp[i].duedate,
                        temp[i].days_repeated,
                        temp[i].deadline_time,
                        temp[i].startDate,
                        temp[i].startTime,
                        temp[i].notificationIdentifier,
                        temp[i].calendarIdentifier,
                        temp[i].reminder_type,
                        temp[i].startLocation,
                        temp[i].endLocation,
                        temp[i].startPlaceId,
                        temp[i].endPlaceId,
                        temp[i].locationHours,
                        temp[i].locationMinutes
                    );
                    temp.splice(i, 1);
                }
            }
            subtaskList = temp;
        } else {
        }
    }
    window.refreshListScreen();
}

export async function subtaskChecked(mainTaskId, id, checked) {
    let checkedBoolToNum = 0;
    if (checked == true) {
        checkedBoolToNum = 1;
    } else {
        checkedBoolToNum = 0;
    }
    let membership = getMembership();
    if (membership == 'free') {
        let item = subtaskList.find((item) => item.id == mainTaskId && item.subtaskID == id);
        item.checked = checkedBoolToNum;
        await localStoreSubtasks();
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/checkSubtask', {
            method: 'POST',
            body: JSON.stringify({
                mainTaskId: mainTaskId,
                id: id,
                checked: checkedBoolToNum,
            }),
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success == true) {
            let item = subtaskList.find((item) => item.id == mainTaskId && item.subtaskID == id);

            item.checked = checkedBoolToNum;
        } else {
        }
    }
    window.refreshListScreen();
}

export async function subtaskHidden(mainTaskId, id, hidden) {
    let hiddenBoolToNum = 0;
    if (hidden == true) {
        hiddenBoolToNum = 1;
    } else {
        hiddenBoolToNum = 0;
    }
    let membership = getMembership();
    if (membership == 'free') {
        let item = subtaskList.find((item) => item.id == mainTaskId && item.subtaskID == id);
        item.hidden = hiddenBoolToNum;
        await localStoreSubtasks();
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/hiddenSubtask', {
            method: 'POST',
            body: JSON.stringify({
                mainTaskId: mainTaskId,
                id: id,
                hidden: hiddenBoolToNum,
            }),
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success == true) {
            let item = subtaskList.find((item) => item.id == mainTaskId && item.subtaskID == id);

            item.hidden = hiddenBoolToNum;
        } else {
        }
    }
    window.refreshListScreen();
}

export function DisplaySubtaskList({
    parentID,
    subtasksFromParentComponent,
    handleEditSubtaskDrawer,
    setEditSubtaskObject,
}) {
    const [showHiddenSubtasks, setShowHiddenSubtasks] = React.useState(false);

    return (
        <div>
            {subtasksFromParentComponent.map((subtask) => {
                return (
                    <div>
                        {showHiddenSubtasks == false && (
                            <div>
                                {subtask.hidden == 0 && (
                                    <SubtaskComponent
                                        item={subtask}
                                        handleEditSubtaskDrawer={handleEditSubtaskDrawer}
                                        setEditSubtaskObject={setEditSubtaskObject}
                                        key={subtask.id}
                                    />
                                )}
                            </div>
                        )}
                        {showHiddenSubtasks == true && (
                            <div>
                                <SubtaskComponent
                                    item={subtask}
                                    handleEditSubtaskDrawer={handleEditSubtaskDrawer}
                                    setEditSubtaskObject={setEditSubtaskObject}
                                    key={subtask.id}
                                />
                            </div>
                        )}
                    </div>
                );
            })}
            {subtasksFromParentComponent.some((item) => item.hidden == 1) && (
                <div>
                    {showHiddenSubtasks == false && (
                        <ButtonBase onClick={() => setShowHiddenSubtasks(true)}>
                            <VisibilityOff style={{ color: 'white' }} />
                        </ButtonBase>
                    )}
                    {showHiddenSubtasks == true && (
                        <ButtonBase onClick={() => setShowHiddenSubtasks(false)}>
                            <Visibility style={{ color: 'white' }} />
                        </ButtonBase>
                    )}
                </div>
            )}
        </div>
    );
}

import FullCalendar from '@fullcalendar/react';
import * as React from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { readSubtaskFromDB, readTaskFromDB } from './MainTaskHandler';

import '../css/Calendar.css';
export function CalendarView({ tasks, subtasks }) {
    let events = [];

    for (let i = 0; i < tasks.length; i++) {
        let repeatedDays = [];
        let startTime;
        let endTime;
        let date;
        let startDate;
        let allDay = true;
        if (tasks[i].taskDueDate.length > 1 && tasks[i].hidden == false) {
            //get date object from 'MM/DD/YYYY'
            let tempDateTime = tasks[i].taskDueDate.split('/');
            date = tempDateTime[2] + '-' + tempDateTime[0] + '-' + tempDateTime[1];
            if (tasks[i].taskDeadlineTime.length > 1) {
                allDay = false;
                //get hours and minutes from 'HH:MM AM/PM'
                let hours = tasks[i].taskDeadlineTime.split(':')[0];
                let minutes = tasks[i].taskDeadlineTime.split(':')[1].split(' ')[0];
                let ampm = tasks[i].taskDeadlineTime.split(':')[1].split(' ')[1];

                if (ampm == 'pm') {
                    hours = parseInt(hours) + 12;
                }
                if (hours == 12 && ampm == 'am') {
                    hours = 0;
                }

                let tempDate = new Date(date);
                tempDate.setHours(hours);
                tempDate.setMinutes(minutes);
                endTime = tempDate;

                //get tasks[i].duration which is in minutes and subtract it from endTime
                if (tasks[i].taskStartDate.length > 1) {
                    let tempStartDateTime = tasks[i].taskStartDate.split('/');
                    startDate = tempStartDateTime[2] + '-' + tempStartDateTime[0] + '-' + tempStartDateTime[1];
                    if (tasks[i].taskStartTime.length > 1) {
                        let startHours = tasks[i].taskStartTime.split(':')[0];
                        let startMinutes = tasks[i].taskStartTime.split(':')[1].split(' ')[0];
                        let startAmpm = tasks[i].taskStartTime.split(':')[1].split(' ')[1];
                        if (startAmpm === 'pm') {
                            startHours = parseInt(startHours) + 12;
                        }
                        if (startHours == 12 && startAmpm == 'am') {
                            startHours = 0;
                        }

                        let tempStartDate = new Date(startDate);
                        tempStartDate.setHours(startHours);
                        tempStartDate.setMinutes(startMinutes);
                        startTime = tempStartDate;
                    }
                } else {
                    let durationHours = Math.floor(30 / 60);
                    let durationMinutes = 30 % 60;
                    let tempStartTime = new Date(endTime);
                    tempStartTime.setHours(tempStartTime.getHours() - durationHours);
                    tempStartTime.setMinutes(tempStartTime.getMinutes() - durationMinutes);
                    startTime = tempStartTime;
                }
            }
            if (tasks[i].taskDaysRepeated.length > 1) {
                if (tasks[i].taskDaysRepeated.toLowerCase().includes('daily')) {
                    repeatedDays.push(0, 1, 2, 3, 4, 5, 6);
                } else {
                    if (tasks[i].taskDaysRepeated.toLowerCase().includes('sunday')) {
                        repeatedDays.push(0);
                    }
                    if (tasks[i].taskDaysRepeated.toLowerCase().includes('monday')) {
                        repeatedDays.push(1);
                    }
                    if (tasks[i].taskDaysRepeated.toLowerCase().includes('tuesday')) {
                        repeatedDays.push(2);
                    }
                    if (tasks[i].taskDaysRepeated.toLowerCase().includes('wednesday')) {
                        repeatedDays.push(3);
                    }
                    if (tasks[i].taskDaysRepeated.toLowerCase().includes('thursday')) {
                        repeatedDays.push(4);
                    }
                    if (tasks[i].taskDaysRepeated.toLowerCase().includes('friday')) {
                        repeatedDays.push(5);
                    }
                    if (tasks[i].taskDaysRepeated.toLowerCase().includes('saturday')) {
                        repeatedDays.push(6);
                    }
                }
                if (allDay) {
                    let startDateForEvent = date;
                    if (tasks[i].taskStartDate.length > 1) {
                        startDateForEvent = startDate;
                    }
                    let tempTask = {
                        title: tasks[i].taskTitle,
                        description: tasks[i].taskDescription,
                        start: startDateForEvent,
                        end: endTime,
                        allDay: allDay,
                        backgroundColor: 'white',
                        textColor: 'black',
                        daysOfWeek: repeatedDays,
                        borderColor: tasks[i].taskColor,
                    };
                    events.push(tempTask);
                } else {
                    //make time like this hh:mm:ss
                    let tempEndTime = new Date(endTime);
                    let tempEndTimeHours = tempEndTime.getHours();
                    let tempEndTimeMinutes = tempEndTime.getMinutes();
                    let tempEndTimeSeconds = tempEndTime.getSeconds();
                    if (tempEndTimeHours < 10) {
                        tempEndTimeHours = '0' + tempEndTimeHours;
                    }
                    if (tempEndTimeMinutes < 10) {
                        tempEndTimeMinutes = '0' + tempEndTimeMinutes;
                    }
                    if (tempEndTimeSeconds < 10) {
                        tempEndTimeSeconds = '0' + tempEndTimeSeconds;
                    }
                    tempEndTime = tempEndTimeHours + ':' + tempEndTimeMinutes + ':' + tempEndTimeSeconds;
                    let startDateForEvent = tempEndTime;
                    if (tasks[i].taskStartDate.length > 1) {
                        let tempStartDateTime = new Date(startTime);
                        let tempStartDateTimeHours = tempStartDateTime.getHours();
                        let tempStartDateTimeMinutes = tempStartDateTime.getMinutes();
                        let tempStartDateTimeSeconds = tempStartDateTime.getSeconds();
                        if (tempStartDateTimeHours < 10) {
                            tempStartDateTimeHours = '0' + tempStartDateTimeHours;
                        }
                        if (tempStartDateTimeMinutes < 10) {
                            tempStartDateTimeMinutes = '0' + tempStartDateTimeMinutes;
                        }
                        if (tempStartDateTimeSeconds < 10) {
                            tempStartDateTimeSeconds = '0' + tempStartDateTimeSeconds;
                        }
                        tempStartDateTime =
                            tempStartDateTimeHours + ':' + tempStartDateTimeMinutes + ':' + tempStartDateTimeSeconds;
                        startDateForEvent = tempStartDateTime;
                    }
                    let tempTask = {
                        title: tasks[i].taskTitle,
                        description: tasks[i].taskDescription,
                        startTime: startDateForEvent,
                        endTime: tempEndTime,
                        allDay: allDay,
                        backgroundColor: 'white',
                        textColor: 'black',
                        daysOfWeek: repeatedDays,
                    };
                    events.push(tempTask);
                }
            } else {
                if (allDay) {
                    let startDateForEvent = date;
                    if (tasks[i].taskStartDate.length > 1) {
                        let tempStartDateTime = tasks[i].taskStartDate.split('-');
                        //set it up for Date object
                        startDateForEvent =
                            tempStartDateTime[1] + '/' + tempStartDateTime[2] + '/' + tempStartDateTime[0];
                        //
                    }

                    let tempTask = {
                        title: tasks[i].taskTitle,
                        description: tasks[i].taskDescription,
                        start: new Date(startDateForEvent),
                        end: new Date(date),
                        allDay: allDay,
                        backgroundColor: 'white',
                        textColor: 'black',
                        borderColor: tasks[i].taskColor,
                    };
                    events.push(tempTask);
                } else {
                    let startDateForEvent = endTime;
                    if (tasks[i].taskStartDate.length > 1) {
                        startDateForEvent = startTime;
                    }
                    let tempTask = {
                        title: tasks[i].taskTitle,
                        description: tasks[i].taskDescription,
                        start: startDateForEvent,
                        end: endTime,
                        allDay: allDay,
                        backgroundColor: 'white',
                        textColor: 'black',
                        borderColor: tasks[i].taskColor,
                        eventColor: 'red',
                    };
                    events.push(tempTask);
                }
            }
        }
    }

    for (let i = 0; i < subtasks.length; i++) {
        let repeatedDays = [];
        let startTime;
        let endTime;
        let date;
        let startDate;
        let allDay = true;
        if (subtasks[i].duedate.length > 1 && subtasks[i].hidden == false) {
            //get date object from 'MM/DD/YYYY'
            let tempDateTime = subtasks[i].duedate.split('/');
            date = tempDateTime[2] + '-' + tempDateTime[0] + '-' + tempDateTime[1];
            if (subtasks[i].deadline_time.length > 1) {
                allDay = false;
                //get hours and minutes from 'HH:MM AM/PM'
                let hours = subtasks[i].deadline_time.split(':')[0];
                let minutes = subtasks[i].deadline_time.split(':')[1].split(' ')[0];
                let ampm = subtasks[i].deadline_time.split(':')[1].split(' ')[1];
                if (ampm == 'pm') {
                    hours = parseInt(hours) + 12;
                }
                if (hours == 12 && ampm == 'am') {
                    hours = 0;
                }

                let tempDate = new Date(date);
                tempDate.setHours(hours);
                tempDate.setMinutes(minutes);
                endTime = tempDate;
                //get subtasks[i].duration which is in minutes and subtract it from endTime
                if (subtasks[i].startDate.length > 1) {
                    let tempStartDateTIme = subtasks[i].startDate.split('/');
                    startDate = tempStartDateTIme[2] + '-' + tempStartDateTIme[0] + '-' + tempStartDateTIme[1];
                    if (subtasks[i].startTime.length > 1) {
                        let startHours = subtasks[i].startTime.split(':')[0];
                        let startMinutes = subtasks[i].startTime.split(':')[1].split(' ')[0];
                        let startAmpm = subtasks[i].startTime.split(':')[1].split(' ')[1];
                        if (startAmpm == 'pm') {
                            startHours = parseInt(startHours) + 12;
                        }
                        if (startHours == 12 && startAmpm == 'am') {
                            startHours = 0;
                        }

                        let tempStartDate = new Date(startDate);
                        tempStartDate.setHours(startHours);
                        tempStartDate.setMinutes(startMinutes);
                        startTime = tempStartDate;
                    }
                } else {
                    let durationHours = Math.floor(30 / 60);
                    let durationMinutes = 30 % 60;
                    let tempStartTime = new Date(endTime);
                    tempStartTime.setHours(tempStartTime.getHours() - durationHours);
                    tempStartTime.setMinutes(tempStartTime.getMinutes() - durationMinutes);
                    startTime = tempStartTime;
                }
            }
            if (subtasks[i].days_repeated.length > 1) {
                if (subtasks[i].days_repeated.toLowerCase().includes('daily')) {
                    repeatedDays.push(0, 1, 2, 3, 4, 5, 6);
                } else {
                    if (subtasks[i].days_repeated.toLowerCase().includes('sunday')) {
                        repeatedDays.push(0);
                    }
                    if (subtasks[i].days_repeated.toLowerCase().includes('monday')) {
                        repeatedDays.push(1);
                    }
                    if (subtasks[i].days_repeated.toLowerCase().includes('tuesday')) {
                        repeatedDays.push(2);
                    }
                    if (subtasks[i].days_repeated.toLowerCase().includes('wednesday')) {
                        repeatedDays.push(3);
                    }
                    if (subtasks[i].days_repeated.toLowerCase().includes('thursday')) {
                        repeatedDays.push(4);
                    }
                    if (subtasks[i].days_repeated.toLowerCase().includes('friday')) {
                        repeatedDays.push(5);
                    }
                    if (subtasks[i].days_repeated.toLowerCase().includes('saturday')) {
                        repeatedDays.push(6);
                    }
                }
                if (allDay) {
                    let startDateForEvent = date;
                    if (subtasks[i].startDate.length > 1) {
                        startDateForEvent = startDate;
                    }
                    let tempTask = {
                        title: subtasks[i].title,
                        description: subtasks[i].description,
                        start: startDateForEvent,
                        end: endTime,
                        allDay: allDay,
                        backgroundColor: 'white',
                        textColor: 'black',
                        daysOfWeek: repeatedDays,
                        borderColor: subtasks[i].color,
                    };
                    events.push(tempTask);
                } else {
                    let tempEndTime = new Date(endTime);
                    let tempEndTimeHours = tempEndTime.getHours();
                    let tempEndTimeMinutes = tempEndTime.getMinutes();
                    let tempEndTimeSeconds = tempEndTime.getSeconds();
                    if (tempEndTimeHours < 10) {
                        tempEndTimeHours = '0' + tempEndTimeHours;
                    }
                    if (tempEndTimeMinutes < 10) {
                        tempEndTimeMinutes = '0' + tempEndTimeMinutes;
                    }
                    if (tempEndTimeSeconds < 10) {
                        tempEndTimeSeconds = '0' + tempEndTimeSeconds;
                    }
                    tempEndTime = tempEndTimeHours + ':' + tempEndTimeMinutes + ':' + tempEndTimeSeconds;
                    let startDateForEvent = tempEndTime;
                    if (subtasks[i].startDate.length > 1) {
                        let tempStartDateTime = new Date(startTime);
                        let tempStartDateTimeHours = tempStartDateTime.getHours();
                        let tempStartDateTimeMinutes = tempStartDateTime.getMinutes();
                        let tempStartDateTimeSeconds = tempStartDateTime.getSeconds();
                        if (tempStartDateTimeHours < 10) {
                            tempStartDateTimeHours = '0' + tempStartDateTimeHours;
                        }
                        if (tempStartDateTimeMinutes < 10) {
                            tempStartDateTimeMinutes = '0' + tempStartDateTimeMinutes;
                        }
                        if (tempStartDateTimeSeconds < 10) {
                            tempStartDateTimeSeconds = '0' + tempStartDateTimeSeconds;
                        }
                        tempStartDateTime =
                            tempStartDateTimeHours + ':' + tempStartDateTimeMinutes + ':' + tempStartDateTimeSeconds;
                        startDateForEvent = tempStartDateTime;
                    }
                    let tempTask = {
                        title: subtasks[i].title,
                        description: subtasks[i].description,
                        startTime: startDateForEvent,
                        endTime: tempEndTime,
                        allDay: allDay,
                        backgroundColor: 'white',
                        textColor: 'black',
                        borderColor: subtasks[i].color,
                    };
                    events.push(tempTask);
                }
            } else {
                if (allDay) {
                    let startDateForEvent = date;
                    if (subtasks[i].startDate.length > 1) {
                        let tempStartDateTime = subtasks[i].startDate.split('-');
                        //set it up for Date object
                        startDateForEvent =
                            tempStartDateTime[1] + '/' + tempStartDateTime[2] + '/' + tempStartDateTime[0];
                    }
                    let tempTask = {
                        title: subtasks[i].title,
                        description: subtasks[i].description,
                        start: new Date(startDateForEvent),
                        end: new Date(date),
                        allDay: allDay,
                        backgroundColor: 'white',
                        textColor: 'black',
                        borderColor: subtasks[i].color,
                    };
                    events.push(tempTask);
                } else {
                    let startDateForEvent = endTime;
                    if (subtasks[i].startDate.length > 1) {
                        startDateForEvent = startTime;
                    }
                    let tempTask = {
                        title: subtasks[i].title,
                        description: subtasks[i].description,
                        start: startDateForEvent,
                        end: endTime,
                        allDay: allDay,
                        backgroundColor: 'white',
                        textColor: 'black',
                        borderColor: subtasks[i].color,
                    };
                    events.push(tempTask);
                }
            }
        }
    }

    const handleDateClick = (arg) => {
        alert(arg.dateStr);
    };

    return (
        <div style={{ color: 'white' }}>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={events}
                eventDisplay="block"
                //have a event popper
                moreLinkClick="popover"
                displayEventEnd={true}
                //on date click, open the popovers
                //change the background color of the calendar
                eventBackgroundColor="red"
                dateClick={handleDateClick}
                style={{ backgroundColor: 'red' }}
                eventColor="red"
                //selected date color
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short',
                }}
                eventTextColor="black"
            />
        </div>
    );
}

import * as React from 'react';
import { categoryChoices } from '../../../Logic/CategoryHandler';
import { createTheme, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CreateCategoryModal } from './CreateCategoryModal';
import { DeleteCategoryModal } from './DeleteCategoryModal';
import { listChoices } from '../../../Logic/ListHandler';

const theme = createTheme({
    //remove the default border
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                },
            },
        },
    },
});

export function TaskEditCategoryModal({ setCategory, categoryName, setCategoryChanged }) {
    const [currentFrame, setFrame] = React.useState(1);
    let counter = currentFrame;
    const refreshCategories = () => {
        counter++;
        setFrame(counter);
    };
    let categoryOptions = [];

    //find index of "None" category
    let index = categoryChoices.findIndex((item) => item.option === 'None');
    const [selectedCategory, setSelectedCategory] = React.useState(categoryChoices[index]);
    const [createCategory, setCreateCategory] = React.useState(false);
    const [deleteCategory, setDeleteCategory] = React.useState(false);
    for (let i = 0; i < categoryChoices.length; i++) {
        categoryOptions.push(categoryChoices[i]);
    }
    categoryOptions.push({ option: 'Create New Category', color: 'White' });
    categoryOptions.push({ option: 'Delete Category', color: 'White' });

    const handleCategory = (category) => {
        let index;
        if (category.target.value === 'Create New Category') {
            index = categoryChoices.length;
            setCreateCategory(true);
            setDeleteCategory(false);
        } else if (category.target.value === 'Delete Category') {
            index = categoryChoices.length + 1;
            setDeleteCategory(true);
            setCreateCategory(false);
        } else {
            //find index of category to change
            index = categoryChoices.findIndex((item) => item.option === category.target.value);
            setCategoryChanged(true);
            setCategory(categoryChoices[index]);
            setDeleteCategory(false);
            setCreateCategory(false);
        }
        setSelectedCategory(categoryOptions[index]);
    };
    return (
        <div current_frame={currentFrame}>
            <h2 style={{ textAlign: 'center', fontWeight: 500, marginBottom: 0, marginTop: 1, color: '#A6a6a6' }}>
                Category
            </h2>
            <Box sx={{ minWidth: 120, marginTop: 2 }}>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }}
                    >
                        Category
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCategory.option}
                        defaultValue={categoryName}
                        label="Age"
                        onChange={handleCategory}
                        sx={{
                            marginBottom: 2,
                            width: 250,
                            border: '1px solid #262626',

                            color: 'white',
                            //turn off focus border
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: ' #F2F2F2',
                            },
                        }}
                    >
                        {categoryOptions.map((item) => {
                            return (
                                <MenuItem
                                    key={item.option}
                                    value={item.option}
                                    style={{
                                        backgroundColor: item.color,
                                    }}
                                >
                                    {item.option}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
            {createCategory == true && <CreateCategoryModal fetchCategories={refreshCategories} />}
            {deleteCategory == true && <DeleteCategoryModal fetchCategories={refreshCategories} />}
        </div>
    );
}

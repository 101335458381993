import * as React from 'react';

import { createTheme, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { DeleteListModal } from './DeleteListModal';
import { listChoices } from '../../../Logic/ListHandler';
import { CreateListModal } from './CreateListModal';
import { collabLists } from '../../../Logic/collabListLogic/collabListLogic';
import { getMembership } from '../../../Logic/AccountHandler';

const theme = createTheme({
    //remove the default border
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                },
            },
        },
    },
});

export function EditTaskListModal({ setList, list, setListChanged, setListId }) {
    const [currentFrame, setFrame] = React.useState(1);
    let counter = currentFrame;
    const refreshListOptions = () => {
        counter++;
        setFrame(counter);
    };
    let listOptions = [];
    let membership = getMembership();
    let premiumMember = membership == 'premium' ? true : false;
    let index = listChoices.findIndex((item) => item === 'All');
    const [selectedList, setSelectedList] = React.useState(listChoices[0].option);
    const [selectedListId, setSelectedListId] = React.useState('');
    const [createList, setCreateList] = React.useState(false);
    const [deleteList, setDeleteList] = React.useState(false);
    for (let i = 0; i < listChoices.length; i++) {
        listOptions.push(listChoices[i].option);
    }
    for (let i = 0; i < collabLists.length; i++) {
        listOptions.push(collabLists[i].option + '👤');
    }

    listOptions.push('Create New List');
    listOptions.push('Delete List');

    function handleList(list, listIndex) {
        let index;
        if (list.target.value === 'Create New List') {
            index = listChoices.length;
            setCreateList(true);
            setDeleteList(false);
        } else if (list.target.value === 'Delete List') {
            index = listChoices.length + 1;
            setDeleteList(true);
            setCreateList(false);
        } else {
            if (listIndex >= listChoices.length) {
                index = collabLists.findIndex(
                    (item) => item.option === list.target.value.substring(0, list.target.value.length - 2)
                );

                setSelectedListId(collabLists[index].list_id);
                setListId(collabLists[index].list_id);
                setList(collabLists[index].option);
                setListChanged(true);
            } else {
                index = listChoices.findIndex((item) => item.option === list.target.value);

                setList(listChoices[index].option);
                setListChanged(true);
                setDeleteList(false);
                setCreateList(false);
            }
        }
        setSelectedList(listOptions[listIndex]);
    }
    return (
        <div current_frame={currentFrame}>
            <h2 style={{ textAlign: 'center', fontWeight: 500, marginBottom: 0, marginTop: 1, color: '#A6a6a6' }}>
                List
            </h2>
            <Box sx={{ minWidth: 120, marginTop: 2 }}>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }}
                    >
                        List
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={list}
                        value={selectedList}
                        label="Age"
                        onChange={(list, child) => {
                            handleList(list, child.props.name);
                        }}
                        sx={{
                            marginBottom: 2,
                            width: 250,
                            border: '1px solid #262626',

                            color: 'white',
                            //turn off focus border
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: ' #F2F2F2',
                            },
                        }}
                    >
                        {listOptions.map((item, index) => {
                            return (
                                <MenuItem key={index} name={index} value={item}>
                                    {item}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
            {createList == true && <CreateListModal fetchLists={refreshListOptions} />}
            {deleteList == true && <DeleteListModal fetchLists={refreshListOptions} />}
        </div>
    );
}

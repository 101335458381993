import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import dayjs from 'dayjs';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled, alpha } from '@mui/material/styles';
import parse from 'autosuggest-highlight/parse';
import { ArrowDownward, ArrowRight, ArrowRightAltOutlined } from '@mui/icons-material';
const GOOGLE_MAPS_api_KEY = 'AIzaSyC6ssF8ezAFmmjMvEb-AG_AqTYX7lzjBQA'; // Replace with your api key

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

async function getLocationTime(date, travelType, startLocationPlaceId, endLocationPlaceId) {
    let response = await fetch('https://api.priorityze.app/getDuration', {
        method: 'POST',
        cors: 'no-cors',
        credentials: 'include',

        body: JSON.stringify({
            startLocationPlaceId: startLocationPlaceId,
            endLocationPlaceId: endLocationPlaceId,
            arrivalTime: date,
            traveMode: travelType,
        }),
    });
    const body = await response.json();
    return body;
}

export function TaskLocationModal({
    setStartLocation,
    setEndLocation,
    setStartPlaceId,
    setEndPlaceId,
    setLocationHours,
    setLocationMinutes,
    originalStartLocation,
    originalEndLocation,
    originalLocationMinutes,
    originalLocationHours,
    userSelectedStartDate,
    startDate,
    userSelectedTime,
    dueDate,
    allday,
}) {
    const loaded = React.useRef(false);

    const [travelType, setTravelType] = React.useState('driving');
    //start location
    const [startLocation, setStartLocationState] = React.useState(
        originalStartLocation.length > 0 ? originalStartLocation : null
    );
    const [startOptions, setStartOptions] = React.useState([]);
    const [startInputValue, setStartInputValue] = React.useState('');
    const [startLocationPlaceId, setStartLocationPlaceId] = React.useState(''); // [0]

    //end location
    const [endLocation, setEndLocationState] = React.useState(
        originalEndLocation.length > 0 ? originalEndLocation : null
    );
    const [endOptions, setEndOptions] = React.useState([]);
    const [endInputValue, setEndInputValue] = React.useState('');
    const [endLocationPlaceId, setEndLocationPlaceId] = React.useState(''); // [0]

    const [hours, setHours] = React.useState(originalLocationHours);
    const [minutes, setMinutes] = React.useState(originalLocationMinutes);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_api_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps'
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        []
    );

    React.useEffect(() => {
        let active = true;
        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (startInputValue === '') {
            setStartOptions(startLocation ? [startLocation] : []);
            return undefined;
        }
        fetch({ input: startInputValue }, (results) => {
            if (active) {
                let newOptions = [];
                if (startLocation) {
                    newOptions = [startLocation];
                }
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setStartOptions(newOptions);
            }
        });
        return () => {
            active = false;
        };
    }, [startLocation, startInputValue, fetch]);

    React.useEffect(() => {
        let active = true;
        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (endInputValue === '') {
            setEndOptions(endLocation ? [endLocation] : []);
            return undefined;
        }
        fetch({ input: endInputValue }, (results) => {
            if (active) {
                let newOptions = [];
                if (endLocation) {
                    newOptions = [endLocation];
                }
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setEndOptions(newOptions);
            }
        });
        return () => {
            active = false;
        };
    }, [endLocation, endInputValue, fetch]);

    React.useEffect(() => {
        async function getTime() {
            let response = await getLocationTime(dateForFetch, travelType, startLocationPlaceId, endLocationPlaceId);
            if (response) {
                if (response.status == 'success') {
                    setHours(response.durationHour);
                    setMinutes(response.durationMinute);
                    setLocationHours(response.durationHour);
                    setLocationMinutes(response.durationMinute);
                }
            }
        }
        let dateForFetch = null;
        if (userSelectedTime && allday == false) {
            if (userSelectedStartDate) {
                dateForFetch = new Date(dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss'));
            } else {
                dateForFetch = new Date(dayjs(dueDate).format('YYYY-MM-DDTHH:mm:ss'));
            }
        }

        if (startLocationPlaceId && endLocationPlaceId) {
            if (startLocationPlaceId.length > 0 && endLocationPlaceId.length > 0) {
                getTime();
            }
        }
    }, [startLocationPlaceId, endLocationPlaceId]);

    return (
        <div>
            <h2 style={{ textAlign: 'center', fontWeight: 500, marginBottom: 0, marginTop: 1, color: '#A6a6a6' }}>
                Location
            </h2>
            <Box
                sx={{
                    minWidth: 120,
                    marginTop: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    display: 'flex',
                }}
            >
                <Autocomplete
                    id="start-location"
                    sx={{ width: '50%' }}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    filterOptions={(x) => x}
                    options={startOptions}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={startLocation}
                    noOptionsText="No locations"
                    onChange={(event, newValue) => {
                        setStartOptions(newValue ? [newValue, ...startOptions] : startOptions);
                        setStartLocationState(newValue);
                        setStartPlaceId(newValue.place_id);
                        setStartLocationPlaceId(newValue.place_id);
                        setStartLocation(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                        setStartInputValue(newInputValue);
                    }}
                    renderInput={(params) => <StyledTextField {...params} label="Start location" fullWidth />}
                    renderOption={(props, option) => {
                        const matches = option.structured_formatting.main_text_matched_substrings || [];
                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [match.offset, match.offset + match.length])
                        );
                        return (
                            <li {...props}>
                                <Grid container alignItems="center">
                                    <Grid item sx={{ display: 'flex', width: 44 }}>
                                        <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                    </Grid>
                                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                        {parts.map((part, index) => (
                                            <Box
                                                key={index}
                                                component="span"
                                                sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                            >
                                                {part.text}
                                            </Box>
                                        ))}
                                        <Typography variant="body2" color="text.secondary">
                                            {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ArrowRightAltOutlined
                        sx={{ color: '#A6a6a6', marginLeft: 2, textAlign: 'center', marginRight: 2 }}
                    />
                </div>
                <Autocomplete
                    id="end-location"
                    sx={{ width: '50%' }}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    filterOptions={(x) => x}
                    options={endOptions}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={endLocation}
                    noOptionsText="No locations"
                    onChange={(event, newValue) => {
                        setEndOptions(newValue ? [newValue, ...endOptions] : endOptions);
                        setEndLocationState(newValue);
                        setEndPlaceId(newValue.place_id);
                        setEndLocationPlaceId(newValue.place_id);
                        setEndLocation(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                        setEndInputValue(newInputValue);
                    }}
                    renderInput={(params) => <StyledTextField {...params} label="End location" fullWidth />}
                    renderOption={(props, option) => {
                        const matches = option.structured_formatting.main_text_matched_substrings || [];
                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [match.offset, match.offset + match.length])
                        );
                        return (
                            <li {...props}>
                                <Grid container alignItems="center">
                                    <Grid item sx={{ display: 'flex', width: 44 }}>
                                        <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                    </Grid>
                                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                        {parts.map((part, index) => (
                                            <Box
                                                key={index}
                                                component="span"
                                                sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                            >
                                                {part.text}
                                            </Box>
                                        ))}
                                        <Typography variant="body2" color="text.secondary">
                                            {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                />
            </Box>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                {
                    //radio group for travel type: driving, walking, biking, transit
                    <RadioGroup
                        row
                        aria-label="travel type"
                        name="row-radio-buttons-group"
                        defaultValue={travelType}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        onChange={(event) => {
                            setTravelType(event.target.value);
                        }}
                    >
                        <FormControlLabel
                            value="driving"
                            control={<Radio sx={{ color: 'white' }} />}
                            label="Driving"
                            sx={{ color: travelType == 'driving' ? 'white' : '#A6a6a6' }}
                        />
                        <FormControlLabel
                            value="walking"
                            control={<Radio sx={{ color: 'white' }} />}
                            label="Walking"
                            sx={{ color: travelType == 'walking' ? 'white' : '#A6a6a6' }}
                        />
                        <FormControlLabel
                            value="bicycling"
                            control={<Radio sx={{ color: 'white' }} />}
                            label="Bicycling"
                            sx={{ color: travelType == 'bicycling' ? 'white' : '#A6a6a6' }}
                        />
                        <FormControlLabel
                            value="transit"
                            control={<Radio sx={{ color: 'white' }} />}
                            label="Transit"
                            sx={{ color: travelType == 'transit' ? 'white' : '#A6a6a6' }}
                        />
                    </RadioGroup>
                }
            </Box>
            <Box>
                <Box
                    sx={{
                        minWidth: 120,
                        marginTop: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                    }}
                >
                    <h3>
                        {hours} : {minutes}
                    </h3>
                    <StyledTextField
                        id="time"
                        type="number"
                        defaultValue={hours}
                        placeholder={hours != '0' ? hours : ''}
                        value={hours}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={(event) => {
                            setLocationHours(event.target.value);
                            setHours(event.target.value);
                        }}
                        sx={{ width: '20%' }}
                    />
                    <h3>
                        <span style={{ color: '#A6a6a6' }}>h</span>
                    </h3>
                    <h3>
                        <span style={{ color: '#A6a6a6' }}>:</span>
                    </h3>
                    <StyledTextField
                        id="time"
                        type="number"
                        defaultValue={minutes}
                        placeholder={minutes != '0' ? minutes : ''}
                        value={minutes}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={(event) => {
                            setLocationMinutes(event.target.value);
                            setMinutes(event.target.value);
                        }}
                        sx={{ width: '20%' }}
                    />
                    <h3>
                        <span style={{ color: '#A6a6a6' }}>m</span>
                    </h3>
                </Box>
            </Box>
        </div>
    );
}

const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});

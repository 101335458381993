import React from 'react';
import { Button, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { BrowserRouter as Routes, Navigate, Redirect, Route, Router, useNavigate } from 'react-router-dom';
import { CheckBox, LinkOff, PersonAdd, PersonRemove, PlaylistRemove } from '@mui/icons-material';
import { collabLists, sendCollabInvite } from '../Logic/collabListLogic/collabListLogic';
import { deleteCollabList } from '../Logic/collabListLogic/deleteCollabList';
import { removeUserFromList } from '../Logic/collabListLogic/removeUserFromList';
import { removeSelfFromList } from '../Logic/collabListLogic/removeSelfFromList';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled, alpha } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { StarBorder, StarBorderOutlined } from '@mui/icons-material';
export default function CollabListComponent({ list }) {
    const [attendeeEmail, setAttendeeEmail] = React.useState('');
    //handle adding an attendee to the calendar
    const addAttendee = (listId, email, permission) => {
        if (attendeeEmail != '') {
            sendCollabInvite(listId, email, permission);
            setAttendeeEmail('');
        }
    };

    const [removeAttendeeEmail, setRemoveAttendeeEmail] = React.useState('');
    //handle removing an attendee from the calendar
    const removeAttendee = (listId, email) => {
        if (removeAttendeeEmail != '') {
            removeUserFromList(listId, email);
            setRemoveAttendeeEmail('');
        }
    };

    //handle removing a list
    const removeList = (listId) => {
        deleteCollabList(listId);
    };

    const removeSelf = (listId) => {
        removeSelfFromList(listId);
    };

    //handle removing self from a list

    const [inviteAttendeeShow, setInviteAttendeeShow] = React.useState(false);
    const [removeAttendeeShow, setRemoveAttendeeShow] = React.useState(false);
    const [permission, setPermission] = React.useState('edit');

    return (
        <div
            style={{
                backgroundColor: 'black',
                flex: 1,
                maxWidth: window.innerWidth < 600 ? '95vw' : '100%',
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
                sx={{
                    width: '96%',
                    padding: 0.5,
                    marginBottom: 2,
                    backgroundColor: '#ffffff',
                    borderRadius: 2,
                    marginLeft: '2%',
                    marginRight: '2%',
                    borderStyle: 'solid',
                }}
            >
                <Box
                    ml={1}
                    alignItems="center"
                    style={{
                        marginBottom: 0,
                        //keep all items in the box
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        //make the text wrap around
                        textWrap: 'wrap',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                        overflowWrap: 'break-word',
                        hyphens: 'auto',
                        maxWidth: '95%',
                    }}
                    flex={10}
                >
                    <Typography
                        variant="h6"
                        style={{
                            marginBottom: 0,
                            marginLeft: 0,
                            textAlign: 'left',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            maxWidth: '95%',
                        }}
                    >
                        {list.option}
                    </Typography>
                </Box>
                <Box ml="auto" alignItems="center" display="flex">
                    <PlaylistRemove
                        onClick={() => removeSelf(list.list_id)}
                        sx={{ '&:hover': { color: '#86abd4', backgroundColor: '#86abd4', borderRadius: 1 } }}
                    />
                </Box>
            </Box>
        </div>
    );
}

const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});
const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#262626',
        '&:hover': {
            backgroundColor: alpha('#262626', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#e32f45',
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#B3B3B3',
    },
}));

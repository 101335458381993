import React from 'react';

import { Box, Card, Flexbox, CardContent, Checkbox, Grid, Rating, Typography } from '@mui/material';
import {
    Add,
    Delete,
    Edit,
    KeyboardArrowDown,
    KeyboardArrowUp,
    LocalOffer,
    Article,
    VisibilityOff,
    Visibility,
    LocationOnOutlined,
} from '@mui/icons-material';
import { DisplaySubtaskList, readSubtaskFromDB } from '../Logic/SubtaskHandler';
import { deleteTask, taskChecked, taskHidden } from '../Logic/MainTaskHandler';
import LabelIcon from '@mui/icons-material/Label';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FlagIcon from '@mui/icons-material/Flag';

export default function TaskComponent({
    item,
    index,
    toggleSubtaskDrawer,
    subtaskList,
    handleEditTaskDrawer,
    setEditTaskObject,
    handleEditSubtaskDrawer,
    setEditSubtaskObject,
}) {
    let toggleSubtaskModal;

    const checked = item.taskChecked == 0 ? false : true;

    const [expanded, setExpanded] = React.useState(false);

    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    //filter subtaskList where subtaskList.taskID == item.taskID
    subtaskList = subtaskList.filter((subtask) => subtask.id == item.taskID);
    let repeatedDayString = '';
    if (item.taskDaysRepeated.length > 0) {
        if (!item.taskDaysRepeated.includes('Every')) {
            repeatedDayString = ' every ' + item.taskDaysRepeated;
        } else {
            repeatedDayString = item.taskDaysRepeated;
        }
    }
    let stringForDate = '';
    if (item.taskDueDate.length > 0) {
        if (!item.taskDaysRepeated.includes('Daily')) {
            if (item.taskStartDate.length > 0) {
                if (item.taskStartDate == item.taskDueDate) {
                    if (item.taskDeadlineTime.length > 0) {
                        stringForDate =
                            item.taskDueDate +
                            repeatedDayString +
                            ' ' +
                            item.taskStartTime +
                            ' - ' +
                            item.taskDeadlineTime;
                    } else {
                        stringForDate = item.taskStartDate + ' - ' + item.taskDueDate + repeatedDayString;
                    }
                } else {
                    if (item.taskDeadlineTime.length > 0) {
                        stringForDate =
                            item.taskStartDate +
                            ' - ' +
                            item.taskDueDate +
                            repeatedDayString +
                            ' ' +
                            item.taskStartTime +
                            ' - ' +
                            item.taskDeadlineTime;
                    } else {
                        stringForDate = item.taskStartDate + ' - ' + item.taskDueDate + repeatedDayString;
                    }
                }
            } else {
                if (item.taskDeadlineTime.length > 0) {
                    stringForDate = item.taskDueDate + repeatedDayString + ' ' + item.taskDeadlineTime;
                } else {
                    stringForDate = item.taskDueDate + repeatedDayString;
                }
            }
        }
        if (item.taskDaysRepeated.includes('Daily')) {
            if (item.taskDeadlineTime.length > 0) {
                if (item.taskStartTime.length > 0) {
                    stringForDate = 'Daily' + ' ' + item.taskStartTime + ' - ' + item.taskDeadlineTime;
                } else {
                    stringForDate = 'Daily' + ' ' + item.taskDeadlineTime;
                }
            } else {
                stringForDate = 'Daily';
            }
        }
    }
    let stringForLocation = null;
    if (item.startLocation) {
        if (item.startLocation.length > 0 && item.endLocation.length > 0) {
            if (item.locationHours.length > 0 || item.locationMinutes.length > 0) {
                stringForLocation =
                    item.startLocation +
                    '... ' +
                    item.locationHours +
                    'h' +
                    item.locationMinutes +
                    'm' +
                    ' ...' +
                    item.endLocation;
            } else {
                stringForLocation = item.startLocation + ' - ' + item.endLocation;
            }
        }
    }
    return (
        <div
            style={{
                backgroundColor: 'black',
                flex: 1,
                //make maxwidth 100vw so that it doesn't overflow
                //hide the overflow

                maxWidth: window.innerWidth < 600 ? '95vw' : '100%',
                //if screen is smaller than 600px, make the width 100%
            }}
        >
            <Box
                className="TaskComponent"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
                sx={{
                    width: '96%',
                    padding: 0.5,
                    marginBottom: 2,
                    backgroundColor: item.taskChecked == 0 ? '#ffffff' : '#dddddd',
                    borderRadius: 2,
                    opacity: item.taskChecked == 0 ? 1 : 0.5,
                    marginLeft: '2%',
                    marginRight: '2%',
                    borderColor: item.taskColor,
                    borderStyle: 'solid',
                }}
                onClick={() => {}}
            >
                <Box alignItems="center">
                    <Checkbox
                        checked={checked}
                        onChange={(event) => {
                            //update the taskChecked in the database

                            taskChecked(item.taskID, event.target.checked);
                        }}
                    />
                </Box>
                <Box
                    ml={1}
                    alignItems="center"
                    style={{
                        marginBottom: 0,
                        //keep all items in the box
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        //make the text wrap around
                        textWrap: 'wrap',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                        overflowWrap: 'break-word',
                        hyphens: 'auto',
                        maxWidth: '95%',
                    }}
                    flex={10}
                >
                    <Typography
                        variant="h6"
                        style={{
                            marginBottom: 0,
                            marginLeft: 0,
                            textAlign: 'left',
                            textDecorationLine: item.taskChecked == 1 ? 'line-through' : 'none',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            maxWidth: '95%',
                        }}
                    >
                        {item.taskTitle}
                    </Typography>
                    {item.taskDueDate.length > 0 && (
                        <Typography
                            variant="overline"
                            component="p"
                            style={{
                                marginBottom: 0,
                                marginTop: 0,
                                textAlign: 'left',

                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                overflowWrap: 'break-word',
                                hyphens: 'auto',
                                maxWidth: '95%',
                                color: '#5f6674',
                            }}
                        >
                            {stringForDate}
                        </Typography>
                    )}
                    {item.taskDescription.length > 0 ? (
                        <Typography
                            variant="body2"
                            component="p"
                            style={{
                                marginBottom: 0,
                                marginTop: 0,
                                textAlign: 'left',

                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                overflowWrap: 'break-word',
                                hyphens: 'auto',
                                maxWidth: '95%',
                                color: '#5f6674',
                            }}
                        >
                            {item.taskDescription}
                        </Typography>
                    ) : (
                        ''
                    )}
                    <Box mt={1} style={{ marginTop: 0 }}>
                        <Box display="flex" alignItems="center" justifyContent="left">
                            <Box display="flex" style={{ marginLeft: 0, marginRight: '5%' }}>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    style={{ marginBottom: 0, marginTop: 0, color: '#5f6674' }}
                                >
                                    {item.taskRating}
                                </Typography>
                                <StarBorderIcon
                                    fontSize="small"
                                    color="#5f6674"
                                    //make it the same size as the text
                                />
                            </Box>
                            {item.taskCategory != 'None' && (
                                <Box display="flex" style={{ marginLeft: 0, marginRight: '2%' }}>
                                    <LocalOffer fontSize="small" sx={{ color: '#5f6674' }} />
                                    <Typography
                                        variant="body2"
                                        component="p"
                                        style={{ marginBottom: 0, marginTop: 0, color: '#5f6674' }}
                                    >
                                        {item.taskCategory}
                                    </Typography>
                                </Box>
                            )}
                            {item.status != 'None' && (
                                <Box display="flex" style={{ marginLeft: 0, marginRight: '2%' }}>
                                    <FlagIcon fontSize="small" sx={{ color: '#5f6674 ' }} />
                                    <Typography
                                        variant="body2"
                                        component="p"
                                        style={{ marginBottom: 0, marginTop: 0, color: '#5f6674' }}
                                    >
                                        {item.status}
                                    </Typography>
                                </Box>
                            )}
                            {item.taskList != 'All' && (
                                <Box display="flex" style={{ marginLeft: 0 }}>
                                    <Article fontSize="small" sx={{ color: '#5f6674 ' }} />
                                    <Typography
                                        variant="body2"
                                        component="p"
                                        style={{ marginBottom: 0, marginTop: 0, color: '#5f6674' }}
                                    >
                                        {item.taskList}
                                    </Typography>
                                </Box>
                            )}
                            {stringForLocation != null && (
                                <Box display="flex" style={{ marginLeft: 0 }}>
                                    <LocationOnOutlined fontSize="small" sx={{ color: '#5f6674 ' }} />
                                    <Typography
                                        variant="body2"
                                        component="p"
                                        style={{ marginBottom: 0, marginTop: 0, color: '#5f6674' }}
                                    >
                                        {stringForLocation}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box
                    ml="auto"
                    alignItems="center"
                    display="flex"
                    //add a hover effect to the icons and ease the transition
                >
                    {item.hidden == 0 && (
                        <Visibility
                            onClick={() => {
                                taskHidden(item.taskID, true);
                            }}
                            sx={{
                                color: '#86abd4',
                                '&:hover': {
                                    color: '#000000',

                                    borderRadius: 1,
                                },
                            }}
                        />
                    )}
                    {item.hidden == 1 && (
                        <VisibilityOff
                            onClick={() => {
                                taskHidden(item.taskID, false);
                            }}
                            sx={{
                                color: '#86abd4',
                                '&:hover': {
                                    color: '#000000',

                                    borderRadius: 1,
                                },
                            }}
                        />
                    )}
                    <Box mx={1} />
                    <Add
                        onClick={() => {
                            toggleSubtaskDrawer(item.taskID);
                        }}
                        sx={{
                            color: '#86abd4',
                            '&:hover': {
                                color: '#000000',

                                borderRadius: 1,
                            },
                        }}
                    />
                    <Box mx={1} />

                    {!expanded && (
                        <KeyboardArrowDown
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            sx={{
                                color: '#86abd4',
                                '&:hover': {
                                    color: '#000000',

                                    borderRadius: 1,
                                },
                            }}
                        />
                    )}
                    {expanded && (
                        <KeyboardArrowUp
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            sx={{
                                color: '#86abd4',
                                '&:hover': {
                                    color: '#000000',

                                    borderRadius: 1,
                                },
                            }}
                        />
                    )}
                    <Box mx={1} />
                    <Edit
                        onClick={() => {
                            setEditTaskObject(item);
                            handleEditTaskDrawer();
                        }}
                        sx={{
                            color: '#86abd4',
                            '&:hover': {
                                color: '#000000',

                                borderRadius: 1,
                            },
                        }}
                    />
                    <Box mx={1} />

                    <Delete
                        onClick={async () => {
                            await deleteTask(item.taskID);
                        }}
                        sx={{
                            color: '#86abd4',
                            '&:hover': {
                                color: '#000000',

                                borderRadius: 1,
                            },
                        }}
                    />

                    <Box mx={1} />
                </Box>
            </Box>
            {expanded && (
                <DisplaySubtaskList
                    subtasksFromParentComponent={subtaskList}
                    taskID={item.taskID}
                    handleEditSubtaskDrawer={handleEditSubtaskDrawer}
                    setEditSubtaskObject={setEditSubtaskObject}
                />
            )}
        </div>
    );
}

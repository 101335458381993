import React from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import syncImage from '../Image/syncImage.png';
import aiImage from '../Image/aiImage.png';
import startImage from '../Image/startImage.png';
import { Card, CardContent } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import '@fontsource/montserrat';
import '@fontsource/montserrat/700.css';
export default function StartScreen() {
    console.log(window.innerWidth);
    return (
        <div
            style={{
                backgroundColor: '#000000',
                color: '#FFFFFF',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 20,
            }}
        >
            <div>
                <Typography
                    variant="h1"
                    align="center"
                    sx={{
                        color: '#FFFFFF',
                        fontWeight: 700,
                        fontSize: '7vh',
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontDisplay: 'swap',
                        letterSpacing: '-0.015em',
                    }}
                >
                    Priorityze
                </Typography>
                <Typography
                    variant="body1"
                    align="center"
                    sx={{
                        color: '#FFFFFF',
                        fontWeight: 700,
                        fontSize: '2vh',
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontDisplay: 'swap',
                        letterSpacing: '-0.015em',
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                >
                    A powerful task manager for everyone. Create simple tasks and subtasks with just a title. Or go all
                    out and add a description, due date, start date, category, list, importance rating, & more! Create
                    unlimited tasks, subtasks, lists, and categories for free!
                </Typography>
            </div>
            <div
                style={{
                    marginTop: 2,
                    marginBottom: 4,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div style={{ flex: '100%' }}>
                    <img
                        src={startImage}
                        alt="App Screenshot"
                        style={{
                            width: '100%',
                            height: 'auto',
                            //cut off pixels from top of the image
                        }}
                    />
                </div>
            </div>

            <div
                style={{
                    marginTop: 4,
                    marginBottom: 4,
                    display: 'flex',
                    background: 'linear-gradient(to bottom, #ffffff, #f5f5f5)',
                    flexDirection: window.innerWidth > 600 ? 'row' : 'column-reverse',
                }}
            >
                <div
                    style={{
                        flex: '40%',
                        //im
                    }}
                >
                    <img src={aiImage} alt="App Screenshot" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div style={{ flex: '60%', padding: '2%' }}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 700,
                            fontSize: '3vh',
                            fontFamily: 'Montserrat',
                            alignItems: 'top',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                            color: '#000000',
                        }}
                    >
                        AI Task Creation
                    </Typography>
                    <div
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#FFFFFF',
                                fontWeight: 700,
                                fontSize: '2.5vh',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontDisplay: 'swap',
                                letterSpacing: '-0.015em',
                                color: '#000000',
                            }}
                        >
                            Too busy to add complex tasks?
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#FFFFFF',
                                fontWeight: 700,
                                fontSize: '2.5vh',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontDisplay: 'swap',
                                letterSpacing: '-0.015em',
                                color: '#000000',
                            }}
                        >
                            Let Priorityze's AI do it for you!
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#FFFFFF',
                                fontWeight: 700,
                                fontSize: '2.5vh',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontDisplay: 'swap',
                                letterSpacing: '-0.015em',
                                color: '#000000',
                            }}
                        >
                            Type in a prompt into Priorityze and it will generate your detailed task for you. Our
                            Open-AI GPT-3.5 model allows us to break down your tasks into deadlines, notifications,
                            categories, ratings, and lists!
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#FFFFFF',
                                fontWeight: 700,
                                fontSize: '2.5vh',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontDisplay: 'swap',
                                letterSpacing: '-0.015em',
                                color: '#000000',
                            }}
                        >
                            Upgrade to Priorityze Premium to access this feature!
                        </Typography>
                    </div>
                </div>
            </div>
            <div
                style={{
                    marginTop: 4,
                    marginBottom: 4,
                    display: 'flex',

                    flexDirection: window.innerWidth > 600 ? 'row' : 'column-reverse',
                }}
            >
                <div style={{ flex: '60%', padding: ' 2%' }}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 700,
                            fontSize: '3vh',
                            fontFamily: 'Montserrat',
                            alignItems: 'top',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                            color: '#ffffff',
                        }}
                    >
                        Sync Across All Devices
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 700,
                            fontSize: '2.5vh',
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                        }}
                    >
                        Upgrade to Priorityze Premium to sync everything from tasks & subtasks to categories & lists
                        across all your devices.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#FFFFFF',
                            fontWeight: 700,
                            fontSize: '2.5vh',
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            letterSpacing: '-0.015em',
                        }}
                    >
                        Priorityze Premium is only $4.99/month!
                    </Typography>
                </div>
                <div
                    style={{
                        flex: '40%',
                        //im
                    }}
                >
                    <img src={syncImage} alt="App Screenshot" style={{ width: '100%', height: 'auto' }} />
                </div>
            </div>
            <div
                style={{
                    width: '100%',
                    marginTop: 4,
                    marginBottom: 4,
                    display: 'flex',
                    alignItems: 'center',
                    background: 'linear-gradient(to bottom, #ffffff, #f5f5f5)',
                    flexDirection: window.innerWidth > 600 ? 'row' : 'column-reverse',
                    justifyContent: 'center',
                }}
            >
                <Card
                    style={{
                        backgroundColor: '#000',
                        color: '#fff',
                        padding: '1rem',
                        borderRadius: '10px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                        borderColor: '#fff',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        marginRight: window.innerWidth > 600 ? '1rem' : '0rem',
                    }}
                >
                    <CardContent>
                        <Typography variant="h5" component="div" sx={{ marginBottom: '1rem', color: 'white' }}>
                            Free Plan
                        </Typography>
                        <Typography variant="body2" color="white">
                            Enjoy these Priorityze features for free!
                        </Typography>
                        <ul style={{ listStyleType: 'none', paddingLeft: '1rem', marginTop: '0.5rem' }}>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2rem',
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ marginRight: '0.5rem', color: 'green', verticalAlign: 'middle' }}>
                                    <CheckCircleOutlineIcon fontSize="small" />
                                </span>
                                <span style={{ verticalAlign: 'middle' }}>Unlimited Tasks with all features</span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2rem',
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ marginRight: '0.5rem', color: 'green', verticalAlign: 'middle' }}>
                                    <CheckCircleOutlineIcon fontSize="small" />
                                </span>
                                <span style={{ verticalAlign: 'middle' }}>Unlimited Subtasks with all features</span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2rem',
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ marginRight: '0.5rem', color: 'green', verticalAlign: 'middle' }}>
                                    <CheckCircleOutlineIcon fontSize="small" />
                                </span>
                                <span style={{ verticalAlign: 'middle' }}>Create and delete unlimited Categories</span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2rem',
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ marginRight: '0.5rem', color: 'green', verticalAlign: 'middle' }}>
                                    <CheckCircleOutlineIcon fontSize="small" />
                                </span>
                                <span style={{ verticalAlign: 'middle' }}>Create and delete unlimited Lists</span>
                            </li>
                        </ul>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                marginTop: '1rem',
                                background: 'linear-gradient(to right, #ffffff, #f1f1f1)',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
                                transition: 'transform 0.3s ease-in-out',
                                color: '#000000',
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.transform = 'scale(1.05)';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.transform = 'scale(1)';
                            }}
                        >
                            Sign In
                        </Button>
                    </CardContent>
                </Card>
                <Card
                    style={{
                        backgroundColor: '#000',
                        color: '#fff',
                        padding: '1rem',
                        borderRadius: '10px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                        borderColor: '#fff',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        marginRight: window.innerWidth > 600 ? '1rem' : '0rem',
                    }}
                >
                    <CardContent>
                        <Typography variant="h5" component="div" sx={{ marginBottom: '1rem', color: 'white' }}>
                            Premium Plan
                        </Typography>
                        <Typography variant="body2" color="white">
                            Enjoy these Priorityze features for just $4.99/month!
                        </Typography>
                        <ul style={{ listStyleType: 'none', paddingLeft: '1rem', marginTop: '0.5rem' }}>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2rem',
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ marginRight: '0.5rem', color: 'green', verticalAlign: 'middle' }}>
                                    <CheckCircleOutlineIcon fontSize="small" />
                                </span>
                                <span style={{ verticalAlign: 'middle' }}>
                                    Create complex tasks in seconds using OpenAI's GPT-3.5 model
                                </span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2rem',
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ marginRight: '0.5rem', color: 'green', verticalAlign: 'middle' }}>
                                    <CheckCircleOutlineIcon fontSize="small" />
                                </span>
                                <span style={{ verticalAlign: 'middle' }}>
                                    Sync all your tasks, subtasks, categories, and lists across unlimited devices
                                </span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2rem',
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ marginRight: '0.5rem', color: 'green', verticalAlign: 'middle' }}>
                                    <CheckCircleOutlineIcon fontSize="small" />
                                </span>
                                <span style={{ verticalAlign: 'middle' }}>Unlimited Tasks with all features</span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2rem',
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ marginRight: '0.5rem', color: 'green', verticalAlign: 'middle' }}>
                                    <CheckCircleOutlineIcon fontSize="small" />
                                </span>
                                <span style={{ verticalAlign: 'middle' }}>Unlimited Subtasks with all features</span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2rem',
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ marginRight: '0.5rem', color: 'green', verticalAlign: 'middle' }}>
                                    <CheckCircleOutlineIcon fontSize="small" />
                                </span>
                                <span style={{ verticalAlign: 'middle' }}>Create and delete unlimited Categories</span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.2rem',
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ marginRight: '0.5rem', color: 'green', verticalAlign: 'middle' }}>
                                    <CheckCircleOutlineIcon fontSize="small" />
                                </span>
                                <span style={{ verticalAlign: 'middle' }}>Create and delete unlimited Lists</span>
                            </li>
                        </ul>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                marginTop: '1rem',
                                background: 'linear-gradient(to right, #ffffff, #f1f1f1)',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
                                transition: 'transform 0.3s ease-in-out',
                                color: '#000000',
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.transform = 'scale(1.05)';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.transform = 'scale(1)';
                            }}
                        >
                            Sign in and Upgrade
                        </Button>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

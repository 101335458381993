import React from 'react';

import { Box, Card, Flexbox, CardContent, Checkbox, Grid, Rating, Typography } from '@mui/material';
import {
    Add,
    Delete,
    Edit,
    KeyboardArrowDown,
    KeyboardArrowUp,
    LocalOffer,
    Article,
    Visibility,
    VisibilityOff,
    LocationOnOutlined,
} from '@mui/icons-material';
import {
    deleteSubtask,
    DisplaySubtaskList,
    readSubtaskFromDB,
    subtaskChecked,
    subtaskHidden,
} from '../Logic/SubtaskHandler';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FlagIcon from '@mui/icons-material/Flag';
export default function SubtaskComponent({ item, index, handleEditSubtaskDrawer, setEditSubtaskObject }) {
    let toggleSubtaskModal;
    const checked = item.checked == 0 ? false : true;
    let repeatedDayString = '';
    if (item.days_repeated.length > 0) {
        repeatedDayString = ' every ' + item.days_repeated;
    }
    let stringForDate = '';
    if (item.duedate.length > 0) {
        if (!item.days_repeated.includes('Daily')) {
            if (item.startDate.length > 0) {
                if (item.startDate == item.duedate) {
                    if (item.deadline_time.length > 0) {
                        stringForDate =
                            item.duedate + repeatedDayString + ' ' + item.startTime + ' - ' + item.deadline_time;
                    } else {
                        stringForDate = item.startDate + ' - ' + item.duedate + repeatedDayString;
                    }
                } else {
                    if (item.deadline_time.length > 0) {
                        stringForDate =
                            item.startDate +
                            ' - ' +
                            item.duedate +
                            repeatedDayString +
                            ' ' +
                            item.startTime +
                            ' - ' +
                            item.deadline_time;
                    } else {
                        stringForDate = item.startDate + ' - ' + item.duedate + repeatedDayString;
                    }
                }
            } else {
                if (item.deadline_time.length > 0) {
                    stringForDate = item.duedate + repeatedDayString + ' ' + item.deadline_time;
                } else {
                    stringForDate = item.duedate + repeatedDayString;
                }
            }
        }
        if (item.days_repeated.includes('Daily')) {
            if (item.deadline_time.length > 0) {
                if (item.startTime.length > 0) {
                    stringForDate = 'Daily' + ' ' + item.startTime + ' - ' + item.deadline_time;
                } else {
                    stringForDate = 'Daily' + ' ' + item.deadline_time;
                }
            } else {
                stringForDate = 'Daily';
            }
        }
    }
    let stringForLocation = null;
    if (item.startLocation) {
        if (item.startLocation.length > 0 && item.endLocation.length > 0) {
            if (item.locationHours.length > 0 || item.locationMinutes.length > 0) {
                stringForLocation =
                    item.startLocation +
                    '... ' +
                    item.locationHours +
                    'h' +
                    item.locationMinutes +
                    'm' +
                    ' ...' +
                    item.endLocation;
            } else {
                stringForLocation = item.startLocation + ' - ' + item.endLocation;
            }
        }
    }
    return (
        <Box
            className="TaskComponent"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                width: '94%',
                padding: 0.5,
                marginBottom: 2,
                backgroundColor: item.checked == 0 ? '#ffffff' : '#dddddd',
                borderRadius: 2,
                opacity: item.checked == 0 ? 1 : 0.5,
                borderColor: item.color,
                borderStyle: 'solid',
                marginLeft: '4%',
                marginRight: '2%',
            }}
            flex={1}
            onClick={() => {}}
        >
            <Box alignItems="center">
                <Checkbox
                    checked={checked}
                    onChange={(event) => {
                        subtaskChecked(item.id, item.subtaskID, event.target.checked);
                    }}
                />
            </Box>
            <Box
                ml={1}
                alignItems="center"
                style={{
                    marginBottom: 0,
                    //keep all items in the box
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    //make the text wrap around
                    textWrap: 'wrap',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    overflowWrap: 'break-word',
                    hyphens: 'auto',
                    maxWidth: '95%',
                }}
                flex={10}
            >
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: 0,
                        marginLeft: 0,
                        textAlign: 'left',
                        textDecorationLine: item.taskChecked == 1 ? 'line-through' : 'none',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                        overflowWrap: 'break-word',
                        hyphens: 'auto',
                        maxWidth: '95%',
                    }}
                >
                    {item.title}
                </Typography>
                {item.duedate.length > 0 && (
                    <Typography
                        variant="overline"
                        component="p"
                        style={{
                            marginBottom: 0,
                            marginTop: 0,
                            textAlign: 'left',

                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            maxWidth: '95%',
                            color: '#5f6674',
                        }}
                    >
                        {stringForDate}
                    </Typography>
                )}
                {item.description.length > 0 ? (
                    <Typography
                        variant="body2"
                        component="p"
                        style={{
                            marginBottom: 0,
                            marginTop: 0,
                            textAlign: 'left',

                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            maxWidth: '95%',
                            color: '#5f6674',
                        }}
                    >
                        {item.description}
                    </Typography>
                ) : (
                    ''
                )}
                <Box mt={1} style={{ marginTop: 0 }}>
                    <Box display="flex" alignItems="center" justifyContent="left">
                        <Box display="flex" style={{ marginLeft: 0, marginRight: '2%' }}>
                            <Typography
                                variant="body1"
                                component="p"
                                style={{ marginBottom: 0, marginTop: 0, color: '#5f6674' }}
                            >
                                {item.rating}
                            </Typography>
                            <StarBorderIcon
                                fontSize="small"
                                sx={{
                                    color: '#5f6674',
                                }}
                                //make it the same size as the text
                            />
                        </Box>
                        {item.category != 'None' && (
                            <Box display="flex" style={{ marginLeft: 0, marginRight: '2%' }}>
                                <LocalOffer fontSize="small" sx={{ color: '#5f6674' }} />
                                <Typography
                                    variant="body2"
                                    component="p"
                                    style={{ marginBottom: 0, marginTop: 0, color: '#5f6674' }}
                                >
                                    {item.category}
                                </Typography>
                            </Box>
                        )}
                        {item.status != 'None' && (
                            <Box display="flex" style={{ marginLeft: 0, marginRight: '2%' }}>
                                <FlagIcon fontSize="small" sx={{ color: '#5f6674' }} />
                                <Typography
                                    variant="body2"
                                    component="p"
                                    style={{ marginBottom: 0, marginTop: 0, color: '#5f6674' }}
                                >
                                    {item.status}
                                </Typography>
                            </Box>
                        )}
                        {stringForLocation != null && (
                            <Box display="flex" style={{ marginLeft: 0 }}>
                                <LocationOnOutlined fontSize="small" sx={{ color: '#5f6674' }} />
                                <Typography
                                    variant="body2"
                                    component="p"
                                    style={{ marginBottom: 0, marginTop: 0, color: '#5f6674' }}
                                >
                                    {stringForLocation}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box ml="auto" alignItems="center" display="flex">
                {item.hidden == 0 && (
                    <Visibility
                        onClick={async () => {
                            subtaskHidden(item.id, item.subtaskID, true);
                        }}
                        sx={{
                            color: '#86abd4',
                            '&:hover': {
                                color: '#000000',

                                borderRadius: 1,
                            },
                        }}
                    />
                )}
                {item.hidden == 1 && (
                    <VisibilityOff
                        onClick={async () => {
                            subtaskHidden(item.id, item.subtaskID, false);
                        }}
                        sx={{
                            color: '#86abd4',
                            '&:hover': {
                                color: '#000000',

                                borderRadius: 1,
                            },
                        }}
                    />
                )}
                <Box mx={1} />
                <Edit
                    onClick={() => {
                        setEditSubtaskObject(item);
                        handleEditSubtaskDrawer(true);
                    }}
                    sx={{
                        color: '#86abd4',
                        '&:hover': {
                            color: '#000000',

                            borderRadius: 1,
                        },
                    }}
                />
                <Box mx={1} />
                <Delete
                    onClick={async () => {
                        deleteSubtask(item.id, item.subtaskID);
                    }}
                    sx={{
                        color: '#86abd4',
                        '&:hover': {
                            color: '#000000',

                            borderRadius: 1,
                        },
                    }}
                />
                <Box mx={1} />
            </Box>
        </Box>
    );
}

import * as React from 'react';

import { createTheme, Select, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled, alpha } from '@mui/material/styles';
import { StarBorder, StarBorderOutlined } from '@mui/icons-material';

export function TaskStatusModal({ setStatus, originalStatus }) {
    const [status, setChildStatus] = React.useState(originalStatus);
    let statusOptions = [
        'None',
        'Completed',
        'In Progress',
        'Not Started',
        'Overdue',
        'On Track',
        'Waiting',
        'Running Late',
        'Blocked',
        'Reviewed',
    ];

    return (
        <div>
            <h2 style={{ textAlign: 'center', fontWeight: 500, marginBottom: 0, marginTop: 1, color: '#A6a6a6' }}>
                Status
            </h2>
            <Box sx={{ minWidth: 120, marginTop: 2 }}>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }}
                    >
                        Status
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="Status"
                        onChange={(e) => {
                            setStatus(e.target.value);
                            setChildStatus(e.target.value);
                        }}
                        sx={{
                            marginBottom: 2,
                            width: 250,
                            border: '1px solid #262626',

                            color: 'white',
                            //turn off focus border
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: ' #F2F2F2',
                            },
                        }}
                    >
                        {statusOptions.map((item) => {
                            return (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <StyledTextField
                    id="outlined-multiline-static"
                    label="Custom Status"
                    multiline
                    placeholder="Enter a custom status"
                    fullWidth={true}
                    sx={{ marginBottom: 2, width: '80%', borderColor: 'red', justifyContent: 'center' }}
                    variant="outlined"
                    onChange={(e) => {
                        setStatus(e.target.value);
                        setChildStatus(e.target.value);
                    }}
                />
            </div>
        </div>
    );
}

const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});

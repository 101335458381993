import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import TaskComponent from '../Components/TaskComponent';
import { getMembership } from './AccountHandler';
import { CalendarView } from './CalendarView';
import { addHistoryTask } from './HistoryTaskHandler';
import { deleteSubtask, DisplaySubtaskList, localReadSubtasks, readSubtaskFromDB, subtaskList } from './SubtaskHandler';
import { ButtonBase } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { collabLists } from './collabListLogic/collabListLogic';

export let todoList = [];
export let subtaskListForDisplay = [];

export async function localStoreTasks() {
    localStorage.setItem('todoList', JSON.stringify(todoList));
}
export async function localReadTasks() {
    const raw = localStorage.getItem('todoList');
    if (raw) todoList = JSON.parse(raw);
    //if status is null make it none
    for (let i = 0; i < todoList.length; i++) {
        todoList[i].status = todoList[i].status || 'None';
    }

    return todoList;
}

function generateString(length) {
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export async function addTaskAI(title) {
    let membership = getMembership();
    let id = generateString(10);
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/addTaskAI', {
            method: 'POST',
            body: JSON.stringify({
                prompt: title,
                id: id,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }),
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success == 'success') {
            todoList.push(data.task);
        }
    }
    organizeList();
    window.refreshListScreen();
}

export async function addToList(
    title,
    description,
    list,
    rating,
    category,
    date,
    startDate,
    userSelectedDate,
    userSelectedStartDate,
    allDay,
    deadlineDaysRepeating,
    duration,
    getReminderOptions,
    reminderTime,
    repetition,
    reminderDate,
    dayforReminder,
    timeForReminder,
    status,
    startLocation,
    endLocation,
    startPlaceId,
    endPlaceId,
    locationHours,
    locationMinutes,
    listId
) {
    if (title) {
        //CreatesID
        let id = generateString(10);
        //create A format for the date and time in format mm/dd/yyyy hh:mm
        let formattedString = '';
        let timeFormatted = '';
        let deadlineDaysRepeatingFormatted = '';
        //check if userSelctedDate exists
        if (userSelectedDate) {
            if (allDay) {
                formattedString = dayjs(date).format('MM/DD/YYYY');
                //remove useless 0s
                formattedString = formattedString.replace('/0', '/');
                if (formattedString.charAt(0) == '0') {
                    formattedString = formattedString.substring(1);
                }
            } else {
                //format time in 12 hour format
                formattedString = dayjs(date).format('MM/DD/YYYY');
                //remove useless 0s
                formattedString = formattedString.replace('/0', '/');
                if (formattedString.charAt(0) == '0') {
                    formattedString = formattedString.substring(1);
                }
                timeFormatted = dayjs(date).format('hh:mm A');
                //remove useless 0s

                timeFormatted = timeFormatted.replace('AM', 'am');

                //Compute notifications
            }
            if (deadlineDaysRepeatingFormatted.includes('Daily')) {
                deadlineDaysRepeatingFormatted = 'Daily';
            }
            //Days Repeating
            for (let i = 0; i < deadlineDaysRepeating.length; i++) {
                deadlineDaysRepeatingFormatted += deadlineDaysRepeating[i];
                if (i < deadlineDaysRepeating.length - 1) {
                    deadlineDaysRepeatingFormatted += ', ';
                }
            }
        }

        let startDateFormattedString = '';
        let startTimeFormattedString = '';
        if (userSelectedStartDate) {
            if (allDay) {
                startDateFormattedString = dayjs(startDate).format('MM/DD/YYYY');
                //remove useless 0s
                startDateFormattedString = startDateFormattedString.replace('/0', '/');
                if (startDateFormattedString.charAt(0) == '0') {
                    startDateFormattedString = startDateFormattedString.substring(1);
                }
            } else {
                //format time in 12 hour format
                startDateFormattedString = dayjs(startDate).format('MM/DD/YYYY');
                //remove useless 0s
                startDateFormattedString = startDateFormattedString.replace('/0', '/');
                if (startDateFormattedString.charAt(0) == '0') {
                    startDateFormattedString = startDateFormattedString.substring(1);
                }
                startTimeFormattedString = dayjs(startDate).format('hh:mm A');
                //remove useless 0s
                startTimeFormattedString = startTimeFormattedString.replace(':0', ':');
                if (startTimeFormattedString.charAt(0) == '0') {
                    startTimeFormattedString = startTimeFormattedString.substring(1);
                }
                startTimeFormattedString = startTimeFormattedString.replace('AM', 'am');
            }
        }

        if (!category) {
            category.option = 'None';
            category.color = '#ffffff';
        }
        let membership = getMembership();
        if (membership == 'free') {
            todoList.push({
                taskID: id,
                taskTitle: title,
                taskDescription: description,
                taskCategory: category.option,
                taskColor: category.color,
                taskList: list,
                taskRating: rating,
                taskDuration: duration,
                taskDueDate: formattedString,
                taskDaysRepeated: deadlineDaysRepeatingFormatted.toLowerCase(),
                taskDeadlineTime: timeFormatted.toLowerCase(),
                taskChecked: false,
                taskStartDate: startDateFormattedString,
                taskStartTime: startTimeFormattedString.toLowerCase(),
                notificationIdentifier: '',
                calendarIdentifier: '',
                hidden: false,
                reminder_type: 'None',
                status: status,
                startLocation: startLocation,
                endLocation: endLocation,
                startPlaceId: startPlaceId,
                endPlaceId: endPlaceId,
                locationHours: locationHours,
                locationMinutes: locationMinutes,
            });
            await localStoreTasks();
        }

        if (membership == 'premium') {
            if (listId) {
                let collabList = collabLists.find((item) => item.list_id == listId);
                let collabPermission = collabList.permission;
                if (collabPermission != 'edit') {
                    //sennd a pop up saying you dont have permission to edit this list
                    alert('You do not have permission to edit this list');
                    return;
                }
            }

            //setup reminderDate to be in new Date format
            let reminderDateFormatted = new Date(reminderDate);
            let reminderTimeFormatted = new Date(timeForReminder);
            const response = await fetch('https://api.priorityze.app/addTask', {
                method: 'POST',
                body: JSON.stringify({
                    title: title,
                    id: id,
                    description: description,
                    list: list,
                    rating: rating,
                    category: category.option,
                    color: category.color,
                    reminder_time: null,
                    duration: duration,
                    duedate: formattedString,
                    days_repeated: deadlineDaysRepeatingFormatted,
                    deadline_time: timeFormatted.toLowerCase(),
                    startDate: startDateFormattedString,
                    startTime: startTimeFormattedString.toLowerCase(),
                    notificationIdentifier: '',
                    calendarIdentifier: '',
                    reminderOptions: getReminderOptions,
                    reminderTime: reminderTime,
                    reminderRepeat: repetition,
                    reminderDate: reminderDateFormatted,
                    reminderDay: dayforReminder,
                    reminderTimeForDate: reminderTimeFormatted,
                    repetitionForDayReminder: true,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    status: status,
                    startLocation: startLocation,
                    endLocation: endLocation,
                    startPlaceId: startPlaceId,
                    endPlaceId: endPlaceId,
                    locationHours: locationHours,
                    locationMinutes: locationMinutes,
                    listId: listId,
                }),
                credentials: 'include',
            });
            const data = await response.json();
            if (data.success === true) {
                todoList.push({
                    taskID: id,
                    taskTitle: title,
                    taskDescription: description,
                    taskCategory: category.option,
                    taskColor: category.color,
                    taskList: list,
                    taskRating: rating,
                    taskDuration: duration,
                    taskDueDate: formattedString,
                    taskDaysRepeated: deadlineDaysRepeatingFormatted,
                    taskDeadlineTime: timeFormatted,
                    taskChecked: false,
                    taskStartDate: startDateFormattedString,
                    taskStartTime: startTimeFormattedString,
                    notificationIdentifier: '',
                    calendarIdentifier: '',
                    hidden: false,
                    reminder_type: data.task.reminder_type,
                    status: status,
                    startLocation: startLocation,
                    endLocation: endLocation,
                    startPlaceId: startPlaceId,
                    endPlaceId: endPlaceId,
                    locationHours: locationHours,
                    locationMinutes: locationMinutes,
                    listId: listId,
                });
            } else {
            }
        }
    }

    organizeList();
    window.refreshListScreen();
    //make sure DisplayTask component is updated
}

export function organizeList() {
    todoList.sort((a, b) => {
        return b.taskRating - a.taskRating;
    });
}

export async function updateTask(
    id,
    title,
    description,
    list,
    rating,
    category,
    originalDate,
    originalTime,
    originalStartDate,
    originalStartTime,
    date,
    startDate,
    userSelectedDate,
    userSelectedStartDate,
    allDay,
    deadlineDaysRepeating,
    duration,
    getReminderOptions,
    reminderTime,
    repetition,
    reminderDate,
    dayforReminder,
    timeForReminder,
    dateChanged,
    listChanged,
    categoryChanged,
    checked,
    reminder_type,
    reminderChanged,
    status,
    startLocation,
    endLocation,
    startPlaceId,
    endPlaceId,
    locationHours,
    locationMinutes,
    listId
) {
    if (title) {
        let dateToUse = originalDate;
        let formattedString = '';
        let timeFormatted = '';
        let startDateFormattedString = '';
        let startTimeFormattedString = '';
        let deadlineDaysRepeatingFormatted = '';

        if (dateChanged) {
            //check if userSelctedDate exists
            if (userSelectedDate) {
                if (allDay) {
                    formattedString = dayjs(date).format('MM/DD/YYYY');
                    //remove useless 0s
                    formattedString = formattedString.replace('/0', '/');
                    if (formattedString.charAt(0) == '0') {
                        formattedString = formattedString.substring(1);
                    }
                } else {
                    //format time in 12 hour format
                    formattedString = dayjs(date).format('MM/DD/YYYY');
                    //remove useless 0s
                    formattedString = formattedString.replace('/0', '/');
                    if (formattedString.charAt(0) == '0') {
                        formattedString = formattedString.substring(1);
                    }
                    timeFormatted = dayjs(date).format('hh:mm A');
                    //remove useless 0s

                    timeFormatted = timeFormatted.replace('AM', 'am');

                    //Compute notifications
                }
                if (deadlineDaysRepeatingFormatted.includes('Daily')) {
                    deadlineDaysRepeatingFormatted = 'Daily';
                }
                //Days Repeating
                for (let i = 0; i < deadlineDaysRepeating.length; i++) {
                    deadlineDaysRepeatingFormatted += deadlineDaysRepeating[i];
                    if (i < deadlineDaysRepeating.length - 1) {
                        deadlineDaysRepeatingFormatted += ', ';
                    }
                }
            }
            if (userSelectedStartDate) {
                if (allDay) {
                    startDateFormattedString = dayjs(startDate).format('MM/DD/YYYY');
                    //remove useless 0s
                    startDateFormattedString = startDateFormattedString.replace('/0', '/');
                    if (startDateFormattedString.charAt(0) == '0') {
                        startDateFormattedString = startDateFormattedString.substring(1);
                    }
                } else {
                    //format time in 12 hour format
                    startDateFormattedString = dayjs(startDate).format('MM/DD/YYYY');
                    //remove useless 0s
                    startDateFormattedString = startDateFormattedString.replace('/0', '/');
                    if (startDateFormattedString.charAt(0) == '0') {
                        startDateFormattedString = startDateFormattedString.substring(1);
                    }
                    startTimeFormattedString = dayjs(startDate).format('hh:mm A');
                    //remove useless 0s
                    startTimeFormattedString = startTimeFormattedString.replace(':0', ':');
                    if (startTimeFormattedString.charAt(0) == '0') {
                        startTimeFormattedString = startTimeFormattedString.substring(1);
                    }
                    startTimeFormattedString = startTimeFormattedString.replace('AM', 'am');
                }
            }
        } else {
            timeFormatted = originalTime;
            formattedString = originalDate;
            startDateFormattedString = originalStartDate;
            startTimeFormattedString = originalStartTime;
        }
        if (!category) {
            category.option = 'None';
            category.color = '#ffffff';
        }
        let membership = getMembership();
        if (membership == 'free') {
            for (let i = 0; i < todoList.length; i++) {
                if (todoList[i].taskID == id) {
                    todoList[i].taskTitle = title;
                    todoList[i].taskDescription = description;
                    todoList[i].taskCategory = category.option;
                    todoList[i].taskColor = category.color;
                    todoList[i].taskList = list;
                    todoList[i].taskRating = rating;
                    todoList[i].taskDuration = duration;
                    todoList[i].taskDueDate = formattedString.toLowerCase();
                    todoList[i].taskDaysRepeated = deadlineDaysRepeatingFormatted.toLowerCase();
                    todoList[i].taskDeadlineTime = timeFormatted;
                    todoList[i].taskChecked = checked;
                    todoList[i].taskStartDate = startDateFormattedString;
                    todoList[i].taskStartTime = startTimeFormattedString.toLowerCase();
                    todoList[i].status = status;
                    todoList[i].startLocation = startLocation;
                    todoList[i].endLocation = endLocation;
                    todoList[i].startPlaceId = startPlaceId;
                    todoList[i].endPlaceId = endPlaceId;
                    todoList[i].locationHours = locationHours;
                    todoList[i].locationMinutes = locationMinutes;
                }
            }
            await localStoreTasks();
        }
        if (membership == 'premium') {
            if (listId) {
                let collabList = collabLists.find((item) => item.list_id == listId);
                let collabPermission = collabList.permission;
                if (collabPermission != 'edit') {
                    //sennd a pop up saying you dont have permission to edit this list
                    alert('You do not have permission to edit this list');
                    return;
                }
            }

            const response = await fetch('https://api.priorityze.app/editTask', {
                method: 'POST',
                body: JSON.stringify({
                    title: title,
                    id: id,
                    description: description,
                    list: list,
                    rating: rating,
                    category: category.option,
                    color: category.color,
                    reminder_time: null,
                    duration: duration,
                    duedate: formattedString,
                    days_repeated: deadlineDaysRepeatingFormatted,
                    deadline_time: timeFormatted.toLowerCase(),
                    checked: checked,
                    startDate: startDateFormattedString,
                    startTime: startTimeFormattedString.toLowerCase(),
                    notificationIdentifier: '',
                    calendarIdentifier: '',
                    reminderOptions: getReminderOptions,
                    reminderTime: reminderTime,
                    reminderRepeat: repetition,
                    reminderDate: reminderDate,
                    reminderDay: dayforReminder,
                    reminderTimeForDate: timeForReminder,
                    repetitionForDayReminder: true,
                    reminder_type: reminder_type,
                    reminderChanged: reminderChanged,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    status: status,
                    startLocation: startLocation,
                    endLocation: endLocation,
                    startPlaceId: startPlaceId,
                    endPlaceId: endPlaceId,
                    locationHours: locationHours,
                    locationMinutes: locationMinutes,
                    listId: listId,
                }),

                credentials: 'include',
            });
            const data = await response.json();
            if (data.success === true) {
                let index = todoList.findIndex((x) => x.taskID === id);
                todoList[index].taskTitle = title;
                todoList[index].taskDescription = description;
                todoList[index].taskCategory = category.option;
                todoList[index].taskColor = category.color;
                todoList[index].taskList = list;
                todoList[index].taskRating = rating;
                todoList[index].taskDuration = duration;
                todoList[index].taskDueDate = formattedString;
                todoList[index].taskDaysRepeated = deadlineDaysRepeatingFormatted.toLowerCase();
                todoList[index].taskDeadlineTime = timeFormatted;
                todoList[index].taskChecked = checked;
                todoList[index].taskStartDate = startDateFormattedString;
                todoList[index].taskStartTime = startTimeFormattedString.toLowerCase();
                todoList[index].notificationIdentifier = '';
                todoList[index].calendarIdentifier = '';
                todoList[index].reminder_type = data.reminder_type;
                todoList[index].status = status;
                todoList[index].startLocation = startLocation;
                todoList[index].endLocation = endLocation;
                todoList[index].startPlaceId = startPlaceId;
                todoList[index].endPlaceId = endPlaceId;
                todoList[index].locationHours = locationHours;
                todoList[index].locationMinutes = locationMinutes;
                todoList[index].listId = listId;
            } else {
            }
        }
    }
    organizeList();
    window.refreshListScreen();
}

export async function addTaskFromHistoryTask(
    id,
    title,
    category,
    color,
    description,
    list,
    reminder_time,
    rating,
    duration,
    duedate,
    days_repeated,
    deadline_time,
    startDate,
    startTime,
    notificationIdentifier,
    calendarIdentifier,
    reminder_type
) {
    let membership = getMembership();
    todoList.push({
        taskID: id,
        taskTitle: title,
        taskDescription: description,
        taskCategory: category,
        taskColor: color,
        taskList: list,
        taskRating: rating,
        taskDuration: duration,
        taskDueDate: duedate,
        taskDaysRepeated: days_repeated,
        taskDeadlineTime: deadline_time,
        taskChecked: false,
        taskStartDate: startDate,
        taskStartTime: startTime,
        notificationIdentifier: notificationIdentifier,
        calendarIdentifier: calendarIdentifier,
        hidden: false,
        reminder_type: reminder_type,
        status: 'None',
    });
    if (membership == 'free') {
        await localStoreTasks();
    }
    window.refreshListScreen();
}

export async function deleteTask(id) {
    let membership = getMembership();
    //get main task id
    if (membership == 'free') {
        let temp = [...todoList];
        let temp2 = [...subtaskList];
        for (let i = 0; i < temp2.length; i++) {
            if (temp2[i].id == id) {
                await deleteSubtask(temp2[i].taskID, temp2[i].subtaskID);
            }
        }
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].taskID == id) {
                await addHistoryTask(
                    temp[i].taskID,
                    temp[i].taskTitle,
                    temp[i].taskCategory,
                    temp[i].taskColor,
                    temp[i].taskDescription,
                    temp[i].taskList,
                    null,
                    temp[i].taskRating,
                    temp[i].taskDuration,
                    temp[i].taskDueDate,
                    temp[i].taskDaysRepeated,
                    temp[i].taskDeadlineTime,
                    temp[i].taskStartDate,
                    temp[i].taskStartTime,
                    temp[i].notificationIdentifier,
                    temp[i].calendarIdentifier,
                    temp[i].reminder_type,
                    temp[i].startLocation,
                    temp[i].endLocation,
                    temp[i].startPlaceId,
                    temp[i].endPlaceId,
                    temp[i].locationHours,
                    temp[i].locationMinutes
                );
                temp.splice(i, 1);
            }
        }
        todoList = temp;

        await localStoreTasks();
    }

    if (membership == 'premium') {
        let itemIndex = todoList.findIndex((x) => x.taskID === id);
        if (todoList[itemIndex].list_id) {
            let collabList = collabLists.find((item) => item.list_id == todoList[itemIndex].list_id);
            let collabPermission = collabList.permission;
            if (collabPermission != 'edit') {
                //sennd a pop up saying you dont have permission to edit this list
                alert('You do not have permission to edit this list');
                return;
            }
        }

        let temp2 = [...subtaskList];
        for (let i = 0; i < temp2.length; i++) {
            if (temp2[i].id == id) {
                await deleteSubtask(temp2[i].id, temp2[i].subtaskID);
            }
        }
        const response = await fetch('https://api.priorityze.app/deleteTask', {
            method: 'POST',
            body: JSON.stringify({
                id: id,
            }),
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success === true) {
            let temp = [...todoList];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].taskID == id) {
                    await addHistoryTask(
                        temp[i].taskID,
                        temp[i].taskTitle,
                        temp[i].taskCategory,
                        temp[i].taskColor,
                        temp[i].taskDescription,
                        temp[i].taskList,
                        null,
                        temp[i].taskRating,
                        temp[i].taskDuration,
                        temp[i].taskDueDate,
                        temp[i].taskDaysRepeated,
                        temp[i].taskDeadlineTime,
                        temp[i].taskStartDate,
                        temp[i].taskStartTime,
                        temp[i].notificationIdentifier,
                        temp[i].calendarIdentifier,
                        temp[i].reminder_type,
                        temp[i].startLocation,
                        temp[i].endLocation,
                        temp[i].startPlaceId,
                        temp[i].endPlaceId,
                        temp[i].locationHours,
                        temp[i].locationMinutes
                    );
                    temp.splice(i, 1);
                }
            }
            todoList = temp;
        } else {
        }
    }
    organizeList();
    window.refreshListScreen();

    return;
}

export async function readTaskFromDB() {
    let membership = getMembership();
    if (membership === 'free') {
        await localReadTasks();
        organizeList();
        return todoList;
    }
    if (membership === 'premium') {
        const response = await fetch('https://api.priorityze.app/readTask', {
            method: 'GET',
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success === true) {
            todoList = data.task;
            organizeList();
            return data.task;
        } else {
        }
    } else {
        return [];
    }

    //
}

export async function getSubtask() {
    let membership = getMembership();
    if (membership === 'free') {
        subtaskListForDisplay = await localReadSubtasks();
        return subtaskListForDisplay;
    }
    if (membership === 'premium') {
        subtaskListForDisplay = await readSubtaskFromDB();
        return subtaskListForDisplay;
    }
}

export async function taskChecked(id, checked) {
    let checkedBoolToNum = 0;
    if (checked == true) {
        checkedBoolToNum = 1;
    } else {
        checkedBoolToNum = 0;
    }

    let membership = getMembership();
    if (membership == 'free') {
        let item = todoList.find((item) => item.taskID == id);
        item.taskChecked = checkedBoolToNum;
        await localStoreTasks();
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/taskChecked', {
            method: 'POST',
            body: JSON.stringify({
                id: id,
                checked: checkedBoolToNum,
            }),
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success === true) {
            let item = todoList.find((item) => item.taskID == id);
            item.taskChecked = checkedBoolToNum;
        } else {
        }
    }
    window.refreshListScreen();
}
export async function taskHidden(id, hidden) {
    let hiddenBoolToNum = 0;
    if (hidden == true) {
        hiddenBoolToNum = 1;
    } else {
        hiddenBoolToNum = 0;
    }

    let membership = getMembership();
    if (membership == 'free') {
        let item = todoList.find((item) => item.taskID == id);
        item.hidden = hiddenBoolToNum;
        await localStoreTasks();
    }

    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/hiddenTask', {
            method: 'POST',
            body: JSON.stringify({
                id: id,
                hidden: hiddenBoolToNum,
            }),
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success === true) {
            let item = todoList.find((item) => item.taskID == id);
            item.hidden = hiddenBoolToNum;
        } else {
        }
    }
    window.refreshListScreen();
}

export function DisplayTasks({
    handleSubtaskDrawer,
    handleEditTaskDrawer,
    setEditObject,
    handleEditSubtaskDrawer,
    setEditSubtaskObject,
    selectedList,
}) {
    //update every time a new task is added

    const [list, setList] = React.useState([]);
    const [subtaskListForDisplay, setSubtaskList] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [current_frame, setCurrentFrame] = React.useState(0);
    const [showHiddenTasks, setShowHiddenTasks] = React.useState(false);
    let counter = current_frame;
    window.refreshListScreen = () => {
        counter++;
        setCurrentFrame(counter);
    };

    React.useEffect(() => {
        async function fetchData() {
            setError(null);
            setLoading(true);
            try {
                setSubtaskList(subtaskList);
                setList(todoList);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [current_frame]);

    const toggleSubtaskDrawer = (id) => {
        handleSubtaskDrawer(id);
    };

    if (error) {
        return <h1>{error}</h1>;
    }

    return (
        <div current_frame={current_frame} style={{ backgroundColor: 'black', flex: 1 }}>
            {list.map((task, index) => (
                <div key={index} style={{ flex: 1 }}>
                    {selectedList == 'All' && (
                        <div>
                            {showHiddenTasks == false && (
                                <div>
                                    {task.hidden == 0 && (
                                        <TaskComponent
                                            item={task}
                                            index={index}
                                            toggleSubtaskDrawer={toggleSubtaskDrawer}
                                            subtaskList={subtaskList}
                                            handleEditTaskDrawer={handleEditTaskDrawer}
                                            setEditTaskObject={setEditObject}
                                            handleEditSubtaskDrawer={handleEditSubtaskDrawer}
                                            setEditSubtaskObject={setEditSubtaskObject}
                                        />
                                    )}
                                </div>
                            )}
                            {showHiddenTasks == true && (
                                <div>
                                    <TaskComponent
                                        item={task}
                                        index={index}
                                        toggleSubtaskDrawer={toggleSubtaskDrawer}
                                        subtaskList={subtaskList}
                                        handleEditTaskDrawer={handleEditTaskDrawer}
                                        setEditTaskObject={setEditObject}
                                        handleEditSubtaskDrawer={handleEditSubtaskDrawer}
                                        setEditSubtaskObject={setEditSubtaskObject}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {selectedList != 'All' && task.taskList == selectedList && (
                        <div>
                            {showHiddenTasks == false && (
                                <div>
                                    {task.hidden == 0 && (
                                        <TaskComponent
                                            item={task}
                                            index={index}
                                            toggleSubtaskDrawer={toggleSubtaskDrawer}
                                            subtaskList={subtaskList}
                                            handleEditTaskDrawer={handleEditTaskDrawer}
                                            setEditTaskObject={setEditObject}
                                            handleEditSubtaskDrawer={handleEditSubtaskDrawer}
                                            setEditSubtaskObject={setEditSubtaskObject}
                                        />
                                    )}
                                </div>
                            )}
                            {showHiddenTasks == true && (
                                <div>
                                    <TaskComponent
                                        item={task}
                                        index={index}
                                        toggleSubtaskDrawer={toggleSubtaskDrawer}
                                        subtaskList={subtaskList}
                                        handleEditTaskDrawer={handleEditTaskDrawer}
                                        setEditTaskObject={setEditObject}
                                        handleEditSubtaskDrawer={handleEditSubtaskDrawer}
                                        setEditSubtaskObject={setEditSubtaskObject}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ))}
            {list.some((item) => item.hidden == 1) && (
                <div>
                    {showHiddenTasks == false && (
                        <ButtonBase onClick={() => setShowHiddenTasks(true)}>
                            <VisibilityOff style={{ color: 'white' }} />
                        </ButtonBase>
                    )}
                    {showHiddenTasks == true && (
                        <ButtonBase onClick={() => setShowHiddenTasks(false)}>
                            <Visibility style={{ color: 'white' }} />
                        </ButtonBase>
                    )}
                </div>
            )}
        </div>
    );
}

export function DisplayCalendarView({
    handleSubtaskDrawer,
    handleEditTaskDrawer,
    setEditObject,
    handleEditSubtaskDrawer,
    setEditSubtaskObject,
}) {
    //update every time a new task is added

    const [list, setList] = React.useState([]);
    const [subtaskListForDisplay, setSubtaskList] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [current_frame, setCurrentFrame] = React.useState(0);

    let counter = current_frame;
    window.refreshListScreen = () => {
        counter++;
        setCurrentFrame(counter);
    };

    React.useEffect(() => {
        async function fetchData() {
            setError(null);
            setLoading(true);
            try {
                setSubtaskList(subtaskList);
                setList(todoList);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [current_frame]);

    const toggleSubtaskDrawer = (id) => {
        handleSubtaskDrawer(id);
    };

    if (error) {
        return <h1>{error}</h1>;
    }

    return (
        <div current_frame={current_frame} style={{ backgroundColor: 'black' }}>
            <CalendarView tasks={list} subtasks={subtaskList} />
        </div>
    );
}

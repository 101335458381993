import { Button, MenuItem, Select, Toolbar } from '@mui/material';
import * as React from 'react';
import { CalendarView } from '../../Logic/CalendarView';
import { DisplayHistoryTasks } from '../../Logic/HistoryTaskHandler';
import { listChoices, readList } from '../../Logic/ListHandler';
import { DisplayCalendarView, DisplayTasks } from '../../Logic/MainTaskHandler';
import ListScreen from './ListScreen';
export default function SubNavigation({
    toggleSubtaskDrawer,
    setParentID,
    toggleEditTaskDrawer,
    setEditTaskObject,
    toggleEditSubtaskDrawer,
    setEditSubtaskObject,
}) {
    const [listView, setListView] = React.useState(true);
    const [calendarView, setCalendarView] = React.useState(false);
    const [historyView, setHistoryView] = React.useState(false);

    const handleListView = () => {
        setListView(true);
        setCalendarView(false);
        setHistoryView(false);
    };
    const handleCalendarView = () => {
        setListView(false);
        setCalendarView(true);
        setHistoryView(false);
    };
    const handleHistoryView = () => {
        setListView(false);
        setCalendarView(false);
        setHistoryView(true);
    };
    const [listOptions, setListOptions] = React.useState([]);
    let index = listChoices.findIndex((item) => item.option === 'All');

    const [loadList, setLoadList] = React.useState(true);
    const [selectedList, setSelectedList] = React.useState(listChoices[index].option);

    //create a subhook function to handle the toggleSubtaskDrawer and setParentID
    const handleSubtaskDrawer = (id) => {
        setParentID(id);
        toggleSubtaskDrawer();
    };

    return loadList ? (
        <div>
            <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', backgroundColor: 'black' }}>
                <Button
                    variant="outlined"
                    //make it so when the button is on, it is filled
                    sx={{
                        flexGrow: 1,
                        backgroundColor: listView ? 'white' : 'black',
                        color: listView ? 'black' : 'white',
                        '&:hover': {
                            color: 'white',
                        },
                    }}
                    onClick={handleListView}
                >
                    List
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        flexGrow: 1,
                        backgroundColor: calendarView ? 'white' : 'black',
                        color: calendarView ? 'black' : 'white',
                        '&:hover': {
                            color: 'white',
                        },
                    }}
                    onClick={handleCalendarView}
                >
                    Calendar
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        flexGrow: 1,
                        backgroundColor: historyView ? 'white' : 'black',
                        color: historyView ? 'black' : 'white',
                        '&:hover': {
                            color: 'white',
                        },
                    }}
                    //make it so when the button is hovered and on, text is white

                    onClick={handleHistoryView}
                >
                    History
                </Button>
                {listView && (
                    <Select
                        value={selectedList}
                        onChange={(e) => setSelectedList(e.target.value)}
                        variant="outlined"
                        sx={{ backgroundColor: 'black', color: 'white', borderColor: 'white' }}
                    >
                        {listChoices.map((list, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={list.option}
                                    //if the list is selected, make it white
                                    sx={{
                                        backgroundColor: selectedList === list.option ? 'white' : 'black',
                                        color: selectedList === list.option ? 'black' : 'white',
                                        '&:hover': {
                                            color: 'black',
                                        },
                                    }}
                                >
                                    {list.option}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
            </Toolbar>
            <div style={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
                <div style={{ flex: 1, width: '100%' }}>
                    {listView && (
                        <DisplayTasks
                            handleSubtaskDrawer={handleSubtaskDrawer}
                            setEditObject={setEditTaskObject}
                            handleEditTaskDrawer={toggleEditTaskDrawer}
                            handleEditSubtaskDrawer={toggleEditSubtaskDrawer}
                            setEditSubtaskObject={setEditSubtaskObject}
                            selectedList={selectedList}
                        />
                    )}
                    {calendarView && (
                        <DisplayCalendarView
                            handleSubtaskDrawer={handleSubtaskDrawer}
                            setEditObject={setEditTaskObject}
                            handleEditTaskDrawer={toggleEditTaskDrawer}
                            handleEditSubtaskDrawer={toggleEditSubtaskDrawer}
                            setEditSubtaskObject={setEditSubtaskObject}
                        />
                    )}
                    {historyView && <DisplayHistoryTasks />}
                </div>
            </div>
        </div>
    ) : null;
}

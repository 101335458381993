import * as React from 'react';
import { Button, FormControlLabel, FormGroup, Rating, SwipeableDrawer, TextField } from '@mui/material';
import { TaskCategoryModal } from './Category/TaskCategoryModal';
import { categoryChoices, readCategory } from '../../Logic/CategoryHandler';
import { listChoices, readList } from '../../Logic/ListHandler';
import { TaskListModal } from './List/TaskListModal';
import { TaskDeadlineModal } from './Deadline/TaskDeadlineModal';
import { createTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { TaskNotificationModal } from './Notification/TaskNotificationModal';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled, alpha } from '@mui/material/styles';
import { StarBorder, StarBorderOutlined } from '@mui/icons-material';
import { addTaskAI, addToList, testingFunction } from '../../Logic/MainTaskHandler';
import { getMembership } from '../../Logic/AccountHandler';
import Switch from '@mui/material/Switch';
import { TaskStatusModal } from './StatusModal';
import { TaskLocationModal } from './Location/LocationModal';

export function MainAddTaskModal({ openAddModal, toggleDrawer }) {
    let modalWidth = '35%';
    let screenWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    if (screenWidth <= 1200) {
        modalWidth = '100%';
    }
    //setup for AI modal
    let membership = getMembership();
    let premiumMember = membership == 'premium' ? true : false;
    const [openAIModal, setOpenAIModal] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const handleAiSwitch = (event) => {
        setOpenAIModal(event.target.checked);
    };

    //Date
    const [date, setDate] = React.useState(dayjs(new Date()));
    const [startDate, setStartDate] = React.useState(dayjs(new Date()));
    const [userSelectedDate, setUserSelectedDate] = React.useState(false);
    const [userSelectedStartDate, setUserSelectedStartDate] = React.useState(false);
    const [allDayEvent, setAllDayEvent] = React.useState(false);
    const [repeatedDate, setRepeatedDate] = React.useState([]);
    const [duration, setDuration] = React.useState(30);

    //Rating
    const [rating, setRating] = React.useState(2);

    //Category

    const [category, setCategory] = React.useState(categoryChoices[0]);
    //List
    const [list, setList] = React.useState(listChoices[0].option);
    const [listId, setListId] = React.useState(''); //listId is used for collab lists

    //find t

    //Notification
    const [notificationChosen, setNotificationChosen] = React.useState(0);
    const [timeInterval, setTimeInterval] = React.useState('');
    const [notificationTime, setNotificationTime] = React.useState(dayjs(new Date()));
    const [notificationDate, setNotificationDate] = React.useState(dayjs(new Date()));
    const [notificationRepeat, setNotificationRepeat] = React.useState(false);
    const [notificationDayChosen, setNotificationDayChosen] = React.useState('');

    //location
    const [startLocation, setStartLocation] = React.useState('');
    const [endLocation, setEndLocation] = React.useState('');
    const [startPlaceId, setStartPlaceId] = React.useState('');
    const [endPlaceId, setEndPlaceId] = React.useState('');
    const [locationHours, setLocationHours] = React.useState('');
    const [locationMinutes, setLocationMinutes] = React.useState('');

    //status
    const [status, setStatus] = React.useState('None');
    return (
        <SwipeableDrawer
            anchor={'left'}
            open={openAddModal}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            sx={{
                color: 'white',
                width: 240,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: modalWidth,
                    boxSizing: 'border-box',
                    marginTop: 8,
                    overflow: 'auto',
                    height: '100%',
                    backgroundColor: 'black',
                },
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: 15,
                }}
            >
                <h1 style={{ textAlign: 'center', fontWeight: 700, color: '#A6a6a6' }}>The Task</h1>
                {premiumMember && (
                    <FormGroup sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <FormControlLabel
                            sx={{ color: 'White' }}
                            label="Enable AI"
                            control={<RedSwitch onChange={handleAiSwitch} />}
                        />
                    </FormGroup>
                )}
                <StyledTextField
                    id="outlined-textarea"
                    label={openAIModal ? 'Task' : 'Title'}
                    placeholder={openAIModal ? 'Task' : 'Add a Title'}
                    fullWidth={true}
                    sx={{ marginBottom: 2, width: '80%', borderColor: 'red' }}
                    variant="outlined"
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                    multiline
                />
                {openAIModal == false && (
                    <StyledTextField
                        id="outlined-textarea"
                        label="Description"
                        placeholder="Add a Description"
                        fullWidth={true}
                        sx={{ marginBottom: 2, width: '80%' }}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                        multiline
                    />
                )}
                {openAIModal == false && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <TaskDeadlineModal
                            setDeadline={setDate}
                            deadline={date}
                            setAllDay={setAllDayEvent}
                            allDay={allDayEvent}
                            userSelectedDate={setUserSelectedDate}
                            repeatedDays={repeatedDate}
                            setRepeatedDays={setRepeatedDate}
                            setDuration={setDuration}
                            setStartDate={setStartDate}
                            userSelectedStartDate={setUserSelectedStartDate}
                            startDate={startDate}
                        />

                        <h2
                            style={{
                                textAlign: 'center',
                                fontWeight: 500,
                                marginBottom: 0,
                                marginTop: 1,
                                color: '#A6a6a6',
                            }}
                        >
                            {' '}
                            Rating
                        </h2>
                        <Rating
                            value={rating}
                            sx={{ color: 'white' }}
                            max={4}
                            size="large"
                            onChange={(_event, newValue) => {
                                setRating(newValue);
                            }}
                            emptyIcon={<StarBorderOutlined fontSize="inherit" style={{ color: 'white' }} />}
                        />
                        <TaskCategoryModal setCategory={setCategory} category={category} />
                        <TaskListModal setList={setList} list={list} setListId={setListId} />

                        <TaskNotificationModal
                            notificationChosen={notificationChosen}
                            setNotificationChosen={setNotificationChosen}
                            setTimeInterval={setTimeInterval}
                            setNotificationTime={setNotificationTime}
                            setNotificationDate={setNotificationDate}
                            setNotificationRepeat={setNotificationRepeat}
                            setNotificationDayChosen={setNotificationDayChosen}
                            notificationDate={notificationDate}
                            notificationDayChosen={notificationDayChosen}
                            notificationTime={notificationTime}
                        />
                        <TaskStatusModal setStatus={setStatus} originalStatus={status} />
                        <TaskLocationModal
                            setStartLocation={setStartLocation}
                            setEndLocation={setEndLocation}
                            setStartPlaceId={setStartPlaceId}
                            setEndPlaceId={setEndPlaceId}
                            setLocationHours={setLocationHours}
                            setLocationMinutes={setLocationMinutes}
                            originalStartLocation={startLocation}
                            originalEndLocation={endLocation}
                            originalStartPlaceId={startPlaceId}
                            originalEndPlaceId={endPlaceId}
                            originalLocationHours={locationHours}
                            originalLocationMinutes={locationMinutes}
                            userSelectedStartDate={userSelectedStartDate}
                            userSelectedTime={userSelectedDate}
                            startDate={startDate}
                            dueDate={date}
                            allday={allDayEvent}
                        />
                    </div>
                )}
                <Button
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        width: '80%',
                        backgroundColor: '#A6a6a6',
                        color: 'black',
                        fontWeight: 700,
                        '&:hover': {
                            backgroundColor: '#A6a6a6',
                            color: 'black',
                        },
                    }}
                    onClick={() => {
                        if (openAIModal == true) {
                            addTaskAI(title);
                        } else {
                            addToList(
                                title,
                                description,
                                list,
                                rating,
                                category,
                                date,
                                startDate,
                                userSelectedDate,
                                userSelectedStartDate,
                                allDayEvent,
                                repeatedDate,
                                duration,
                                notificationChosen,
                                timeInterval,
                                notificationRepeat,
                                notificationDate,
                                notificationDayChosen,
                                notificationTime,
                                status,
                                startLocation,
                                endLocation,
                                startPlaceId,
                                endPlaceId,
                                locationHours,
                                locationMinutes,
                                listId.length > 0 ? listId : null
                            );
                        }
                        //reset all the values
                        setTitle('');
                        setDescription('');
                        setList(listChoices[0].option);
                        setRating(2);
                        setCategory(categoryChoices[0]);
                        setDate(dayjs(new Date()));
                        setStartDate(dayjs(new Date()));
                        setAllDayEvent(false);
                        setUserSelectedDate(false);
                        setUserSelectedStartDate(false);
                        setRepeatedDate([]);
                        setDuration(30);
                        setNotificationChosen(0);
                        setTimeInterval('');
                        setNotificationTime(dayjs(new Date()));
                        setNotificationDate(dayjs(new Date()));
                        setNotificationRepeat(false);
                        setNotificationDayChosen('');
                        setStatus('None');
                        setStartLocation('');
                        setEndLocation('');
                        setStartPlaceId('');
                        setEndPlaceId('');
                        setLocationHours('');
                        setLocationMinutes('');
                        setListId('');

                        toggleDrawer();
                    }}
                >
                    Add Task
                </Button>
            </div>

            <div style={{ minHeight: '15%' }}> </div>
        </SwipeableDrawer>
    );
}
const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});
const RedSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#262626',
        '&:hover': {
            backgroundColor: alpha('#262626', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#e32f45',
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#B3B3B3',
    },
}));

import React from 'react';
import { cancelMembership, getEmail, getMembership, goToCheckOut, signOut } from '../Logic/AccountHandler';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { BrowserRouter as Routes, Navigate, Redirect, Route, Router, useNavigate } from 'react-router-dom';

export default function PurchaseSuccess() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column',
            }}
        >
            <Typography variant="h4" style={{ color: 'white' }}>
                Thank you for your purchase!
            </Typography>
            <Typography variant="h6" style={{ color: 'white' }}>
                Your account is being updated in a couple of moments.
            </Typography>
            <Typography variant="h6" style={{ color: 'white' }}>
                You will be required to sign back in to all your devices.
            </Typography>
            <Typography variant="h6" style={{ color: 'white' }}>
                If you have any issues, please contact us at alloysolutionsus@gmail.com{' '}
            </Typography>
            <Typography variant="h6" style={{ color: 'white' }}>
                Thank you for using Priorityze!
            </Typography>
        </div>
    );
}

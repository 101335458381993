import '../App.css';
import logo from '../logo.svg';

import * as React from 'react';
import Header from '../Components/Header Components/Header';
import { Button } from '@mui/material';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { MainAddTaskModal } from '../Components/AddTaskModals/MainAddTaskModal';
import { readList } from '../Logic/ListHandler';
import { readCategory } from '../Logic/CategoryHandler';
import SubNavigation from '../Components/SubNavigation/SubNavigation';
import { localReadTasks, readTaskFromDB } from '../Logic/MainTaskHandler';
import { SubtaskAddTaskModal } from '../Components/AddTaskModals/SubtaskAddTaskModal';
import { EditTaskModal } from '../Components/AddTaskModals/EditTaskModal';
import { EditSubtaskTaskModal } from '../Components/AddTaskModals/EditSubtaskModal';
import { readHistorySubtaskFromDB, readHistoryTaskFromDB } from '../Logic/HistoryTaskHandler';
import { storeCalendarEmails, storeEmail, storeMembership, syncCalendar } from '../Logic/AccountHandler';
import { readSubtaskFromDB } from '../Logic/SubtaskHandler';
import AppleLogin from 'react-apple-login';
import StartScreen from './StartScreen';
import { readCollabInvites, readCollabLists } from '../Logic/collabListLogic/collabListLogic';
export default function Home() {
    let Component;
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const toggleDrawer = () => {
        setOpenAddModal(!openAddModal);
    };

    const [openSubtaskModal, setOpenSubtaskModal] = React.useState(false);
    const toggleSubtaskDrawer = () => {
        setOpenSubtaskModal(!openSubtaskModal);
    };

    const [openEditModal, setOpenEditModal] = React.useState(false);

    const [openEditSubtaskModal, setOpenEditSubtaskModal] = React.useState(false);

    const [isLoaded, setIsLoaded] = React.useState(true);

    const navigate = useNavigate();
    const handleLoggedIn = (data) => {
        navigate('/?dashboard');
    };

    React.useEffect(() => {
        fetch('https://api.priorityze.app/checkLogin', {
            method: 'GET',

            credentials: 'include',
        })
            .then((response) => {
                return response.json();
            })
            .then(async (body) => {
                if (body.success == true) {
                    handleLoggedIn(body.success);
                    await storeMembership().then(async () => {
                        await readList();
                        await readCategory();
                        await readTaskFromDB();
                        await readHistoryTaskFromDB();
                        await readHistorySubtaskFromDB();
                        await readSubtaskFromDB();
                        await storeEmail();
                        //await storeCalendarEmails();
                        await readCollabLists();
                        await readCollabInvites();
                        //await syncCalendar();
                    });
                }
            })
            .then(() => {
                setIsLoaded(true);
            });
    }, []);

    const [parentID, setParentID] = React.useState('');

    //setup for edit task

    const [editTaskObject, setEditTaskObject] = React.useState({});
    const [editSubtaskObject, setEditSubtaskObject] = React.useState({});
    const toggleEditDrawer = () => {
        //wait until editTaskObject is set
        if (editTaskObject.taskID) {
            setOpenEditModal(!openEditModal);
        }
    };
    const toggleEditSubtaskDrawer = () => {
        //wait until editTaskObject is set
        if (editSubtaskObject.subtaskID != '') {
            setOpenEditSubtaskModal(!openEditSubtaskModal);
        }
    };
    return (
        <div className="App">
            <Header title="Priorityze" toggleDrawer={toggleDrawer} />
            {isLoaded && //link includes the dashboard
                window.location.href.includes('?dashboard') && (
                    <div>
                        <div>
                            <MainAddTaskModal toggleDrawer={toggleDrawer} openAddModal={openAddModal} />
                            <SubNavigation
                                toggleSubtaskDrawer={toggleSubtaskDrawer}
                                setParentID={setParentID}
                                setEditTaskObject={setEditTaskObject}
                                toggleEditTaskDrawer={toggleEditDrawer}
                                toggleEditSubtaskDrawer={toggleEditSubtaskDrawer}
                                setEditSubtaskObject={setEditSubtaskObject}
                            />
                            <SubtaskAddTaskModal
                                toggleDrawer={toggleSubtaskDrawer}
                                openAddModal={openSubtaskModal}
                                parentID={parentID}
                            />
                            {editTaskObject.taskID && (
                                <EditTaskModal
                                    toggleDrawer={toggleEditDrawer}
                                    openAddModal={openEditModal}
                                    item={editTaskObject}
                                    setItem={setEditTaskObject}
                                />
                            )}
                            {editSubtaskObject.subtaskID && (
                                <EditSubtaskTaskModal
                                    toggleDrawer={toggleEditSubtaskDrawer}
                                    openAddModal={openEditSubtaskModal}
                                    item={editSubtaskObject}
                                    setItem={setEditSubtaskObject}
                                />
                            )}
                        </div>
                    </div>
                )}
            {isLoaded && //link doesn't include dashboard
                !window.location.href.includes('?dashboard') && <StartScreen />}
        </div>
    );
}

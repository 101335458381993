import * as React from 'react';
import { Button, Rating, SwipeableDrawer, TextField } from '@mui/material';
import { TaskCategoryModal } from './Category/TaskCategoryModal';
import { categoryChoices, readCategory } from '../../Logic/CategoryHandler';

import dayjs from 'dayjs';
import { TaskNotificationModal } from './Notification/TaskNotificationModal';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { StarBorder, StarBorderOutlined } from '@mui/icons-material';
import { addToList, testingFunction } from '../../Logic/MainTaskHandler';
import { TaskEditCategoryModal } from './Category/TaskEditCategoryModal';

import { EditTaskDeadlineModal } from './Deadline/EditTaskDeadlineModal';
import { updateSubtask } from '../../Logic/SubtaskHandler';
import { EditTaskNotificationModal } from './Notification/EdiTaskNotificationModal';
import { TaskStatusModal } from './StatusModal';
import { TaskLocationModal } from './Location/LocationModal';

export function EditSubtaskTaskModal({ openAddModal, toggleDrawer, item, setItem }) {
    let modalWidth = '35%';
    let screenWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    if (screenWidth <= 1200) {
        modalWidth = '100%';
    }
    //Title and Description
    const [title, setTitle] = React.useState(item.title);
    const [description, setDescription] = React.useState(item.description);

    //Date
    //create a new date object with 'MM/DD/YYYY' format
    let defaultDate;
    let defaultStartDate;
    let startDateSelected = false;
    let defaultAllDayEvent = false;
    if (item.duedate) {
        if (item.deadline_time.length > 0) {
            //create date with MM/DD/YYYY and hh:mm A format
            defaultDate = dayjs(item.duedate + ' ' + item.deadline_time, 'MM/DD/YYYY hh:mm A');
            defaultAllDayEvent = false;
        } else {
            //create date with MM/DD/YYYY format
            defaultDate = dayjs(item.duedate, 'MM/DD/YYYY');
            defaultAllDayEvent = true;
        }
    }
    let defaultRepeatedDate = [];
    //item.days_repeated is in format of a string with each day separated by a comma
    if (item.days_repeated.length > 0) {
        defaultRepeatedDate = item.days_repeated.split(',');
    }

    if (item.startDate) {
        startDateSelected = true;
        if (item.startTime.length > 0) {
            defaultStartDate = dayjs(item.startDate + ' ' + item.startTime, 'MM/DD/YYYY hh:mm A');
        } else {
            defaultStartDate = dayjs(item.startDate, 'MM/DD/YYYY');
        }
    }

    //

    //make that date the default date
    const [date, setDate] = React.useState(dayjs(defaultDate));
    const [startDate, setStartDate] = React.useState(dayjs(defaultStartDate));
    const [userSelectedStartDate, setUserSelectedStartDate] = React.useState(startDateSelected);
    const [userSelectedDate, setUserSelectedDate] = React.useState(false);
    const [allDayEvent, setAllDayEvent] = React.useState(defaultAllDayEvent);
    const [repeatedDate, setRepeatedDate] = React.useState(defaultRepeatedDate);
    const [duration, setDuration] = React.useState(item.duration);
    const [dateChanged, setDateChanged] = React.useState(false);

    //Rating
    const [rating, setRating] = React.useState(item.rating);
    const [ratingChanged, setRatingChanged] = React.useState(false);

    //Category

    const [category, setCategory] = React.useState({
        option: item.category,
        color: item.color,
    });
    const [categoryName, setCategoryName] = React.useState(item.category === 'None' ? '' : item.category);
    const [categoryChanged, setCategoryChanged] = React.useState(false);

    //Notification
    const [notificationChosen, setNotificationChosen] = React.useState(0);
    const [timeInterval, setTimeInterval] = React.useState('');
    const [notificationTime, setNotificationTime] = React.useState(dayjs(new Date()));
    const [notificationDate, setNotificationDate] = React.useState(dayjs(new Date()));
    const [notificationRepeat, setNotificationRepeat] = React.useState(false);
    const [notificationDayChosen, setNotificationDayChosen] = React.useState('');
    const [notificationChanged, setNotificationChanged] = React.useState(false);

    //Location
    const [startLocation, setStartLocation] = React.useState(item.startLocation ? item.startLocation : '');
    const [endLocation, setEndLocation] = React.useState(item.endLocation ? item.endLocation : '');
    const [startPlaceId, setStartPlaceId] = React.useState(item.startPlaceId ? item.startPlaceId : '');
    const [endPlaceId, setEndPlaceId] = React.useState(item.endPlaceId ? item.endPlaceId : '');
    const [locationHours, setLocationHours] = React.useState(item.locationHours ? item.locationHours : '');
    const [locationMinutes, setLocationMinutes] = React.useState(item.locationMinutes ? item.locationMinutes : '');

    //status
    const [status, setStatus] = React.useState(item.status ? item.status : 'None');
    return (
        <SwipeableDrawer
            anchor={'left'}
            open={openAddModal}
            onClose={() => {
                setTitle('');
                setDescription('');
                setRating(2);
                setCategory(categoryChoices[0]);
                setCategoryName('');
                setDate(dayjs(new Date()));
                setStartDate(dayjs(new Date()));
                setUserSelectedStartDate(false);
                setUserSelectedDate(false);
                setAllDayEvent(false);
                setRepeatedDate([]);
                setDuration(30);
                setNotificationChosen(0);
                setTimeInterval('');
                setNotificationTime(dayjs(new Date()));
                setNotificationDate(dayjs(new Date()));
                setNotificationRepeat(false);
                setRatingChanged(false);
                setCategoryChanged(false);
                setDateChanged(false);
                setNotificationChanged(false);
                setItem({});
                toggleDrawer();
            }}
            onOpen={toggleDrawer}
            sx={{
                color: 'white',
                width: 240,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: modalWidth,
                    boxSizing: 'border-box',
                    marginTop: 8,
                    overflow: 'auto',
                    height: '100%',
                    backgroundColor: 'black',
                },
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: 15,
                }}
            >
                <h1 style={{ textAlign: 'center', fontWeight: 700, color: '#A6a6a6' }}>Edit Subtask</h1>

                <StyledTextField
                    id="outlined-textarea"
                    label="Title"
                    placeholder="Add a Title"
                    defaultValue={item.title}
                    fullWidth={true}
                    sx={{ marginBottom: 2, width: '80%', borderColor: 'red' }}
                    variant="outlined"
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                    multiline
                />
                <StyledTextField
                    id="outlined-textarea"
                    label="Description"
                    placeholder="Add a Description"
                    defaultValue={item.description}
                    fullWidth={true}
                    sx={{ marginBottom: 2, width: '80%' }}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                    multiline
                />
                <EditTaskDeadlineModal
                    setDeadline={setDate}
                    deadline={date}
                    setAllDay={setAllDayEvent}
                    allDay={allDayEvent}
                    userSelectedDate={setUserSelectedDate}
                    repeatedDays={repeatedDate}
                    setRepeatedDays={setRepeatedDate}
                    setDuration={setDuration}
                    duration={item.duration}
                    setDateChanged={setDateChanged}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    defaultUserSelectedStartDate={userSelectedStartDate}
                    userSelectedStartDate={setUserSelectedStartDate}
                />
                <h2 style={{ textAlign: 'center', fontWeight: 500, marginBottom: 0, marginTop: 1, color: '#A6a6a6' }}>
                    {' '}
                    Rating
                </h2>
                <Rating
                    defaultValue={item.rating}
                    value={rating}
                    sx={{ color: 'white' }}
                    max={4}
                    size="large"
                    onChange={(_event, newValue) => {
                        setRating(newValue);
                        setRatingChanged(true);
                    }}
                    emptyIcon={<StarBorderOutlined fontSize="inherit" style={{ color: 'white' }} />}
                />
                <TaskEditCategoryModal
                    setCategory={setCategory}
                    categoryName={categoryName}
                    setCategoryChanged={setCategoryChanged}
                />

                <EditTaskNotificationModal
                    notificationChosen={notificationChosen}
                    setNotificationChosen={setNotificationChosen}
                    setTimeInterval={setTimeInterval}
                    setNotificationTime={setNotificationTime}
                    setNotificationDate={setNotificationDate}
                    setNotificationRepeat={setNotificationRepeat}
                    setNotificationDayChosen={setNotificationDayChosen}
                    notificationDate={notificationDate}
                    notificationDayChosen={notificationDayChosen}
                    notificationTime={notificationTime}
                    setReminderChanged={setNotificationChanged}
                />
                <TaskStatusModal setStatus={setStatus} originalStatus={item.status} />
                <TaskLocationModal
                    setStartLocation={setStartLocation}
                    setEndLocation={setEndLocation}
                    setStartPlaceId={setStartPlaceId}
                    setEndPlaceId={setEndPlaceId}
                    setLocationHours={setLocationHours}
                    setLocationMinutes={setLocationMinutes}
                    originalStartLocation={startLocation}
                    originalEndLocation={endLocation}
                    originalStartPlaceId={startPlaceId}
                    originalEndPlaceId={endPlaceId}
                    originalLocationHours={locationHours}
                    originalLocationMinutes={locationMinutes}
                    userSelectedStartDate={userSelectedStartDate}
                    userSelectedTime={userSelectedDate}
                    startDate={startDate}
                    dueDate={date}
                    allday={allDayEvent}
                />
                <Button
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        width: '80%',
                        backgroundColor: '#A6a6a6',
                        color: 'black',
                        fontWeight: 700,
                        '&:hover': {
                            backgroundColor: '#A6a6a6',
                            color: 'black',
                        },
                    }}
                    onClick={() => {
                        updateSubtask(
                            item.subtaskID,
                            item.id,
                            title,
                            description,
                            rating,
                            category,
                            item.duedate,
                            item.deadline_time,
                            item.startDate,
                            item.startTime,
                            date,
                            startDate,
                            userSelectedDate,
                            userSelectedStartDate,
                            allDayEvent,
                            repeatedDate,
                            duration,
                            notificationChosen,
                            timeInterval,
                            notificationRepeat,
                            notificationDate,
                            notificationDayChosen,
                            notificationTime,
                            dateChanged,
                            categoryChanged,
                            item.checked,
                            item.reminder_type,
                            notificationChanged,
                            status,
                            startLocation,
                            endLocation,
                            startPlaceId,
                            endPlaceId,
                            locationHours,
                            locationMinutes
                        );
                        //reset all the states
                        setTitle('');
                        setDescription('');
                        setRating(2);
                        setCategory(categoryChoices[0]);
                        setCategoryName('');
                        setDate(dayjs(new Date()));
                        setStartDate(dayjs(new Date()));
                        setUserSelectedStartDate(false);
                        setUserSelectedDate(false);
                        setAllDayEvent(false);
                        setRepeatedDate([]);
                        setDuration(30);
                        setNotificationChosen(0);
                        setTimeInterval('');
                        setNotificationTime(dayjs(new Date()));
                        setNotificationDate(dayjs(new Date()));
                        setNotificationRepeat(false);
                        setRatingChanged(false);
                        setCategoryChanged(false);
                        setDateChanged(false);
                        setNotificationChanged(false);
                        setStatus('None');
                        setStartLocation('');
                        setEndLocation('');
                        setStartPlaceId('');
                        setEndPlaceId('');
                        setLocationHours('');
                        setLocationMinutes('');
                        setItem({});
                        toggleDrawer();
                    }}
                >
                    Confirm
                </Button>
            </div>

            <div style={{ minHeight: '15%' }}> </div>
        </SwipeableDrawer>
    );
}
const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});

import HistoryTaskComponent from '../Components/HistoryTaskComponent';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import HistorySubtaskComponent from '../Components/HistorySubtaskComponent';
import { getMembership } from './AccountHandler';
import { addTaskFromHistoryTask } from './MainTaskHandler';
import { addSubtaskFromHistorySubtask } from './SubtaskHandler';
export let historyTaskList = [];
export let historySubtaskList = [];

export async function readHistoryTaskFromDB() {
    let membership = getMembership();
    if (membership == 'free') {
        await localReadHistoryTasks();
        return historyTaskList;
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/readHistoryTask', {
            method: 'GET',
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success === true) {
            historyTaskList = data.task;

            return data.task;
        } else {
            return [];
        }
    }
}

export async function localStoreHistoryTasks() {
    localStorage.setItem('historyTaskList', JSON.stringify(historyTaskList));
}

export async function localReadHistoryTasks() {
    const rawHistoryTaskList = localStorage.getItem('historyTaskList');
    if (rawHistoryTaskList) historyTaskList = JSON.parse(rawHistoryTaskList);
    return historyTaskList;
}

export async function localStoreHistorySubtasks() {
    localStorage.setItem('historySubtaskList', JSON.stringify(historySubtaskList));
}

export async function localReadHistorySubtasks() {
    const rawHistorySubtaskList = localStorage.getItem('historySubtaskList');
    if (rawHistorySubtaskList) historySubtaskList = JSON.parse(rawHistorySubtaskList);
    return historySubtaskList;
}

export async function readHistorySubtaskFromDB() {
    let membership = getMembership();
    if (membership == 'free') {
        await localReadHistorySubtasks();
        return historySubtaskList;
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/readHistorySubtask', {
            method: 'GET',
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success === true) {
            historySubtaskList = data.subtask;
            return data.subtask;
        } else {
            return [];
        }
    }
}

export async function deleteHistoryTask(id) {
    let membership = getMembership();
    if (membership == 'free') {
        let temp = [...historyTaskList];
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].id == id) {
                temp.splice(i, 1);
            }
        }
        historyTaskList = temp;
        for (let i = 0; i < historySubtaskList.length; i++) {
            if (historySubtaskList[i].mainTaskId == id) {
                await deleteHistorySubtask(historySubtaskList[i].subtask_id, historySubtaskList[i].task_id);
            }
        }
        await localStoreHistoryTasks();
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/deleteHistoryTask', {
            method: 'POST',
            body: JSON.stringify({
                id: id,
            }),
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success === true) {
            let temp = [...historyTaskList];
            let index = temp.findIndex((x) => x.id == id);
            temp.splice(index, 1);
            historyTaskList = temp;
        } else {
        }
    }
    window.refreshHistoryScreen();
    return;
}
export async function addHistoryTask(
    id,
    title,
    category,
    color,
    description,
    list,
    reminder_time,
    rating,
    duration,
    duedate,
    days_repeated,
    deadline_time,
    startDate,
    startTime,
    notificationIdentifier,
    calendarIdentifier,
    reminder_type,
    startLocation,
    endLocation,
    startPlaceId,
    endPlaceId,
    locationHours,
    locationMinutes
) {
    let dateAddedInMs = new Date().getTime();
    let membership = getMembership();
    historyTaskList.push({
        id: id,
        title: title,
        category: category,
        color: color,
        description: description,
        list: list,
        reminder_time: reminder_time,
        rating: rating,
        duration: duration,
        duedate: duedate,
        days_repeated: days_repeated,
        deadline_time: deadline_time,
        dateAdded: dateAddedInMs,
        startDate: startDate,
        startTime: startTime,
        notificationIdentifier: notificationIdentifier,
        calendarIdentifier: calendarIdentifier,
        reminder_type: reminder_type,
        startLocation: startLocation,
        endLocation: endLocation,
        startPlaceId: startPlaceId,
        endPlaceId: endPlaceId,
        locationHours: locationHours,
        locationMinutes: locationMinutes,
    });
    if (membership == 'free') {
        await localStoreHistoryTasks();
    }
}

export async function addHistorySubtask(
    id,
    subtaskId,
    title,
    category,
    color,
    description,
    reminder_time,
    rating,
    duration,
    duedate,
    days_repeated,
    deadline_time,
    startDate,
    startTime,
    notificationIdentifier,
    calendarIdentifier,
    reminder_type,
    startLocation,
    endLocation,
    startPlaceId,
    endPlaceId,
    locationHours,
    locationMinutes
) {
    //get date in ms
    let date = new Date();
    let dateInMs = date.getTime();
    let membership = getMembership();
    historySubtaskList.push({
        task_id: id,
        subtask_id: subtaskId,
        title: title,
        category: category,
        color: color,
        description: description,
        reminder_time: reminder_time,
        rating: rating,
        duration: duration,
        duedate: duedate,
        days_repeated: days_repeated,
        deadline_time: deadline_time,
        dateAdded: dateInMs,
        startDate: startDate,
        startTime: startTime,
        notificationIdentifier: notificationIdentifier,
        calendarIdentifier: calendarIdentifier,
        reminder_type: reminder_type,
        startLocation: startLocation,
        endLocation: endLocation,
        startPlaceId: startPlaceId,
        endPlaceId: endPlaceId,
        locationHours: locationHours,
        locationMinutes: locationMinutes,
    });
    if (membership == 'free') {
        await localStoreHistorySubtasks();
    }
}

export async function deleteHistorySubtask(id, mainTaskId) {
    let membership = getMembership();
    if (membership == 'free') {
        //find the index of the subtask
        let temp = [...historySubtaskList];
        //find index by task_id and subtask_id
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].task_id == mainTaskId && temp[i].subtask_id == id) {
                temp.splice(i, 1);
            }
        }
        historySubtaskList = temp;
        await localStoreHistorySubtasks();
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/deleteHistorySubtask', {
            method: 'POST',
            body: JSON.stringify({
                subtask_id: id,
                task_id: mainTaskId,
            }),
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success === true) {
            let temp = [...historySubtaskList];
            //find index by task_id and subtask_id
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].task_id == mainTaskId && temp[i].subtask_id == id) {
                    temp.splice(i, 1);
                }
            }
            historySubtaskList = temp;
        } else {
        }
    }
    window.refreshHistoryScreen();
    return;
}

export async function retrieveTask(id) {
    let membership = getMembership();
    if (membership == 'free') {
        let temp = [...historyTaskList];
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].id == id) {
                await addTaskFromHistoryTask(
                    temp[i].id,
                    temp[i].title,
                    temp[i].category,
                    temp[i].color,
                    temp[i].description,
                    temp[i].list,
                    temp[i].reminder_time,
                    temp[i].rating,
                    temp[i].duration,
                    temp[i].duedate,
                    temp[i].days_repeated,
                    temp[i].deadline_time,
                    temp[i].startDate,
                    temp[i].startTime,
                    temp[i].notificationIdentifier,
                    temp[i].calendarIdentifier,
                    temp[i].reminder_type
                );
            }
            temp.splice(i, 1);
        }
        historyTaskList = temp;
        let temp2 = [...historySubtaskList];
        for (let i = 0; i < temp2.length; i++) {
            if (temp2[i].mainTaskId == id) {
                await addSubtaskFromHistorySubtask(
                    historySubtaskList[i].subtask_id,
                    historySubtaskList[i].task_id,
                    historySubtaskList[i].title,
                    historySubtaskList[i].category,
                    historySubtaskList[i].color,
                    historySubtaskList[i].description,
                    historySubtaskList[i].reminder_time,
                    historySubtaskList[i].rating,
                    historySubtaskList[i].duration,
                    historySubtaskList[i].duedate,
                    historySubtaskList[i].days_repeated,
                    historySubtaskList[i].deadline_time,
                    historySubtaskList[i].startDate,
                    historySubtaskList[i].startTime,
                    historySubtaskList[i].notificationIdentifier,
                    historySubtaskList[i].calendarIdentifier,
                    historySubtaskList[i].reminder_type
                );
                temp2.splice(i, 1);
            }
        }
        historySubtaskList = temp2;
        await localStoreHistoryTasks();
        await localStoreHistorySubtasks();
    }
    if (membership == 'premium') {
        const response = await fetch('https://api.priorityze.app/retrieveTask', {
            method: 'POST',
            body: JSON.stringify({
                id: id,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }),
            credentials: 'include',
        });
        const data = await response.json();
        if (data.success === true) {
            let temp = [...historyTaskList];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].id == id) {
                    await addTaskFromHistoryTask(
                        temp[i].id,
                        temp[i].title,
                        temp[i].category,
                        temp[i].color,
                        temp[i].description,
                        temp[i].list,
                        temp[i].reminder_time,
                        temp[i].rating,
                        temp[i].duration,
                        temp[i].duedate,
                        temp[i].days_repeated,
                        temp[i].deadline_time,
                        temp[i].startDate,
                        temp[i].startTime,
                        temp[i].notificationIdentifier,
                        temp[i].calendarIdentifier
                    );
                }
                temp.splice(i, 1);
            }
            let temp2 = [...historySubtaskList];
            for (let i = 0; i < temp2.length; i++) {
                if (temp2[i].mainTaskId == id) {
                    await addSubtaskFromHistorySubtask(
                        historySubtaskList[i].subtask_id,
                        historySubtaskList[i].task_id,
                        historySubtaskList[i].title,
                        historySubtaskList[i].category,
                        historySubtaskList[i].color,
                        historySubtaskList[i].description,
                        historySubtaskList[i].reminder_time,
                        historySubtaskList[i].rating,
                        historySubtaskList[i].duration,
                        historySubtaskList[i].duedate,
                        historySubtaskList[i].days_repeated,
                        historySubtaskList[i].deadline_time,
                        historySubtaskList[i].startDate,
                        historySubtaskList[i].startTime,
                        historySubtaskList[i].notificationIdentifier,
                        historySubtaskList[i].calendarIdentifier
                    );
                    temp2.splice(i, 1);
                }
            }
            historyTaskList = temp;
        } else {
        }
    }
    window.refreshHistoryScreen();
    window.refreshListScreen();
    return;
}

export function DisplayHistoryTasks({}) {
    const [historyTaskListForDisplay, setHistoryTaskList] = React.useState([]);
    const [historySubtaskListForDisplay, setHistorySubtaskList] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [current_frame, setCurrentFrame] = React.useState(0);
    let counter = current_frame;
    window.refreshHistoryScreen = () => {
        counter++;
        setCurrentFrame(counter);
    };
    React.useEffect(() => {
        async function fetchData() {
            setError(null);
            setLoading(true);
            try {
                setHistoryTaskList(historyTaskList);
                setHistorySubtaskList(historySubtaskList);
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        }
        fetchData();
    }, [current_frame]);
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error</div>;
    return (
        <div current_frame={current_frame} style={{ backgroundColor: 'black' }}>
            {historyTaskList.map((task, index) => (
                <div key={index} style={{ backgroundColor: 'black' }}>
                    <HistoryTaskComponent item={task} index={index} subtaskList={historySubtaskList} />
                </div>
            ))}
        </div>
    );
}

export function DisplayHistorySubtask({ subtaskFromParent }) {
    return (
        <div>
            {subtaskFromParent.map((subtask, index) => (
                <HistorySubtaskComponent item={subtask} index={index} key={index} />
            ))}
        </div>
    );
}

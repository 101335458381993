import { Alert } from '@mui/material';

import btoa from 'btoa';
import { BrowserRouter as Router, Navigate, Redirect, Route, Switch, useNavigate } from 'react-router-dom';

//reset password

//sign out
export async function signOut() {
    await fetch('https://api.priorityze.app/signout', {
        method: 'GET',
        cors: 'no-cors',
        credentials: 'include',
    })
        .then((response) => {
            return response.json();
        })
        .then((body) => {
            //get success value from response
            if (body.success == false) {
                return false;
            } else {
                //send email to user
                return true;
            }
        });
}
//get membership
let membership = '';
export async function storeMembership() {
    await fetch('https://api.priorityze.app/getMembership', {
        method: 'GET',

        credentials: 'include',
    })
        .then((response) => {
            return response.json();
        })
        .then((body) => {
            //get success value from response
            if (body.success == false) {
                return false;
            } else {
                //send email to user
                membership = body.membership;
                return true;
            }
        });

    return membership;
}

export function getMembership() {
    return membership;
}
let email = '';
export async function storeEmail() {
    await fetch('https://api.priorityze.app/authentication/getEmail', {
        method: 'GET',
        credentials: 'include',
    })
        .then((response) => {
            return response.json();
        })
        .then((body) => {
            //get success value from response
            if (body.success == false) {
                return false;
            } else {
                //send email to user
                email = body.email;
                return true;
            }
        });

    return email;
}

export function getEmail() {
    return email;
}
let calendarEmails = [];
export async function storeCalendarEmails() {
    await fetch('https://api.priorityze.app/authentication/google/getConnectedAccounts', {
        method: 'GET',
        credentials: 'include',
    })
        .then((response) => {
            return response.json();
        })
        .then((body) => {
            //get success value from response
            if (body.success == false) {
                return false;
            } else {
                //send email to user
                calendarEmails = body.accounts;
                return true;
            }
        });
}

export function getCalendarEmails() {
    return calendarEmails;
}
export async function goToCheckOut() {
    if (membership == 'free' && checkValidEmail(email)) {
        try {
            await fetch('https://api.priorityze.app/purchase/session', {
                method: 'POST',
                credentials: 'include',
            })
                .then((response) => {
                    //catch link to stripe checkout
                    return response.json();
                })
                .then((body) => {
                    //get success value from response
                    if (body.success == false) {
                        return false;
                    } else {
                        //send email to user
                        window.location.href = body.url;
                        return true;
                    }
                });
        } catch (error) {}
    }
}

function checkValidEmail(email) {
    if (!email.includes('@')) {
        return false;
    }
    return true;
}

export async function cancelMembership() {
    if (membership == 'free') {
        return false;
    }
    //cancel membership from stripe
    await fetch('https://api.priorityze.app/purchase/cancel', {
        method: 'POST',
        credentials: 'include',
    })
        .then((response) => {
            //catch link to stripe checkout
            return response.json();
        })
        .then((body) => {
            //get success value from response
            if (body.success == false) {
                return false;
            } else {
                window.location.href = body.url;
                return true;
            }
        });
    //cancel membership from database
}

export async function unsyncCalendar(email) {
    await fetch('https://api.priorityze.app/authentication/google/unsyncGoogleCalendar', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            email: email,
        }),
    })
        .then((response) => {
            return response.json();
        })
        .then((body) => {
            //get success value from response
            if (body.success == false) {
                return false;
            } else {
                return true;
            }
        });
}

export async function syncCalendar() {
    if (membership == 'premium') {
        await fetch('https://api.priorityze.app/authentication/google/refreshCalendar', {
            method: 'GET',
            cors: 'no-cors',
            credentials: 'include',
        })
            .then((response) => {
                return response.json();
            })
            .then((body) => {
                //get success value from response
                if (body.success == false) {
                    return false;
                } else {
                    return true;
                }
            });
    }
}

import * as React from 'react';
import { Select, IconButton } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { categoryChoices, deleteCategory } from '../../../Logic/CategoryHandler';
export function DeleteCategoryModal({ fetchCategories }) {
    const [categoryName, setCategoryName] = React.useState('');
    let categoryOptions = [];
    const [selectedCategory, setSelectedCategory] = React.useState(categoryChoices[0].option);
    for (let i = 1; i < categoryChoices.length; i++) {
        //take out the "None" category
        if (categoryChoices[i].option !== 'None') {
            categoryOptions.push(categoryChoices[i]);
        }
    }
    const handleCategory = (category) => {
        setCategoryName(category.target.value);
        setSelectedCategory(category.target.value);
    };
    return (
        <div>
            <Box sx={{ minWidth: 120, marginTop: 2 }}>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            marginLeft: 4,
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }}
                    >
                        Category
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCategory.option}
                        label="Age"
                        sx={{
                            marginBottom: 2,
                            width: 200,
                            marginLeft: 4,
                            border: '1px solid #262626',

                            color: 'white',
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: ' #F2F2F2',
                            },
                        }}
                        onChange={handleCategory}
                    >
                        {categoryOptions.map((item) => {
                            return (
                                <MenuItem
                                    key={item.option}
                                    value={item.option}
                                    style={{
                                        backgroundColor: item.color,
                                    }}
                                >
                                    {item.option}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
            <IconButton
                style={{
                    color: 'white',
                    backgroundColor: 'black',
                    borderRadius: 5,
                    padding: 5,
                    marginBottom: 2,
                    width: 200,
                    marginLeft: 25,
                    textAlign: 'center',
                    border: '1px solid #262626',
                    //hover
                }}
                onClick={async () => {
                    await deleteCategory(categoryName);
                    fetchCategories();
                }}
            >
                <DeleteForeverIcon fontSize="large" />
            </IconButton>
        </div>
    );
}

import { IconButton, TextField } from '@mui/material';
import * as React from 'react';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { createList } from '../../../Logic/ListHandler';
export function CreateListModal({ fetchLists }) {
    const [listName, setListName] = React.useState('');

    return (
        <div style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
            <IconButton
                style={{ color: 'white', backgroundColor: 'black', borderRadius: 5, padding: 5, marginBottom: 2 }}
                onClick={async () => {
                    await createList(listName);
                    fetchLists();
                }}
            >
                <AddBoxIcon fontSize="large" />
            </IconButton>
            <StyledTextField
                id="outlined-textarea"
                label="List Name"
                onChange={(e) => {
                    setListName(e.target.value);
                }}
                placeholder="Add a List"
                fullWidth={true}
                sx={{ marginBottom: 2, width: 200 }}
            />
        </div>
    );
}

const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});

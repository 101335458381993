import * as React from 'react';
import { IconButton, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { deleteList, listChoices } from '../../../Logic/ListHandler';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { createList } from '../../../Logic/ListHandler';
import { collabLists } from '../../../Logic/collabListLogic/collabListLogic';
import { deleteCollabList } from '../../../Logic/collabListLogic/deleteCollabList';

export function DeleteCollabListModal({ fetchLists }) {
    const [listName, setListName] = React.useState('');
    const [listId, setListId] = React.useState('');

    const [selectedList, setSelectedList] = React.useState('None');
    const listOptions = [];
    for (let i = 1; i < collabLists.length; i++) {
        if (collabLists[i].owner == true) {
            listOptions.push(collabLists[i]);
        }
    }
    const handleList = (listItems) => {
        setListName(listItems.target.value.option);
        setSelectedList(listItems.target.value.option);
        setListId(listItems.target.value.list_id);
        //get the list id
    };
    return (
        <div>
            <Box sx={{ minWidth: 120, marginTop: 2, flexDirection: 'row' }}>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            marginLeft: 4,
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }}
                    >
                        List
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedList}
                        onChange={handleList}
                        defaultValue={selectedList}
                        label="List"
                        sx={{
                            marginBottom: 2,
                            width: 200,
                            marginLeft: 3,
                            border: '1px solid #262626',
                            borderRadius: '5px',
                            color: 'white',
                            //turn off focus border
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: ' #F2F2F2',
                            },
                        }}
                    >
                        {listOptions.map((item) => {
                            return (
                                <MenuItem key={item} value={item}>
                                    {item.option}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
            <IconButton
                style={{
                    color: 'white',
                    backgroundColor: 'black',
                    borderRadius: 5,
                    padding: 5,
                    marginBottom: 2,
                    width: 200,
                    marginLeft: 25,
                    textAlign: 'center',
                    border: '1px solid #262626',
                }}
                onClick={async () => {
                    await deleteCollabList(listId);
                    fetchLists();
                }}
            >
                <DeleteForeverIcon fontSize="large" />
            </IconButton>
        </div>
    );
}

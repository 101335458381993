import React from 'react';
import {
    cancelMembership,
    getCalendarEmails,
    getEmail,
    getMembership,
    goToCheckOut,
    signOut,
    storeCalendarEmails,
    storeEmail,
    storeMembership,
    unsyncCalendar,
} from '../Logic/AccountHandler';
import { Button, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { BrowserRouter as Routes, Navigate, Redirect, Route, Router, useNavigate } from 'react-router-dom';
import {
    CheckCircleOutline,
    LinkOff,
    NotInterested,
    PersonAdd,
    PersonRemove,
    PlaylistRemove,
} from '@mui/icons-material';
import {
    collabInviteResponse,
    collabInvites,
    collabLists,
    readCollabInvites,
    sendCollabInvite,
} from '../Logic/collabListLogic/collabListLogic';
import { deleteCollabList } from '../Logic/collabListLogic/deleteCollabList';
import { removeUserFromList } from '../Logic/collabListLogic/removeUserFromList';
import { removeSelfFromList } from '../Logic/collabListLogic/removeSelfFromList';
import CollabRequestComponent from '../Components/CollabRequestComponent';

export default function CollabInviteScreen() {
    const navigate = useNavigate();
    const handleHeaderClick = () => {
        navigate('/');
    };

    const [current_frame, setCurrentFrame] = React.useState(0);
    const [collabRequests, setCollabRequests] = React.useState([]);
    let counter = current_frame;
    window.refreshCollabInvite = () => {
        counter++;
        setCurrentFrame(counter);
    };
    const handleLoggedIn = (data) => {
        navigate('/?dashboard');
    };
    React.useEffect(() => {
        fetch('https://api.priorityze.app/checkLogin', {
            method: 'GET',

            credentials: 'include',
        })
            .then((response) => {
                return response.json();
            })
            .then(async (body) => {
                if (body.success == true) {
                    handleLoggedIn(body.success);
                    await readCollabInvites().then(async () => {
                        setCollabRequests(collabInvites);
                    });
                }
            })
            .then(() => {});
    }, [current_frame]);

    return (
        <div>
            <Header onClick={handleHeaderClick} />
            <Typography component="h5" variant="h6" align="center" sx={{ color: 'white', marginBottom: '2vh' }}>
                Collab List Invites
            </Typography>
            {collabRequests.map((list) => {
                return <CollabRequestComponent request={list} />;
            })}
        </div>
    );
}
const Header = ({ onClick }) => {
    return (
        <Box sx={{ backgroundColor: 'black', padding: '1rem', marginBottom: '1vh' }}>
            <Typography
                component="h1"
                variant="h4"
                align="center"
                sx={{ color: 'white', cursor: 'pointer' }}
                onClick={onClick}
            >
                Priorityze
            </Typography>
        </Box>
    );
};

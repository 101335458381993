import * as React from 'react';
import { outlinedInputClasses, Select, styled } from '@mui/material';
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export function TaskNotificationModal({
    setNotificationChosen,
    notificationChosen,
    setTimeInterval,
    setNotificationTime,
    notificationTime,
    setNotificationDate,
    notificationDate,
    setNotificationRepeat,
    setNotificationDayChosen,
    notificationDayChosen,
    repetition,
}) {
    const options = ['None', 'Time', 'Day', 'Date'];
    const dayOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [selectedNotification, setSelectedNotification] = React.useState(options[notificationChosen]);

    const handleNotifications = (notificationItem) => {
        let index;
        if (notificationItem.target.value === 'None') {
            setNotificationChosen(0);
            setSelectedNotification(notificationItem.target.value);
        } else if (notificationItem.target.value === 'Time') {
            setNotificationChosen(1);
            setSelectedNotification(notificationItem.target.value);
        } else if (notificationItem.target.value === 'Day') {
            setNotificationChosen(2);
            setSelectedNotification(notificationItem.target.value);
        } else if (notificationItem.target.value === 'Date') {
            setNotificationChosen(3);
            setSelectedNotification(notificationItem.target.value);
        }
    };
    const handleDayChosen = (dayItem) => {
        setNotificationDayChosen(dayItem.target.value);
    };
    return (
        <div>
            <h2 style={{ textAlign: 'center', fontWeight: 500, marginBottom: 0, marginTop: 1, color: '#A6a6a6' }}>
                Notification
            </h2>
            <Box sx={{ minWidth: 120, marginTop: 2 }}>
                <FormControl fullWidth>
                    <InputLabel
                        sx={{
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }}
                        id="demo-simple-select-label"
                    >
                        Notification
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={options[notificationChosen]}
                        label="Age"
                        sx={{
                            marginBottom: 2,
                            width: 250,
                            border: '1px solid #262626',
                            borderRadius: '5px',
                            color: 'white',
                            //turn off focus border
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: ' #F2F2F2',
                            },
                        }}
                        onChange={handleNotifications}
                    >
                        {options.map((item) => {
                            return (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
            {notificationChosen == 1 && (
                <div>
                    <FormGroup>
                        <StyledTextField
                            id="outlined-number"
                            label="Number"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setTimeInterval(e.target.value)}
                            sx={{ marginBottom: 1, width: 200, marginLeft: 4 }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) => setNotificationRepeat(e.target.checked)}
                                    sx={{
                                        marginLeft: 4,
                                        color: 'white',
                                        //make the checkbox white when checked
                                        '&.Mui-checked': {
                                            color: 'white',
                                        }, //make label white
                                        '& .MuiFormControlLabel-label': {
                                            color: 'white',
                                        },
                                    }}
                                />
                            }
                            //make label white
                            sx={{
                                color: 'white',
                                '& .MuiFormControlLabel-label': {
                                    color: 'white',
                                },
                            }}
                            label="Repeats"
                        />
                    </FormGroup>
                </div>
            )}
            {notificationChosen == 2 && (
                <div>
                    <Box sx={{ minWidth: 120, marginTop: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{
                                    color: 'white',
                                    '&.Mui-focused': {
                                        color: 'white',
                                    },
                                }}
                            >
                                Day
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={notificationDayChosen}
                                label="Age"
                                onChange={handleDayChosen}
                                sx={{
                                    marginBottom: 2,
                                    width: 250,
                                    border: '1px solid #262626',
                                    borderRadius: '5px',
                                    color: 'white',
                                    //turn off focus border
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: ' #F2F2F2',
                                    },
                                }}
                            >
                                {dayOptions.map((item) => {
                                    return (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <div style={{ justifyContent: 'center', alignItems: 'center', marginLeft: '3%', marginTop: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Time"
                                value={notificationTime}
                                onChange={(newValue) => {
                                    setNotificationTime(notificationTime);
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                                //Change background color of mui date picker
                                OpenPickerButtonProps={{
                                    style: { backgroundColor: 'white' },
                                }}
                                //Change background color of mui date picker
                                InputAdornmentProps={{
                                    style: { backgroundColor: '#2d2d2d' },
                                }}
                                renderInput={(params) => <StyledTextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            )}
            {notificationChosen == 3 && (
                <div style={{ justifyContent: 'center', alignItems: 'center', marginLeft: '3%', marginTop: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(props) => <StyledTextField {...props} />}
                            label="Date and Time"
                            value={notificationDate}
                            onChange={(newValue) => {
                                setNotificationDate(newValue);
                            }}
                            InputProps={{
                                style: { color: 'white' },
                            }}
                            //Change background color of mui date picker
                            OpenPickerButtonProps={{
                                style: { backgroundColor: 'white' },
                            }}
                            //Change background color of mui date picker
                            InputAdornmentProps={{
                                style: { backgroundColor: '#2d2d2d' },
                            }}
                        />
                    </LocalizationProvider>
                </div>
            )}
        </div>
    );
}

const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});

import React from 'react';
import {
    cancelMembership,
    getCalendarEmails,
    getEmail,
    getMembership,
    goToCheckOut,
    signOut,
    storeCalendarEmails,
    storeEmail,
    storeMembership,
    unsyncCalendar,
} from '../Logic/AccountHandler';
import { Button, MenuItem, Modal, Select, Typography, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { BrowserRouter as Routes, Navigate, Redirect, Route, Router, useNavigate } from 'react-router-dom';
import { Check, LinkOff, PersonAdd, PersonRemove, PlaylistRemove } from '@mui/icons-material';
import {
    collabLists,
    createCollabLists,
    readCollabLists,
    sendCollabInvite,
} from '../Logic/collabListLogic/collabListLogic';
import { deleteCollabList } from '../Logic/collabListLogic/deleteCollabList';
import { removeUserFromList } from '../Logic/collabListLogic/removeUserFromList';
import { removeSelfFromList } from '../Logic/collabListLogic/removeSelfFromList';
import OwnedListComponent from '../Components/OwnedListComponent';
import CollabListComponent from '../Components/attendeeListComponent';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { Clear, Delete } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
export default function CollabManagementScreen() {
    const navigate = useNavigate();
    const handleHeaderClick = () => {
        navigate('/');
    };
    const [ownedLists, setOwnedLists] = React.useState([]);
    const [collabList, seCollabList] = React.useState([]);

    const [attendeeEmail, setAttendeeEmail] = React.useState('');
    //handle adding an attendee to the calendar
    const addAttendee = (listId, email) => {
        if (attendeeEmail != '') {
            sendCollabInvite(listId, email);
            setAttendeeEmail('');
        }
    };

    const [removeAttendeeEmail, setRemoveAttendeeEmail] = React.useState('');
    //handle removing an attendee from the calendar
    const [current_frame, setCurrentFrame] = React.useState(0);

    let counter = current_frame;
    window.refreshCollabManagement = () => {
        counter++;
        setCurrentFrame(counter);
    };

    const handleLoggedIn = (data) => {
        navigate('/?dashboard');
    };
    React.useEffect(() => {
        fetch('https://api.priorityze.app/checkLogin', {
            method: 'GET',

            credentials: 'include',
        })
            .then((response) => {
                return response.json();
            })
            .then(async (body) => {
                if (body.success == true) {
                    handleLoggedIn(body.success);
                    await readCollabLists().then(async () => {
                        let tempListsOwned = collabLists.filter((list) => list.owner == true);
                        let tempListsCollab = collabLists.filter((list) => list.owner == false);
                        setOwnedLists(tempListsOwned);
                        seCollabList(tempListsCollab);
                    });
                }
            })
            .then(() => {});
    }, [current_frame]);
    const [createListShow, setCreateListShow] = React.useState(false);
    const [listName, setListName] = React.useState('');
    const [attendees, setAttendees] = React.useState([]);
    const [inputText, setInputText] = React.useState('');
    const [permission, setPermission] = React.useState('edit');
    const handleAddEmail = () => {
        if (inputText.trim() !== '') {
            if (attendees.length === 0) {
                setAttendees([inputText]);
            } else {
                setAttendees([...attendees, inputText]);
            }
            setInputText('');
        }
    };

    const handleRemoveEmail = (index) => {
        let temp = [...attendees];
        temp.splice(index, 1);

        setAttendees(temp);
    };

    return (
        <div current_frame={current_frame}>
            <Header onClick={handleHeaderClick} />
            <Typography component="h5" variant="h6" align="center" sx={{ color: 'white', marginBottom: '2vh' }}>
                Collab List Management
            </Typography>
            <Button
                variant="outlined"
                //make it so when the button is on, it is filled
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'black',
                    color: 'black',
                    '&:hover': {
                        color: 'white',
                    },
                }}
                onClick={() => {
                    setCreateListShow(true);
                }}
            >
                Create Collab List
            </Button>
            {createListShow && (
                <Modal
                    open={createListShow}
                    onClose={() => setCreateListShow(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
                            Create Collab List
                        </Typography>
                        <Box
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <StyledTextField
                                id="outlined-textarea"
                                label="List Name"
                                onChange={(e) => {
                                    setListName(e.target.value);
                                }}
                                placeholder="Add a List"
                                fullWidth={true}
                                sx={{ marginBottom: 2, width: 200 }}
                            />
                            <div
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {attendees.map((item, index) => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginRight: '2%',
                                            }}
                                        >
                                            <Clear
                                                sx={{ color: 'white' }}
                                                onClick={() => {
                                                    handleRemoveEmail(index);
                                                }}
                                            />
                                            <h3 style={{ color: 'white' }}>{item}</h3>
                                        </div>
                                    );
                                })}
                            </div>
                            <StyledTextField
                                id="outlined-textarea"
                                label="Email"
                                onChange={(e) => {
                                    setInputText(e.target.value);
                                    if (
                                        e.target.value.includes(' ') ||
                                        e.target.value.includes(',') ||
                                        e.target.value.includes('\n') ||
                                        e.target.value.includes('\r') ||
                                        e.target.value.includes('\t')
                                    ) {
                                        handleAddEmail();
                                    }
                                }}
                                placeholder="Add an Email"
                                fullWidth={true}
                            />
                            <div
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    display: 'flex',
                                }}
                            >
                                <Checkbox
                                    sx={{ color: 'white' }}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setPermission('view');
                                        } else {
                                            setPermission('edit');
                                        }
                                    }}
                                />
                                <h3 style={{ color: 'white' }}>Can Edit</h3>
                            </div>
                            <Button
                                variant="outlined"
                                sx={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    '&:hover': {
                                        color: 'black',
                                    },
                                }}
                                onClick={async () => {
                                    setCreateListShow(false);
                                    setListName('');
                                    setAttendees([]);
                                    setPermission('edit');
                                    await createCollabLists(listName, attendees, permission);
                                }}
                            >
                                Create
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
            {ownedLists.map((list) => {
                return <OwnedListComponent list={list} />;
            })}
            {collabList.map((list) => {
                return <CollabListComponent list={list} />;
            })}
        </div>
    );
}

const Header = ({ onClick }) => {
    return (
        <Box sx={{ backgroundColor: 'black', padding: '1rem', marginBottom: '1vh' }}>
            <Typography
                component="h1"
                variant="h4"
                align="center"
                sx={{ color: 'white', cursor: 'pointer' }}
                onClick={onClick}
            >
                Priorityze
            </Typography>
        </Box>
    );
};

const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});

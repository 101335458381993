import React from 'react';

import { Box, Card, Flexbox, CardContent, Checkbox, Grid, Rating, Typography } from '@mui/material';
import { Add, Delete, Edit, KeyboardArrowDown, KeyboardArrowUp, LocalOffer, Article } from '@mui/icons-material';
import { DisplaySubtaskList, readSubtaskFromDB } from '../Logic/SubtaskHandler';
import { deleteTask } from '../Logic/MainTaskHandler';
import { deleteHistoryTask, DisplayHistorySubtask, retrieveTask } from '../Logic/HistoryTaskHandler';
import StarBorderIcon from '@mui/icons-material/StarBorder';
export default function HistoryTaskComponent({ item, index, subtaskList }) {
    const [expanded, setExpanded] = React.useState(false);

    //filter subtaskList where subtaskList.taskID == item.taskID
    subtaskList = subtaskList.filter((subtask) => subtask.task_id == item.id);
    let repeatedDayString = '';
    if (item.days_repeated.length > 0) {
        repeatedDayString = ' every ' + item.days_repeated;
    }
    let stringForDate = '';
    if (item.duedate.length > 0) {
        if (!item.days_repeated.includes('Daily')) {
            if (item.startDate.length > 0) {
                if (item.startDate == item.duedate) {
                    if (item.deadline_time.length > 0) {
                        stringForDate =
                            item.duedate + repeatedDayString + ' ' + item.startTime + ' - ' + item.deadline_time;
                    } else {
                        stringForDate = item.startDate + ' - ' + item.duedate + repeatedDayString;
                    }
                } else {
                    if (item.deadline_time.length > 0) {
                        stringForDate =
                            item.startDate +
                            ' - ' +
                            item.duedate +
                            repeatedDayString +
                            ' ' +
                            item.startTime +
                            ' - ' +
                            item.deadline_time;
                    } else {
                        stringForDate = item.startDate + ' - ' + item.duedate + repeatedDayString;
                    }
                }
            } else {
                if (item.deadline_time.length > 0) {
                    stringForDate = item.duedate + repeatedDayString + ' ' + item.deadline_time;
                } else {
                    stringForDate = item.duedate + repeatedDayString;
                }
            }
        }
        if (item.days_repeated.includes('Daily')) {
            if (item.deadline_time.length > 0) {
                if (item.startTime.length > 0) {
                    stringForDate = 'Daily' + ' ' + item.startTime + ' - ' + item.deadline_time;
                } else {
                    stringForDate = 'Daily' + ' ' + item.deadline_time;
                }
            } else {
                stringForDate = 'Daily';
            }
        }
    }
    return (
        <div
            style={{
                maxWidth: window.innerWidth < 600 ? '95vw' : '100%',
            }}
        >
            <Box
                className="TaskComponent"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    width: '96%',
                    padding: 0.5,
                    marginBottom: 2,
                    backgroundColor: 'white',
                    borderRadius: 2,
                    marginLeft: '2%',
                    marginRight: '2%',
                    borderColor: item.color,
                    borderStyle: 'solid',
                }}
                onClick={() => {}}
            >
                <Box
                    ml={1}
                    alignItems="center"
                    style={{
                        marginBottom: 0,
                        //keep all items in the box
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        //make the text wrap around
                        textWrap: 'wrap',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                        overflowWrap: 'break-word',
                        hyphens: 'auto',
                        maxWidth: '95%',
                    }}
                    flex={10}
                >
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                            marginBottom: 0,
                            marginLeft: 0,
                            textAlign: 'left',
                            textDecorationLine: item.taskChecked == 1 ? 'line-through' : 'none',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            maxWidth: '95%',
                        }}
                    >
                        {item.title}
                    </Typography>
                    {item.duedate.length > 0 && (
                        <Typography
                            variant="overline"
                            component="p"
                            style={{
                                marginBottom: 0,
                                marginTop: 0,
                                textAlign: 'left',

                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                overflowWrap: 'break-word',
                                hyphens: 'auto',
                                maxWidth: '95%',
                            }}
                        >
                            {stringForDate}
                        </Typography>
                    )}
                    {item.description.length > 0 ? (
                        <Typography
                            variant="body2"
                            component="p"
                            style={{
                                marginBottom: 0,
                                marginTop: 0,
                                textAlign: 'left',

                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                overflowWrap: 'break-word',
                                hyphens: 'auto',
                                maxWidth: '95%',
                            }}
                        >
                            {item.description}
                        </Typography>
                    ) : (
                        ''
                    )}
                    <Box mt={1} style={{ marginTop: 0 }}>
                        <Box display="flex" alignItems="center" justifyContent="left">
                            <Box display="flex" style={{ marginLeft: 0, marginRight: '5%' }}>
                                <Typography variant="body1" component="p" style={{ marginBottom: 0, marginTop: 0 }}>
                                    {item.rating}
                                </Typography>
                                <StarBorderIcon
                                    fontSize="small"
                                    //make it the same size as the text
                                />
                            </Box>
                            {item.category != 'None' && (
                                <Box display="flex" style={{ marginLeft: 0, marginRight: '5%' }}>
                                    <LocalOffer
                                        fontSize="small"
                                        sx={{ color: item.category != 'None' ? item.color : 'black' }}
                                    />
                                    <Typography variant="body2" component="p" style={{ marginBottom: 0, marginTop: 0 }}>
                                        {item.category}
                                    </Typography>
                                </Box>
                            )}
                            {item.list != 'All' && (
                                <Box display="flex" style={{ marginLeft: 0 }}>
                                    <Article fontSize="small" />
                                    <Typography variant="body2" component="p" style={{ marginBottom: 0, marginTop: 0 }}>
                                        {item.list}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box ml="auto" alignItems="center" display="flex">
                    <Delete
                        onClick={async () => {
                            await deleteHistoryTask(item.id);
                        }}
                        sx={{
                            '&:hover': {
                                color: 'white',
                                backgroundColor: 'black',
                                borderRadius: 1,
                            },
                        }}
                    />
                    <Box mx={1} />
                    {!expanded && (
                        <KeyboardArrowDown
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            sx={{
                                '&:hover': {
                                    color: 'white',
                                    backgroundColor: 'black',
                                    borderRadius: 1,
                                },
                            }}
                        />
                    )}
                    {expanded && (
                        <KeyboardArrowUp
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            sx={{
                                '&:hover': {
                                    color: 'white',
                                    backgroundColor: 'black',
                                    borderRadius: 1,
                                },
                            }}
                        />
                    )}
                    <Box mx={1} />
                    <Add
                        onClick={() => {
                            retrieveTask(item.id);
                        }}
                        sx={{
                            '&:hover': {
                                color: 'white',
                                backgroundColor: 'black',
                                borderRadius: 1,
                            },
                        }}
                    />
                    <Box mx={1} />
                </Box>
            </Box>
            {expanded && <DisplayHistorySubtask subtaskFromParent={subtaskList} />}
        </div>
    );
}

export async function deleteCollabList(listId) {
    //pop up a window to confirm the deletion
    if (window.confirm('Are you sure you want to delete this list?')) {
        const res = await fetch('https://api.priorityze.app/deleteCollabList', {
            method: 'POST',
            body: JSON.stringify({
                listId: listId,
            }),
            credentials: 'include',
        });
        const body = await res.json();
        if (body.success == false) {
            alert(body.error);
        }
        if (window.refreshCollabManagement) {
            window.refreshCollabManagement();
        }
        return body;
    }
}

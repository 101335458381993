import React from 'react';
import { Button, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { BrowserRouter as Routes, Navigate, Redirect, Route, Router, useNavigate } from 'react-router-dom';
import {
    CheckBox,
    CheckCircle,
    CheckCircleOutline,
    LinkOff,
    NotInterested,
    PersonAdd,
    PersonRemove,
    PlaylistRemove,
} from '@mui/icons-material';
import { collabInviteResponse, collabLists, sendCollabInvite } from '../Logic/collabListLogic/collabListLogic';
import { deleteCollabList } from '../Logic/collabListLogic/deleteCollabList';
import { removeUserFromList } from '../Logic/collabListLogic/removeUserFromList';
import { removeSelfFromList } from '../Logic/collabListLogic/removeSelfFromList';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled, alpha } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { StarBorder, StarBorderOutlined } from '@mui/icons-material';

export default function CollabRequestComponent({ request }) {
    const [response, setResponse] = React.useState('');

    return (
        <div
            style={{
                backgroundColor: 'black',
                flex: 1,
                maxWidth: window.innerWidth < 600 ? '95vw' : '100%',
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
                sx={{
                    width: '96%',
                    padding: 0.5,
                    marginBottom: 2,
                    backgroundColor: '#ffffff',
                    borderRadius: 2,
                    marginLeft: '2%',
                    marginRight: '2%',
                    borderStyle: 'solid',
                }}
            >
                <Box
                    ml={1}
                    alignItems="center"
                    style={{
                        marginBottom: 0,
                        //keep all items in the box
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        //make the text wrap around
                        textWrap: 'wrap',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                        overflowWrap: 'break-word',
                        hyphens: 'auto',
                        maxWidth: '95%',
                    }}
                    flex={10}
                >
                    <Typography
                        variant="h6"
                        style={{
                            marginBottom: 0,
                            marginLeft: 0,
                            textAlign: 'left',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            maxWidth: '95%',
                        }}
                    >
                        {request.listName}
                    </Typography>
                    <Typography
                        variant="h7"
                        style={{
                            marginBottom: 0,
                            marginLeft: 0,
                            textAlign: 'left',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            maxWidth: '95%',
                        }}
                    >
                        Request sent by: {request.owner_email}
                    </Typography>
                </Box>
                <Box ml="auto" alignItems="center" display="flex">
                    <CheckCircleOutline
                        onClick={() =>
                            collabInviteResponse(request.list_id, 'accept', request.permission, request.listName)
                        }
                        sx={{ '&:hover': { color: '#86abd4', backgroundColor: '#86abd4', borderRadius: 1 } }}
                    />
                    <Box ml={1} />
                    <NotInterested
                        onClick={() =>
                            collabInviteResponse(request.list_id, 'decline', request.permission, request.listName)
                        }
                        sx={{ '&:hover': { color: '#86abd4', backgroundColor: '#86abd4', borderRadius: 1 } }}
                    />
                </Box>
            </Box>
        </div>
    );
}

import { TextField } from '@mui/material';
import * as React from 'react';
import { CirclePicker } from 'react-color';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { createCategory } from '../../../Logic/CategoryHandler';
export function CreateCategoryModal({ fetchCategories }) {
    const [categoryName, setCategoryName] = React.useState('');
    const [categoryColor, setCategoryColor] = React.useState('#ffffff');
    return (
        <div>
            <StyledTextField
                id="outlined-textarea"
                label="Title"
                placeholder="Add a Title"
                fullWidth={true}
                sx={{ marginBottom: 2, width: 200, marginLeft: 4 }}
                onChange={(e) => setCategoryName(e.target.value)}
                multiline
            />
            <div style={{ marginLeft: 7 }}>
                <CirclePicker
                    styles={{ width: 200, marginLeft: 5 }}
                    color={categoryColor}
                    onChangeComplete={(color) => setCategoryColor(color.hex)}
                />
            </div>
            <Button
                sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    backgroundColor: '#262626',
                    width: 200,
                    marginLeft: 4,
                    '&:hover': {
                        backgroundColor: '#262626',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#262626',
                    },
                    '&.Mui-disabled:hover': {
                        backgroundColor: '#262626',
                    },
                    '&.Mui-disabled:focus': {
                        backgroundColor: '#262626',
                    },
                    '&.Mui-disabled:active': {
                        backgroundColor: '#262626',
                    },
                    '&.Mui-disabled.Mui-focusVisible': {
                        backgroundColor: '#262626',
                    },
                    '&.Mui-disabled.Mui-focusVisible:hover': {
                        backgroundColor: '#262626',
                    },
                }}
                variant="contained"
                onClick={async () => {
                    await createCategory(categoryName, categoryColor);
                    fetchCategories();
                }}
            >
                Create Category
            </Button>
        </div>
    );
}

const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#262626',
    },
    [`& .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
        color: '#F2F2F2',
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
        color: '#F2F2F2',
    },
});
